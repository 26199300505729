import styles from './ErrorLayout.module.scss';
import illustration from './errorIllustration.png';

interface ErrorLayoutProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ children, title }) => (
  <div className={styles.container}>
    <img src={illustration} alt="People moving boxes" className={styles.illustration} />

    {title && <h2 className={styles.title}>{title}</h2>}
    {children}
  </div>
);
