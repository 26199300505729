import React, { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { ShippingAddress } from 'app/types';

import { useAddressInfoProvider } from './useAddressProvider';

export interface AddressInfoContextInterface {
  addressInfo?: ShippingAddress;
  setAddressInfo: Dispatch<SetStateAction<ShippingAddress | undefined>>;
}

export const AddressContext = createContext<AddressInfoContextInterface>({
  addressInfo: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAddressInfo: () => {},
});

export const AddressInfoContextProvider: React.FC<React.ReactNode> = ({ children }) => (
  <AddressContext.Provider value={useAddressInfoProvider()}>{children}</AddressContext.Provider>
);

export const useAddressInfo = () => useContext(AddressContext);
