import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReturnFlowData } from 'app/types';
import { AddressContext } from 'app/contexts';

import { useInGeo } from './useInGeo';

interface LocationState {
  state: ReturnFlowData;
}

/**
 * Provides a way of easily navigating to a new route while maintaining all `state` from history
 *
 * @returns function Mover function that saves current progress and navigates
 */
export const useSaveAndNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const inGeo = useInGeo();
  const { setAddressInfo } = useContext(AddressContext);
  const { state } = location as LocationState;

  // Needs to pass state on change
  const goBack = useCallback(
    (nextRoute) => {
      navigate(nextRoute, {
        state,
      });
    },
    [state, navigate]
  );

  const saveAndNavigate = useCallback(
    (nextRoute, newState: Partial<ReturnFlowData> = {}) => {
      const updatedState = {
        ...state,
        ...newState,
      };
      if (!inGeo) {
        nextRoute = { pathname: nextRoute, search: '?in_geo=false' };
      }
      navigate(nextRoute, {
        state: updatedState,
      });
    },
    [state, navigate, inGeo]
  );

  const save = useCallback(
    (newState: Partial<ReturnFlowData> = {}) => {
      const updatedState = {
        ...state,
        ...newState,
      };

      const currentRoute = [location.pathname, location.search].filter(Boolean).join('');
      // Note: this does not currently preserve the hash portion of the URL

      navigate(currentRoute, {
        state: updatedState,
        replace: true,
      });
    },
    [state, navigate, location]
  );

  const reset = useCallback(
    (nextRoute) => {
      const newState: Partial<ReturnFlowData> = {
        orderLookupInput: state.orderLookupInput,
        isAdmin: state.isAdmin,
      };

      setAddressInfo(undefined);

      navigate(nextRoute, {
        state: newState,
        replace: true,
      });
    },
    [state, navigate, setAddressInfo]
  );

  return {
    /**
     * Moves the user to a new route without modifying state. This is a convenience method to
     * prevent from having to re-import this method
     */
    navigate,

    /**
     * Moves the user to a new route while maintaining all `state` from history
     *
     * @param nextRoute The path of the new route
     * @param newState The new state to add to the current state
     */
    saveAndNavigate,

    /**
     * Saves new `state` in history without changing routes
     *
     * @param newState The new state to add to the current state
     */
    save,

    /**
     * Goes to the route preserving previous state
     *
     * @param nextRoute The path of the new route
     */
    goBack,

    /**
     * Resets state and goes to the provided route
     *
     * @param nextRoute The path of the new route
     */
    reset,
  };
};
