import { NavLink, useLocation } from 'react-router-dom';

import { IconCheck } from 'icons';
import { ReturnSteps } from 'app/constants';
import { useReturnFlow } from 'app/hooks';
import { ReturnFlowData } from 'app/types';

import styles from './StepperLink.module.scss';

interface StepperLinkProps {
  /**
   * The content to display inside the link tag
   */
  children: React.ReactNode;

  /**
   * Specifies whether or not the link renders as active
   *
   * Active links cannot be clicked, and appear with a blue dot icon next to them
   */
  isActive?: boolean;

  /**
   * Specifies whether or not the link is clickable
   *
   * When not clickable, the element renders as a <span> instead of an <a>
   */
  isClickable?: boolean;

  /**
   * Specifies whether or not the link should render with a checkbox icon
   */
  isCompleted?: boolean;

  stepNumber: number;

  /**
   * Specifies the sub-path of the return process, i.e. `return/{subpath}`
   */
  subpath: string;
}

/**
 * Renders a link in the style used by the `Stepper` component.
 *
 * The links can contain an icons and active styles and a link back to a previous step
 * based on the passed props
 *
 * @returns React.FC
 */
export const StepperLink = ({
  children,
  isActive,
  isClickable,
  isCompleted,
  stepNumber,
  subpath,
}: StepperLinkProps) => {
  const location = useLocation();
  const { orderLookupInput, isAdmin } = useReturnFlow();

  if (!isClickable || isActive) {
    return (
      <div className={isActive ? styles.stepperItemActive : styles.stepperItem}>
        <div className={styles.prefix}>{stepNumber}</div>
        {children}
      </div>
    );
  }

  // reset state except email and isAdmin fields if linking to the order lookup
  const state: Partial<ReturnFlowData> =
    subpath === ReturnSteps.LocateOrder
      ? { orderLookupInput, isAdmin }
      : (location.state as ReturnFlowData);

  return (
    <NavLink
      to={subpath}
      className={isActive ? styles.stepperLinkActive : styles.stepperLink}
      tabIndex={isClickable ? 0 : undefined}
      state={state}
    >
      <div className={styles.prefix}>
        {isCompleted ? <IconCheck className={styles.completedIcon} size={12} /> : stepNumber}
      </div>

      {children}
    </NavLink>
  );
};
