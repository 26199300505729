import { useSelect } from 'downshift';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconChevronDown } from 'icons';

import styles from './LanguageSelector.module.scss';

/**
 * Renders a component that allows the user to change the current language
 *
 * @returns React.FC
 */
export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: languages,
    initialSelectedItem: languages.find((l) => l.value === i18n.language),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && selectedItem.value) {
        i18n.changeLanguage(selectedItem?.value);
        localStorage.setItem('i18nextLng', selectedItem?.value);
      }
    },
  });

  return (
    <div className={styles.container}>
      <button type="button" {...getToggleButtonProps({ className: styles.button })}>
        {t('languageSwitcher.language')} <strong>{selectedItem?.label}</strong>{' '}
        <IconChevronDown className={styles.chevronDown} size={8} />
      </button>
      <ul {...getMenuProps({ className: clsx(styles.dropdown, isOpen && styles.visible) })}>
        {isOpen &&
          languages.map((item, index) => (
            <li
              key={`${item}${index}`}
              {...getItemProps({
                item,
                index,
                className: clsx(
                  styles.dropdownItem,
                  highlightedIndex === index && styles.dropdownItemActive
                ),
              })}
            >
              {item.label}
            </li>
          ))}
      </ul>
    </div>
  );
};

const languages = [
  { value: 'en', label: 'English (United States)' },
  { value: 'fr', label: 'French' },
];
