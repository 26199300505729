import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import type { ApolloError } from '@apollo/client';
import { Severity } from '@sentry/react';

import { ENVIRONMENT, COMMIT_SHA, SENTRY_DSN } from 'app/environment';

export const createMonitor = () => {
  if (SENTRY_DSN && ENVIRONMENT !== 'local') {
    Sentry.init({
      dsn: SENTRY_DSN,
      autoSessionTracking: true,
      integrations: [new BrowserTracing()],
      release: COMMIT_SHA,
      sampleRate: 1,
      environment: ENVIRONMENT,
      tracesSampleRate: 1,
      debug: false, // set to true to track the performance
      attachStacktrace: false, // set to true to track the performance
    });
  }
};

type MessageType = string | Error | ApolloError;
type SentryPosting = {
  message: MessageType;
  loggingLevel?: Severity | undefined;
};

export const sendMessageForMonitoring = ({
  message,
  loggingLevel = Sentry.Severity.Error,
}: SentryPosting) => {
  // DO NOT CAPTURE messages during local development
  if (ENVIRONMENT !== 'local') {
    if (typeof message === 'string') {
      Sentry.captureMessage(message, loggingLevel);
    } else {
      const data = message as ApolloError;
      if (data.graphQLErrors || data.networkError) {
        if (data.networkError) {
          Sentry.captureMessage(data.message, Sentry.Severity.Critical); // network error is a critical error
        } else if (data.graphQLErrors) {
          // Show graphql errors as warnings since it needs further investigation
          Sentry.captureMessage(data.message, Sentry.Severity.Warning);
        } else {
          Sentry.captureException(data);
        }
      } else {
        Sentry.captureException(message);
      }
    }
  }
};

export const sendInfoMessageForMonitoring = (message: string) => {
  sendMessageForMonitoring({ message, loggingLevel: Sentry.Severity.Info });
};
export const sendWarningMessageForMonitoring = (message: string) => {
  sendMessageForMonitoring({ message, loggingLevel: Sentry.Severity.Warning });
};
export const sendErrorMessageForMonitoring = (error: Error | string) => {
  Sentry.captureException(typeof error === 'string' ? error : error?.message);
};
