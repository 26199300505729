import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from 'app/environment';
import { Coordinate, DropoffLocation } from 'app/types';
import { googleLibraries } from 'app/constants';

import CurrentLocationMarker from './markers/currentLocation.svg';
import MarkerPurple from './markers/markerPurple.svg';
import MarkerWhite from './markers/markerWhite.svg';

interface MapPin {
  uid: string;
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

interface DropoffLocationMapProps {
  /**
   * The location of the current user
   *
   * This value should be provided as an object containing { lat, lng }
   */
  currentLocation: null | { lat: number; lng: number };

  /**
   * A callback function to be called whenever a location map marker is clicked
   */
  onLocationClick: (locationUid: string) => void;

  /**
   * The ID of the currently selected dropoff location
   *
   * If a dropofflocation has this ID, the marker will be rendered in purple
   * instead to visually indiciate it is selected
   */
  selectedLocation?: string | null;

  selectedLocationCoords?: null | Coordinate;

  /**
   * The list of dropoff locations to show on the map
   */
  dropoffLocations: DropoffLocation[];

  /**
   * The component that renders the floating current location card
   */
  floatingCurrentLocationCard?: React.ReactNode;
}

export const DropoffLocationMap = ({
  currentLocation,
  onLocationClick,
  selectedLocation,
  selectedLocationCoords,
  dropoffLocations,
  floatingCurrentLocationCard,
}: DropoffLocationMapProps) => {
  const [_map, setMap] = useState<google.maps.Map | null>(null);
  const [zoomLevel] = useState(DEFAULT_ZOOM_LEVEL);
  const center = useMemo(() => DEFAULT_MAP_CENTER, []);

  const pins: MapPin[] = useMemo(() => {
    const mapPins: MapPin[] = dropoffLocations.flatMap((loc) => {
      if (
        loc.address &&
        loc.coordinates != null &&
        loc.coordinates?.lat != null &&
        loc.coordinates?.lng != null
      ) {
        return [
          {
            uid: loc.uid,
            address: loc.address,
            coordinates: {
              lat: loc.coordinates.lat,
              lng: loc.coordinates.lng,
            },
          },
        ];
      }
      return [];
    });
    return mapPins;
  }, [dropoffLocations]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleLibraries,
    // language: i18n.language,
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const closestDropOffLocation: DropoffLocation | undefined = dropoffLocations[0];

  // Center and zoom the map on the inputted location (which is initially the
  // Shopify shipping address) and the closest drop off location.
  useEffect(() => {
    if (isLoaded && _map && closestDropOffLocation && currentLocation) {
      const bounds = new window.google.maps.LatLngBounds();
      if (currentLocation.lat && currentLocation.lng) {
        bounds.extend({
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        });
      }
      if (
        closestDropOffLocation.coordinates?.lat != null &&
        closestDropOffLocation.coordinates?.lng != null
      ) {
        bounds.extend({
          lat: closestDropOffLocation.coordinates.lat,
          lng: closestDropOffLocation.coordinates.lng,
        });
      }

      _map.fitBounds(bounds, { top: 115, right: 55, left: 20, bottom: 25 });
    }
  }, [isLoaded, _map, currentLocation, closestDropOffLocation]);

  /**
   * Update the map bounds whenever the selected location changes
   */
  useEffect(() => {
    if (isLoaded && _map && selectedLocationCoords && currentLocation) {
      const bounds = new window.google.maps.LatLngBounds();

      if (currentLocation.lat && currentLocation.lng) {
        bounds.extend({
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        });
      }
      if (selectedLocationCoords.lat && selectedLocationCoords.lng) {
        bounds.extend({
          lat: selectedLocationCoords.lat,
          lng: selectedLocationCoords.lng,
        });
      }

      _map.fitBounds(bounds, { top: 115, right: 55, left: 20, bottom: 25 });
    }
  }, [currentLocation, selectedLocationCoords, isLoaded, _map]);

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoomLevel}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        {currentLocation && (
          <Marker position={currentLocation} icon={{ url: CurrentLocationMarker }} />
        )}
        {pins.map((loc) => {
          return (
            <Marker
              key={loc.uid}
              position={loc.coordinates}
              onClick={() => {
                if (typeof onLocationClick === 'function') {
                  onLocationClick(loc?.uid);
                }
              }}
              icon={{ url: selectedLocation === loc.uid ? MarkerPurple : MarkerWhite }}
            />
          );
        })}
      </GoogleMap>
      {floatingCurrentLocationCard}
    </>
  );
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

const containerStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
};

const DEFAULT_MAP_CENTER = {
  lat: 43.647587,
  lng: -79.396224,
};

// When zoom level is 3, user can see the whole canada with province information
const DEFAULT_ZOOM_LEVEL = 11;
