import { useEffect, useState } from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

import { SEGMENT_WRITE_KEY } from 'app/environment';

import { SegmentContextInterface } from '.';

export const useSegmentProvider = (): SegmentContextInterface => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  useEffect(() => {
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY });
      setAnalytics(response);
    };
    if (SEGMENT_WRITE_KEY) {
      loadAnalytics();
    }
  }, []);
  return {
    analytics,
  };
};
