import { useTranslation } from 'react-i18next';

import { Select, SelectItemShape } from 'ui';

import styles from './ReturnReasonsDropdown.module.scss';

interface ReturnReasonsDropdownProps {
  /**
   * The current value
   */
  value?: string | null;

  /**
   * Function to be called whenever the selected value changes
   */
  onChange: (selectedReason: string) => void;

  /**
   * Specifies the label prop to use on the <Select> component
   */
  label?: string;

  /**
   * The list of dropdown options
   */
  returnReasonChoices: SelectItemShape[];
}

/**
 * Renders a <Select> element that is prefilled with all return reasons
 */
export const ReturnReasonsDropdown: React.FC<ReturnReasonsDropdownProps> = (spreadProps) => {
  const { t } = useTranslation();

  return (
    <Select
      {...spreadProps}
      className={styles.container}
      buttonText={t('common.select')}
      items={spreadProps.returnReasonChoices}
    />
  );
};
