import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface useRedirectToErrorPageOptions {
  when: boolean | (() => boolean);
}

/**
 * Redirect the user to the error page when certain conditions are met
 */
export const useRedirectToErrorPage = ({
  when,
  ...navigationOptions
}: useRedirectToErrorPageOptions) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (when) {
      navigate('/error', navigationOptions);
    }
  }, [when, navigate, navigationOptions]);
};
