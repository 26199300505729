import { useTranslation } from 'react-i18next';

import { ReturnBearLogo } from 'app/components';
import { useViewportHeight } from 'app/hooks';
import { ThreeDots } from 'ui';

import styles from './LoadingScreen.module.scss';

export const LoadingScreen = () => {
  const { t } = useTranslation();
  const { boxStyle } = useViewportHeight();

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.content}>
        <div className={styles.loadingText}>
          <ThreeDots className={styles.dotPulse} />
          {t('common.loadingWithEllipsis')}
        </div>
      </div>

      <footer className={styles.poweredBy}>
        <ReturnBearLogo size={30} className={styles.logo} />
      </footer>
    </div>
  );
};
