import { useCallback, useMemo, useState, useEffect } from 'react';

/**
 * A hook that returns the current size of the viewport
 *
 * @returns An object containing `height` and `boxStyle`. boxStyle is a convenience style object.
 */
export const useViewportHeight = () => {
  // Use `100vh` as a default value to minimize content jumps after size detection
  const [height, setHeight] = useState<string | number>('100vh');
  const boxStyle = useMemo(() => ({ height }), [height]);

  const handleResize = useCallback(() => setHeight(window?.innerHeight), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [handleResize]);

  return { height, boxStyle };
};
