import { Route, Routes } from 'react-router-dom';

import * as Outlets from 'app/outlets';
import * as Pages from 'pages';

const ApplicationRoutes = () => (
  <Routes>
    <Route path="/" element={<Pages.OrderLookup />} />

    <Route path="/return" element={<Outlets.StoreOrderOutlet />}>
      <Route path="select-items" element={<Pages.SelectItems />} />
      <Route path="return-method" element={<Pages.ReturnMethod />} />
      <Route path="payout-method" element={<Pages.PayoutMethod />} />
      <Route path="review" element={<Pages.ReviewReturn />} />
      <Route path="confirmation" element={<Pages.ReturnConfirmation />} />
    </Route>

    <Route path="/error" element={<Pages.ErrorPage />} />

    {/* Catch-all route must be the last route inside of the <Routes> component */}
    <Route element={<Pages.FourOhFour />} />
  </Routes>
);

export default ApplicationRoutes;
