import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useReturnFlow } from 'app/hooks';
import { Button, Modal, TextInput } from 'ui';

import styles from './ContactInfoModal.module.scss';

export interface ContactInfoModalProps {
  isVisible: boolean;
  onClose?: () => void;
}

export const ContactInfoModal = (spreadProps: ContactInfoModalProps) => {
  const { t } = useTranslation();
  const { shippingAddress, save } = useReturnFlow();

  const formik = useFormik({
    initialValues: {
      country: shippingAddress.country ?? '',
      lastName: shippingAddress.country ?? '',
      zipCode: shippingAddress.country ?? '',
      address2: shippingAddress.country ?? '',
      city: shippingAddress.country ?? '',
      address1: shippingAddress.country ?? '',
      provinceCode: shippingAddress.country ?? '',
      firstName: shippingAddress.country ?? '',
      province: shippingAddress.country ?? '',
      phone: shippingAddress.country ?? '',
      countryCode: shippingAddress.country ?? '',
      name: shippingAddress.country ?? '',
      company: shippingAddress.country ?? '',
      id: shippingAddress.country ?? '',
    },
    onSubmit: (shippingAddress) => save({ shippingAddress }),
  });

  return (
    <Modal
      {...spreadProps}
      title={t('contactInfoModal.title')}
      className={styles.container}
      modalClass={styles.modal}
      closeButtonLabel={t('common.close')}
    >
      <form onSubmit={formik.handleSubmit}>
        <p>{t('contactInfoModal.instructions')}</p>

        <TextInput
          name="address1"
          label={t('common.address')}
          value={formik.values.address1}
          onChange={formik.handleChange}
          className={styles.input}
        />

        <TextInput
          name="address2"
          placeholder={t('contactInfoModal.address2Placeholder')}
          value={formik.values.address2}
          onChange={formik.handleChange}
          className={styles.input}
        />

        <TextInput
          name="city"
          label={t('common.city')}
          value={formik.values.city}
          onChange={formik.handleChange}
          className={styles.input}
        />

        <div className={styles.splitRow}>
          <TextInput
            name="postalCode"
            label={t('common.postalCode')}
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            className={styles.input}
          />

          <TextInput
            name="country"
            label={t('common.country')}
            value={formik.values.country}
            onChange={formik.handleChange}
            className={styles.input}
            disabled
          />
        </div>

        <footer className={styles.actions}>
          <Button type="submit" className={styles.continueButton}>
            {t('common.save')}
          </Button>
          <Button appearance="link" onClick={spreadProps?.onClose}>
            {t('common.cancel')}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};
