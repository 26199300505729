import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  getStoreConfigQuery,
  getThemeConfigQuery,
  getStorePolicyRules,
  getStoreReturnReason,
} from 'app/graphql/queries';
import { getSubdomain } from 'utils';
import {
  StoreConfigInterface,
  ReturnReasonInterface,
  GetStoreReturnReasonResult,
  GetStoreConfigQueryResult,
  GetStoreConfigQueryVariables,
  ReturnAcceptanceCriteria,
} from 'app/types';
import { ListAcceptanceCriteriaDocument, ListAcceptanceCriteriaQuery } from 'generated/graphql';

import { StoreConfigContextInterface } from '.';

const subdomain = getSubdomain();

export const useStoreConfigProvider = (): StoreConfigContextInterface => {
  const [storeConfig, setStoreConfig] = useState<StoreConfigInterface | null>(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);

  const [storeReturnReasons, setStoreReturnReasons] = useState<
    ReturnReasonInterface[] | undefined
  >();

  const { data: storeConfigData, loading: storeConfigLoading } = useQuery<
    GetStoreConfigQueryResult,
    GetStoreConfigQueryVariables
  >(getStoreConfigQuery, {
    variables: { subdomain },
  });

  const { data: returnAcceptanceCriteriaData, loading: returnAcceptanceCriteriaLoading } =
    useQuery<ListAcceptanceCriteriaQuery>(ListAcceptanceCriteriaDocument);

  const { data: getStoreReturnReasonsData, loading: getStoreReturnReasonsDataLoading } =
    useQuery<GetStoreReturnReasonResult>(getStoreReturnReason);

  const { data: themeConfigData, loading: themeConfigLoading } = useQuery(getThemeConfigQuery, {
    variables: { subdomain },
  });

  const { data: policyConfigData, loading: policyRulesLoading } = useQuery(getStorePolicyRules, {
    variables: { subdomain },
  });

  useEffect(() => {
    if (!getStoreReturnReasonsData) {
      return;
    }
    setStoreReturnReasons(getStoreReturnReasonsData?.getStoreReturnReasons.returnReasons);
  }, [getStoreReturnReasonsData]);

  useEffect(() => {
    if (!storeConfigData) {
      return;
    }

    setStoreConfig(storeConfigData?.getStoreConfig);
  }, [storeConfigData]);

  useEffect(() => {
    if (!themeConfigData) {
      return;
    }

    setThemeConfig(themeConfigData?.getThemeConfig);
  }, [themeConfigData]);

  useEffect(() => {
    if (!policyConfigData) {
      return;
    }

    setPolicyRules(policyConfigData?.getStorePolicyRules);
  }, [policyConfigData]);

  const returnAcceptanceCriteria = useMemo(() => {
    if (!returnAcceptanceCriteriaData?.listAcceptanceCriteria) {
      return [];
    }

    return returnAcceptanceCriteriaData.listAcceptanceCriteria?.flatMap(
      (item): [ReturnAcceptanceCriteria] | [] => (item !== null ? [item] : [])
    );
  }, [returnAcceptanceCriteriaData]);

  const hasExchangeFee = !!storeConfig?.fees?.exchange?.feeAmount;
  const hasRefundFee = !!storeConfig?.fees?.refund?.feeAmount;
  const hasStoreCreditFee = !!storeConfig?.fees?.storeCredit?.feeAmount;
  const hasFlatRateFee = !!storeConfig?.fees?.flatRate?.feeAmount;
  const hasTaxesWithheld = storeConfig?.hasTaxesWithheld;

  return {
    returnAcceptanceCriteria,
    storeConfig,
    themeConfig,
    policyRules,
    policyRulesLoading,
    isInitializing:
      storeConfigLoading ||
      themeConfigLoading ||
      getStoreReturnReasonsDataLoading ||
      returnAcceptanceCriteriaLoading,
    hasExchangeFee,
    hasRefundFee,
    hasStoreCreditFee,
    hasFlatRateFee,
    storeReturnReasons,
    hasTaxesWithheld,
  };
};
