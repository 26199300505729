import React, { useEffect, useState } from 'react';

import styles from './ContinueCtaWrapper.module.scss';

import ContinueCta from '../ContinueCta';

interface Props {
  /**
   * If provided, renders the provided button on the right of the layout
   */
  continueButton?: React.ReactNode;

  /**
   * OPTIONAL
   * The count of the total number of selected items.
   *
   * Because the items count is dynamic on Step 2 (<SelectItems />), we need to
   * be able to use that dynamic count only for Step 2. The steps after that
   * will have the selected items count pulled from the useReturnFlow context,
   * which is set AFTER step 2 which is a static, unchanging number.
   */
  selectedItemsCount?: number | null;
}

const ContinueCtaWrapper = ({ continueButton, selectedItemsCount }: Props) => {
  // Flag to indicate if we need to position the CTA Bar at the end of the
  // content area (which is just above the footer), or should be set it's
  // position to sticky.
  const [isStickToBottom, setIsStickToBottom] = useState(false);

  const handleResize = () => {
    setIsStickToBottom(document.documentElement.scrollHeight <= window.innerHeight);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isStickToBottom]);

  const content = (
    <div className={styles.continueButtonContainer}>
      <ContinueCta continueButton={continueButton} selectedItemsCount={selectedItemsCount} />
    </div>
  );

  if (isStickToBottom) {
    return <div className={styles.continueBottomWrapper}>{content}</div>;
  }

  return content;
};

export default ContinueCtaWrapper;
