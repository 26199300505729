import { useMemo } from 'react';
import GoogleFontLoader from 'react-google-font-loader';

import { useStoreConfig } from 'app/contexts';
import { StoreThemeLayout } from 'layouts';

interface StoreThemeProps {
  children?: React.ReactNode;
}

export const StoreTheme = ({ children }: StoreThemeProps) => {
  const { themeConfig } = useStoreConfig();
  const fonts = useMemo(() => {
    if (!themeConfig?.defaultFontFamily) {
      return null;
    }

    return [{ font: themeConfig?.defaultFontFamily, weights: [300, 400, 500, 600] }];
  }, [themeConfig?.defaultFontFamily]);

  return (
    <StoreThemeLayout>
      {fonts && <GoogleFontLoader fonts={fonts} />}
      {children}
    </StoreThemeLayout>
  );
};
