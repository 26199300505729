import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import ContinueCtaWrapper from 'app/components/ContinueCtaWrapper';
import { ReturnMethods } from 'app/constants';
import { useStoreConfig, useStoreOrder } from 'app/contexts';
import {
  useAnalytics,
  useDropoffLocations,
  useRedirectToErrorPage,
  useReturnFlow,
  useScrollToTopOnMount,
} from 'app/hooks';
import { useInGeo } from 'app/hooks/useInGeo';
import { ExchangeOrReturn } from 'app/types';
import { ReturnCaseItemReturnMethod } from 'generated/graphql';
import { IconReturnBear } from 'icons';
import { DefaultAppPage } from 'layouts';
import { Button, Dialog, Placeholder, ProductThumbnail } from 'ui';
import {
  canAllocateFeesToReturnMethod,
  formatPrice,
  getProductImage,
  getReturnChannels,
  MISSING_IMAGE_URL,
  removeDefaultTitle,
} from 'utils';
import { constructURL } from 'utils/url';

import { ReviewReturnSection } from './components';
import styles from './ReviewReturn.module.scss';

const returnMethodsMap = [
  ReturnCaseItemReturnMethod.StoreCredit,
  ReturnCaseItemReturnMethod.Refund,
];

export const ReviewReturn = () => {
  const inGeo = useInGeo();
  const { storeOrder, isInitializing } = useStoreOrder();
  const currency = storeOrder?.currency;
  const {
    createReturnCase,
    formattedCreationErrors,
    isCreateReturnCaseWorking,
    dropoffLocationId,
    exchangeOnlyItemsFee,
    hasExchangeItems,
    hasOrderData,
    hasPayoutItems,
    hasRefundOnlyItems,
    hasStoreCreditOnlyItems,
    payoutMethod,
    payoutSubTotal,
    storeCreditSubtotal,
    refundOnlyItemsFee,
    remainingItemFeeRefund,
    remainingItemFeeStoreCredit,
    returnItems,
    returnMethod,
    saveAndNavigate,
    shippingAddress,
    shippingEstimate,
    logisticsFee,
    storeCreditOnlyItemsFee,
    totalTaxes,
    returnCaseItemInput,
    remainingItems,
    hasInboundShippingFee,
    isCanadianOrder,
    hasFlatRateFee,
    flatRateFeeName,
    flatRateFeeAmountForSelectedReturnMethod,
    hasDropOffFlatRateFee,
    hasMailInFlatRateFee,
  } = useReturnFlow();
  const {
    storeConfig,
    hasExchangeFee,
    hasRefundFee,
    hasStoreCreditFee,
    hasTaxesWithheld,
    themeConfig,
  } = useStoreConfig();

  const { dropoffLocations } = useDropoffLocations();
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const location = useLocation();
  const [showCreationError, setShowCreationError] = useState(false);

  const shippingCountryCode = shippingAddress?.countryCode;
  const storeReturnChannels = storeConfig?.returnChannel || [];
  const currentReturnChannel = getReturnChannels(storeReturnChannels, shippingCountryCode ?? '');

  // bonus doesn't apply if there are items caught by rules
  const hasStoreCreditBonus =
    !hasStoreCreditOnlyItems &&
    !hasRefundOnlyItems &&
    hasPayoutItems &&
    payoutMethod === ReturnCaseItemReturnMethod.StoreCredit;

  const isDropOff = returnMethod === ReturnMethods.DropOff;
  const storeBonusAmount = Number(storeConfig?.bonusAmount) || 0;
  const bonusAmount = hasStoreCreditBonus ? storeBonusAmount : 0;
  const usCrossBorderFee = isCanadianOrder ? 0 : logisticsFee || 0;
  const hasUSCrossBorderFee = isCanadianOrder ? false : usCrossBorderFee > 0;
  const usCrossBorderFeeName = t('reviewReturnPage.crossBorderFee');

  // fees are a combination of items caught by rules and remaining items
  const storeCreditFee =
    payoutMethod === ReturnCaseItemReturnMethod.StoreCredit
      ? remainingItemFeeStoreCredit + storeCreditOnlyItemsFee
      : storeCreditOnlyItemsFee;

  const refundFee =
    payoutMethod === ReturnCaseItemReturnMethod.Refund
      ? remainingItemFeeRefund + refundOnlyItemsFee
      : refundOnlyItemsFee;

  const refundItems = useMemo(() => {
    return returnItems.filter((returnItem) => returnItem.returnMethod === ExchangeOrReturn.Return);
  }, [returnItems]);

  const exchangeItems = useMemo(() => {
    return returnItems.filter(
      (returnItem) => returnItem.returnMethod === ExchangeOrReturn.Exchange
    );
  }, [returnItems]);

  // scroll to content on back
  useScrollToTopOnMount();

  useEffect(() => {
    analytics.returnReviewPageViewed(returnItems.length);
    // This needs to be disabled as we only want to track when the component mounts, and not
    // when the selectedItemList changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropoffLocation = useMemo(() => {
    return dropoffLocations.find((f) => f.uid === dropoffLocationId);
  }, [dropoffLocationId, dropoffLocations]);

  useRedirectToErrorPage({
    when: !hasOrderData || !returnItems.length,
  });
  const shippingAmount = isDropOff ? 0 : Number(shippingEstimate);
  const total =
    Number(payoutSubTotal) -
    shippingAmount -
    refundFee -
    storeCreditFee -
    exchangeOnlyItemsFee +
    totalTaxes +
    Number(bonusAmount) -
    Number(usCrossBorderFee) -
    flatRateFeeAmountForSelectedReturnMethod;

  const totalTaxesWithHeld =
    Number(payoutSubTotal) -
    shippingAmount -
    refundFee -
    storeCreditFee -
    exchangeOnlyItemsFee +
    Number(bonusAmount) -
    Number(usCrossBorderFee) -
    flatRateFeeAmountForSelectedReturnMethod;

  const canAllocateFees = canAllocateFeesToReturnMethod(
    payoutSubTotal + totalTaxes,
    refundFee,
    shippingAmount,
    storeCreditSubtotal,
    storeCreditFee,
    Number(bonusAmount),
    exchangeItems.length > 0
  );

  // conditions for disabling submit
  const shouldDisableSubmit = !canAllocateFees || isCreateReturnCaseWorking || isInitializing;

  const handleSubmitClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Not allowed to submit a return if fees can't be allocated
    if (!canAllocateFees) {
      return;
    }

    try {
      const { data, errors } = await createReturnCase();

      if (errors) {
        setShowCreationError(true);
        // @todo -- Next step is displaying a contextual error message
      } else {
        analytics.returnCreated();

        const createReturnCaseWithOrderLookupParams = data?.createReturnCaseWithOrderLookupParams;

        saveAndNavigate('/return/confirmation', {
          createdReturnCase: createReturnCaseWithOrderLookupParams,
          sessionId: '',
        });
      }
    } catch (error: any) {
      setShowCreationError(true);
      // TODO: We should log the error to sentry (if there's an error we want to log here)
    }
  };

  const joinedAddress = [
    shippingAddress.address1,
    shippingAddress.address2,
    shippingAddress.province,
    shippingAddress.countryCode,
    shippingAddress.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  /**
   * Handles closing the error message dialog
   */
  const dismissErrorModal = () => {
    setShowCreationError(false);
  };

  const hasMultiplePayoutMethods = returnMethodsMap.every((item) => {
    return returnCaseItemInput.map((item) => item.returnMethod).includes(item);
  });
  const canSelectPayoutMethod = remainingItems.length > 0;

  return (
    <>
      <DefaultAppPage title={t('reviewReturnPage.title')}>
        <ReviewReturnSection
          title={t('reviewReturnPage.contactInfo')}
          action={
            hasExchangeItems ? (
              <NavLink to={constructURL('return-method#contactInfo', inGeo)} state={location.state}>
                {t('common.change')}
              </NavLink>
            ) : null
          }
          className={styles.contactInfo}
        >
          {isInitializing ? (
            <>
              <Placeholder height={24} width={72} className={styles.contactPlaceholder} />
              <Placeholder height={24} width={160} className={styles.contactPlaceholder} />
              <Placeholder height={24} width={325} className={styles.contactPlaceholder} />
            </>
          ) : (
            <>
              {/* Hide/exclude info on fullstory with fs-exclude */}
              <div className={clsx(styles.contactInfoRow, 'fs-exclude')}>
                {shippingAddress.name}
              </div>
              <div className={clsx(styles.contactInfoRow, 'fs-exclude')}>{storeOrder?.email}</div>

              {hasExchangeItems && (
                <div className={styles.contactInfoRow}>
                  {t('reviewReturnPage.shipTo', {
                    address: joinedAddress,
                  })}
                </div>
              )}
            </>
          )}
        </ReviewReturnSection>

        <ReviewReturnSection
          title={t('reviewReturnPage.returnMethod')}
          action={
            currentReturnChannel.length < 2 ? null : (
              <NavLink to={constructURL('return-method', inGeo)} state={location.state}>
                {t('common.change')}
              </NavLink>
            )
          }
          className={styles.returnMethod}
        >
          {isDropOff ? (
            <>
              {t('reviewReturnPage.dropOffAtLocation')}
              <div className={styles.dropOffLocation}>
                <div className={styles.dropOffIcon}>
                  <IconReturnBear />
                </div>
                <div className={styles.dropOffDetails}>
                  <strong>{dropoffLocation?.name}</strong>
                  <br />
                  {dropoffLocation?.address}
                </div>
              </div>
            </>
          ) : (
            t('reviewReturnPage.mailIn')
          )}
        </ReviewReturnSection>

        {hasPayoutItems && (
          <ReviewReturnSection
            title={t('reviewReturnPage.payoutMethod')}
            action={
              canSelectPayoutMethod && (
                <NavLink to={constructURL('payout-method', inGeo)} state={location.state}>
                  {t('common.change')}
                </NavLink>
              )
            }
            className={styles.refundMethod}
          >
            {hasMultiplePayoutMethods
              ? t('reviewReturnPage.storeCreditAndRefund')
              : payoutMethod === ReturnCaseItemReturnMethod.Refund
              ? t('reviewReturnPage.originalPaymentMethod')
              : t('reviewReturnPage.storeCredit')}
          </ReviewReturnSection>
        )}

        <section className={styles.items}>
          <div className={styles.returnItems}>
            {refundItems.length > 0 && (
              <>
                <div className={styles.itemsHeader}>
                  <div className={styles.subHeader}>
                    {t('reviewReturnPage.returnsTitle', { count: refundItems.length })}
                  </div>
                  <NavLink
                    to={constructURL('select-items', inGeo)}
                    className={styles.changeLink}
                    state={location.state}
                  >
                    {t('common.change')}
                  </NavLink>
                </div>
                <div className={styles.returnItemsGroup}>
                  {returnCaseItemInput
                    .filter(
                      (item) =>
                        item.returnMethod &&
                        returnMethodsMap.map((x) => x.toString()).includes(item.returnMethod)
                    )
                    .map((returnItem, index) => (
                      <ProductThumbnail
                        key={`${returnItem.lineItemJson.id}-${index}`}
                        size={60}
                        alt={returnItem.lineItemJson.product?.title || returnItem.lineItemJson.name}
                        src={getProductImage(returnItem.lineItemJson)}
                        containerClass={styles.productThumbnailContainer}
                        className={styles.productThumbnail}
                      >
                        <div className={styles.itemContent}>
                          <div className={styles.returnMethodContainer}>
                            <div className={styles.productName}>
                              {returnItem.lineItemJson.product?.title ||
                                returnItem.lineItemJson.name ||
                                t('common.notApplicable')}
                            </div>
                            {hasMultiplePayoutMethods && (
                              <div className={styles.returnMethodText}>
                                {returnItem.returnMethod === ReturnCaseItemReturnMethod.Refund
                                  ? t('common.refund')
                                  : returnItem.returnMethod ===
                                    ReturnCaseItemReturnMethod.StoreCredit
                                  ? t('common.storeCredit')
                                  : ''}
                              </div>
                            )}
                          </div>
                          <div className={styles.variantName}>
                            {removeDefaultTitle(returnItem.lineItemJson.variant?.title)}
                          </div>
                        </div>
                      </ProductThumbnail>
                    ))}
                </div>
              </>
            )}

            {exchangeItems.length > 0 && (
              <>
                <div className={styles.itemsHeader}>
                  <div className={styles.subHeader}>
                    {t('reviewReturnPage.exchangesTitle', { count: exchangeItems.length })}
                  </div>
                  <NavLink
                    to={constructURL('select-items', inGeo)}
                    className={styles.changeLink}
                    state={location.state}
                  >
                    {t('common.change')}
                  </NavLink>
                </div>
                <div className={styles.returnItemsGroup}>
                  {exchangeItems.map((exchangeItem) => (
                    <ProductThumbnail
                      size={60}
                      alt={`${exchangeItem.lineItem.product.title} return image`}
                      src={
                        exchangeItem?.exchangeVariant?.image ??
                        exchangeItem?.lineItem.product?.image ??
                        MISSING_IMAGE_URL
                      }
                      containerClass={styles.productThumbnailContainer}
                      className={styles.productThumbnail}
                      key={exchangeItem.lineItem.id}
                    >
                      <div className={styles.productName}>
                        {exchangeItem.lineItem.product.title}
                      </div>
                      <div className={styles.oldVariantName}>
                        {removeDefaultTitle(exchangeItem.lineItem.variant.title)}
                      </div>
                      <div className={styles.variantName}>
                        {t('reviewReturnPage.exchangingFor', {
                          variantName: exchangeItem?.exchangeVariant?.title,
                          interpolation: { escapeValue: false },
                        })}
                      </div>
                    </ProductThumbnail>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>

        <div className={styles.divider} />

        <section>
          <div className={styles.itemsHeader}>
            <div className={styles.header}>{t('reviewReturnPage.returnSummary')}</div>
          </div>

          <div className={styles.lineItem}>
            {t('reviewReturnPage.itemsSubTotal')}
            <div className={styles.amount}>
              {currency ? (
                formatPrice({ amount: Number(payoutSubTotal), currency })
              ) : (
                <Placeholder width={48} />
              )}
            </div>
          </div>

          {hasTaxesWithheld ? null : (
            <div className={styles.lineItem}>
              {t('reviewReturnPage.taxes')}
              <div className={styles.amount}>
                {currency ? (
                  formatPrice({ amount: totalTaxes, currency })
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          )}

          {hasTaxesWithheld ? null : <div className={clsx(styles.divider, styles.spacingBottom)} />}

          {hasTaxesWithheld ? null : (
            <div className={styles.lineItem}>
              {t('reviewReturnPage.returnTotal')}
              <div className={styles.amount}>
                {currency ? (
                  formatPrice({ amount: totalTaxes + payoutSubTotal, currency })
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          )}

          <div className={clsx(styles.divider, styles.spacingBottom)} />
          {isDropOff && !hasDropOffFlatRateFee ? (
            <div className={styles.lineItem}>
              {t('reviewReturnPage.dropOff')}
              <div className={styles.amount}>{t('common.free')}</div>
            </div>
          ) : hasInboundShippingFee || hasUSCrossBorderFee ? (
            <div className={styles.lineItem}>
              {hasUSCrossBorderFee && hasInboundShippingFee
                ? usCrossBorderFeeName
                : hasInboundShippingFee
                ? t('reviewReturnPage.estimatedShippingCost')
                : t('reviewReturnPage.crossBorderOnlyFee')}

              <div className={styles.amount}>
                {currency ? (
                  shippingEstimate + usCrossBorderFee ? (
                    '-' + formatPrice({ amount: shippingEstimate + usCrossBorderFee, currency })
                  ) : (
                    ''
                  )
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : (
            !hasMailInFlatRateFee &&
            !isDropOff && (
              <div className={styles.lineItem}>
                {t('reviewReturnPage.mailInFee')}
                <div className={styles.amount}>{t('common.free')}</div>
              </div>
            )
          )}

          {hasRefundFee && refundFee > 0 ? (
            <div className={styles.lineItem}>
              {storeConfig?.fees?.refund.feeName || t('reviewReturnPage.defaultRefundFee')}
              <div className={styles.amount}>
                {currency ? (
                  `- ${formatPrice({ amount: refundFee, currency })}`
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : null}
          {hasStoreCreditFee && storeCreditFee > 0 ? (
            <div className={styles.lineItem}>
              {storeConfig?.fees?.storeCredit.feeName ||
                t('reviewReturnPage.defaultStoreCreditFee')}
              <div className={styles.amount}>
                {currency ? (
                  `- ${formatPrice({ amount: storeCreditFee, currency })}`
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : null}
          {hasExchangeFee && exchangeOnlyItemsFee > 0 ? (
            <div className={styles.lineItem}>
              {storeConfig?.fees?.exchange.feeName || t('reviewReturnPage.defaultExchangeFee')}
              <div className={styles.amount}>
                {currency ? (
                  `- ${formatPrice({ amount: exchangeOnlyItemsFee, currency })}`
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : null}

          {hasFlatRateFee && !!flatRateFeeAmountForSelectedReturnMethod && flatRateFeeName ? (
            <div className={styles.lineItem}>
              {flatRateFeeName}
              <div className={styles.amount}>
                {currency ? (
                  `- ${formatPrice({ amount: flatRateFeeAmountForSelectedReturnMethod, currency })}`
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : null}

          {hasStoreCreditBonus && bonusAmount ? (
            <div className={styles.lineItem}>
              {t('reviewReturnPage.storeCreditBonus')}
              <div className={styles.bonusAmount}>
                {currency ? (
                  `+ ${formatPrice({ amount: Number(bonusAmount), currency })}`
                ) : (
                  <Placeholder width={48} />
                )}
              </div>
            </div>
          ) : null}

          <div className={clsx(styles.divider, styles.spacingBottom)} />

          <div className={styles.total}>
            {total >= 0 ? t('reviewReturnPage.total') : t('reviewReturnPage.amountPayable')}
            <div className={styles.amount}>
              {currency ? (
                hasTaxesWithheld ? (
                  formatPrice({ amount: totalTaxesWithHeld, currency })
                ) : (
                  formatPrice({ amount: total, currency })
                )
              ) : (
                <Placeholder width={48} />
              )}
            </div>
          </div>

          {hasTaxesWithheld ? (
            <div className={styles.taxCommunications}>
              <div className={styles.taxCommunications}>
                {t('reviewReturnPage.taxCommunication', { merchant: themeConfig?.name })}
              </div>
              <Trans
                i18nKey="reviewReturnPage.taxCommunicationContact"
                components={{
                  1: (
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={`mailto:${storeConfig?.customerServiceEmail}`}
                    >
                      {storeConfig?.customerServiceEmail}
                    </a>
                  ),
                  2: <b></b>,
                }}
                values={{ merchant: themeConfig?.name }}
              />
            </div>
          ) : null}
        </section>

        <Dialog
          title={t('reviewReturnPage.errors.title')}
          closeButtonLabel={t('common.close')}
          isVisible={showCreationError}
          onClose={dismissErrorModal}
          actions={<Button onClick={dismissErrorModal}>{t('common.close')}</Button>}
        >
          {formattedCreationErrors?.message}
        </Dialog>
      </DefaultAppPage>

      <ContinueCtaWrapper
        continueButton={
          <Button
            onClick={handleSubmitClick}
            isWorking={isCreateReturnCaseWorking}
            disabled={shouldDisableSubmit}
          >
            {t('reviewReturnPage.submitReturn')}
          </Button>
        }
      />
    </>
  );
};
