import { FormikValues } from 'formik';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';

import {
  ExchangeOrReturn,
  RefundFeeInterface,
  ExchangeFeeInterface,
  StoreCreditFeeInterface,
  AmountTypes,
  ReturnItem,
  StoreOrderLineItem,
} from 'app/types';
import {
  CANADA_COUNTRY_CODE,
  ReturnMethods,
  RuleOutcomeKey,
  SHOPIFY_DEFAULT_VARIANT_TITLE,
} from 'app/constants';
import { ReturnCaseItemReturnMethod, StoreSellerTypeChoice } from 'generated/graphql';

import { formatPrice, MoneyBag } from './number';

/**
 * Checks to see if in dev environment, if so return custom subdomain
 * @returns subdomain
 */
export const getSubdomain = (): string => {
  if (process.env.REACT_APP_SHOPIFY_STORE_NAME) {
    return process.env.REACT_APP_SHOPIFY_STORE_NAME;
  }

  const subdomain = window.location.hostname.split('.')[0] || '';
  if (subdomain === 'localhost') {
    return 'development-store';
  }
  return subdomain;
};

/**
 * Builds the alert message for the product item
 * @param  values the formik values
 * @param  t i18tn TFunction
 * @param  exchangeFee The exchange fee for an alert
 * @param  availableReturnMethods The return methods available to the item
 * @returns {alertMessage} an array of string
 */
export const buildItemSelectAlertMessage = (
  values: FormikValues,
  t: TFunction,
  exchangeFee: MoneyBag,
  availableReturnMethods: ReturnCaseItemReturnMethod[]
) => {
  const alertMessages = [];
  // Exchanges are final sale, and cannot be returned again.
  if (
    values.returnMethod === ExchangeOrReturn.Exchange &&
    availableReturnMethods.includes(ReturnCaseItemReturnMethod.Exchange)
  ) {
    // exchanges are always final sale
    alertMessages.push(t('productReturnCard.finalSale'));

    // show fee text
    if (exchangeFee) {
      alertMessages.push(
        t('productReturnCard.hasFeeAmount', {
          feeAmount: formatPrice(exchangeFee),
          exchangeOrReturn: 'exchange',
        })
      );
    }
  }

  if (values.returnMethod === ExchangeOrReturn.Return) {
    if (
      [ReturnCaseItemReturnMethod.Refund, ReturnCaseItemReturnMethod.StoreCredit].every(
        (elem) => availableReturnMethods.indexOf(elem) > -1
      )
    ) {
      alertMessages.push(t('productReturnCard.returnableForStoreCreditAndRefund'));
    } else if (availableReturnMethods.includes(ReturnCaseItemReturnMethod.StoreCredit)) {
      alertMessages.push(t('productReturnCard.returnableForStoreCredit'));
    } else if (availableReturnMethods.includes(ReturnCaseItemReturnMethod.Refund)) {
      alertMessages.push(t('productReturnCard.returnableForRefund'));
    }
  }
  return alertMessages;
};

/**
 * Returns the fee amount for a given fee type and dollar amount
 * @param  returnAmount the return amount
 * @param  feeObject the stores fee object for a return type
 * @returns fee amount as number
 */
export const getFeeAmount = (
  returnAmount: number,
  feeObject?: RefundFeeInterface | StoreCreditFeeInterface | ExchangeFeeInterface
) => {
  let total = 0;
  if (!feeObject || !feeObject.feeAmount || !feeObject.feeAmountType) {
    return total;
  }
  if (returnAmount <= 0) {
    return total;
  }
  const feeAmount = parseFloat(feeObject.feeAmount);
  const feeType = feeObject.feeAmountType;
  if (feeType === AmountTypes.NUMBER) {
    total = feeAmount;
  }
  if (feeType === AmountTypes.PERCENT) {
    total = returnAmount * (feeAmount / 100);
  }
  return total;
};

/**
 * Returns the total fee amount for an array of items and a given fee
 * @param  selectedItems an array of items to be returned
 * @param  feeObject the stores fee object for a return type
 * @returns total fee amount
 */
export const getTotalFeeAmountForItems = (
  selectedItems: ReturnItem[],
  feeObject?: RefundFeeInterface | StoreCreditFeeInterface | ExchangeFeeInterface
) => {
  return selectedItems.reduce(
    (total, current) => total + getFeeAmount(current.lineItem.subtotal || 0, feeObject),
    0
  );
};

/**
 * Returns the payout methods for a line item
 * @param  lineItem an StoreOrderLineItem item
 * @returns a payout method from the line item rule outcomes
 */
export const getItemPayoutRuleOutcome = (lineItem: StoreOrderLineItem) => {
  return lineItem.ruleOutcome?.find((outcome) => outcome.key === RuleOutcomeKey.PAYOUT_METHOD);
};

/**
 * Returns the payout methods for a line item
 * @param  lineItem the order line item
 * @param  storePayoutMethods the return methods enabled for the store
 * @returns ReturnItemMethodEnum[] An array of return methods
 */
export const getItemPayoutMethods = (
  lineItem?: StoreOrderLineItem,
  storePayoutMethods?: ReturnCaseItemReturnMethod[]
) => {
  const rulePayoutMethods: ReturnCaseItemReturnMethod[] | undefined = lineItem
    ? getItemPayoutRuleOutcome(lineItem)?.target
    : undefined;

  // Prioritize the store payout methods if there is no rule outcome payout
  // method.
  // NOTE: Previously we filtered the list of rule outcome payout methods to
  // only include store payout methods. Now, we include any rule outcome payout
  // method, even if the store doesn't have that payout method available.
  // This change was made based on these tickets:
  // https://app.shortcut.com/returnbear/story/5067/gift-registry-items
  // https://app.shortcut.com/returnbear/story/5068/gift-receipt-items-orderline-giftflag
  const payoutMethods = !rulePayoutMethods?.length ? storePayoutMethods : rulePayoutMethods;

  return payoutMethods || [];
};

/**
 * Return a filtered array of available return channels based on the shipping
 * country code.
 * @param returnChannels Array of return channels
 * @param shippingCountryCode The from shipping address ISO alpha-2 country code
 * @returns Filtered array of return channels
 */
export const getReturnChannels = (
  returnChannels: ReturnMethods[],
  shippingCountryCode?: string
) => {
  // Cross border returns do not allow drop off return cases
  if (shippingCountryCode !== CANADA_COUNTRY_CODE) {
    return returnChannels.filter((channel) => channel !== ReturnMethods.DropOff);
  }

  return returnChannels;
};

/**
 * Checks whether if there are line items in the array that are Marketplace owned.
 * @param lineItems Array of line items
 * @returns Boolean indicating that one or more line items are Marketplace owned.
 */
export const getIsMarketPlaceOwned = (lineItems: ReturnItem[]) => {
  return lineItems.some(
    (element) => element.lineItem.store?.sellerType === StoreSellerTypeChoice.MarketplaceOwned
  );
};

/**
 * Gets the valid ReturnCaseItemReturnMethod for a line item
 * country code.
 * @param lineItemPayoutMethods The available methods for a line item
 * @param itemReturnMethod the selected return method for the item (exchange or refund)
 * @param payoutMethod the globally selected payout method during the flow (refund or store credit)
 * @returns ReturnCaseItemReturnMethod
 */
export const getValidLineItemReturnMethod = (
  lineItemPayoutMethods: ReturnCaseItemReturnMethod[],
  itemReturnMethod: ExchangeOrReturn | null,
  payoutMethod?: ReturnCaseItemReturnMethod
) => {
  // if the line item only has one available payout method then use it.
  if (lineItemPayoutMethods.length === 1) {
    return lineItemPayoutMethods[0];
  }
  // if the user selected exchange and its allowed
  if (
    itemReturnMethod === ExchangeOrReturn.Exchange &&
    lineItemPayoutMethods.includes(ReturnCaseItemReturnMethod.Exchange)
  ) {
    return ReturnCaseItemReturnMethod.Exchange;
  }
  // if the user selected payout method is in the available methods use it
  // this catches refunds and store credit
  if (payoutMethod && lineItemPayoutMethods.includes(payoutMethod)) {
    return payoutMethod;
  }

  // if the user selected return (AKA original payment method), but an item is
  // available for store credit only, we need to prioritize store credit only
  if (
    payoutMethod === ReturnCaseItemReturnMethod.Refund &&
    lineItemPayoutMethods.includes(ReturnCaseItemReturnMethod.StoreCredit)
  ) {
    return ReturnCaseItemReturnMethod.StoreCredit;
  }

  // For items that are caught by rules and where the basket of items have no option to select a payout method.
  // If the item is to be returned as store credit or a refund.
  // This should exclude the case where an item can both be store credit or return, since the user should be able to set the payout method,
  // and it would be caught by one of the clauses before this and return out.
  if (
    itemReturnMethod === ExchangeOrReturn.Return &&
    [ReturnCaseItemReturnMethod.StoreCredit, ReturnCaseItemReturnMethod.Refund].some((method) =>
      lineItemPayoutMethods.includes(method)
    )
  ) {
    return lineItemPayoutMethods.filter((item) => item !== ReturnCaseItemReturnMethod.Exchange)[0];
  }
  // its possible no payout methods are set and the item can't be returned
  return undefined;
};

/**
 * Determines if a return case can be created from the total amount and fees
 * currently, if the return case has a negative amount and exchange items we can invoice the fees to the exchange
 * we do not have the ability to invoice return items or store credit items therefore they can't be created if the values are negative without an exchange case
 * @param totalRefundAmount the total amount for the store credit and return items
 * @param totalRefundFeeAmount  the total fee amount for the store credit and return items
 * @param shippingCost the shipping cost
 * @param totalStoreCreditAmount the total amount for store credit items
 * @param totalStoreCreditFee the total amount fee for store credit items
 * @param bonusAmount the Bonus Amount
 * @param hasExchangeItems if the return case has exchange items
 * @returns {boolean}  can the return case be created from fee allocation
 */
export const canAllocateFeesToReturnMethod = (
  totalRefundAmount: number,
  totalRefundFeeAmount: number,
  shippingCost: number,
  totalStoreCreditAmount: number,
  totalStoreCreditFee: number,
  bonusAmount: number,
  hasExchangeItems: boolean
) => {
  const remainingRefundAmount = totalRefundAmount - totalRefundFeeAmount - shippingCost;

  if (remainingRefundAmount > 0) {
    return true;
  }

  const remainingStoreCreditAmount =
    totalStoreCreditAmount - totalStoreCreditFee + bonusAmount - shippingCost;

  if (remainingStoreCreditAmount > 0) {
    return true;
  }

  if (hasExchangeItems) {
    return true;
  }
  return false;
};

/**
 * Determines if a return item has lapsed the return window
 * @param timestamp the timestamp the item was fulfilled
 * @param returnWindow the return window as a number of days
 * @returns {boolean}  has the item has lapsed the return window
 */
export const hasExceededReturnWindow = (timestamp: string, returnWindow?: string): boolean => {
  if (!timestamp || isNaN(Number(returnWindow))) {
    return false;
  }
  const days = dayjs().diff(dayjs.utc(timestamp), 'day');
  return Number(returnWindow) < days;
};

/**
 * Filters a list of items for store credit only items
 * @param payoutItems a list of payout items
 * @returns {storeCreditOnlyItems} a list of store credit only items
 */
export const getStoreCreditOnlyItems = (payoutItems: ReturnItem[]) => {
  const storeCreditOnlyItems = payoutItems.filter((row) => {
    // check if rules outcome is null, nulls denote all rules passed.
    // we don't want to allow the next check to negate the null or else we'll double count.
    // payoutMethods contains all valid payout methods. check for the negation

    const payoutMethods = getItemPayoutRuleOutcome(row.lineItem)?.target;

    const allowsRefunds = payoutMethods?.includes(ReturnCaseItemReturnMethod.Refund);
    const allowsStoreCredit = payoutMethods?.includes(ReturnCaseItemReturnMethod.StoreCredit);

    return row.lineItem.ruleOutcome && !allowsRefunds && allowsStoreCredit;
  });

  return storeCreditOnlyItems;
};

/**
 * Filters a list of items for refund only items
 * @param payoutItems a list of payout items
 * @returns {ReturnItem[]} a list of refund only items
 */
export const getRefundOnlyItems = (payoutItems: ReturnItem[]) => {
  const refundOnlyItems = payoutItems.filter((row) => {
    const payoutMethods = getItemPayoutRuleOutcome(row.lineItem)?.target;
    const allowsRefunds = payoutMethods?.includes(ReturnCaseItemReturnMethod.Refund);
    const allowsStoreCredit = payoutMethods?.includes(ReturnCaseItemReturnMethod.StoreCredit);

    return row.lineItem.ruleOutcome && allowsRefunds && !allowsStoreCredit;
  });
  return refundOnlyItems;
};

export const subtotalReducer = (amount: number, returnItem: ReturnItem) =>
  amount + Number(returnItem.lineItem.subtotal) / Number(returnItem.lineItem.quantity);

export const taxReducer = (amount: number, returnItem: ReturnItem) =>
  amount + Number(returnItem.lineItem.totalTax) / Number(returnItem.lineItem.quantity);

export const removeDefaultTitle = (title: string) => {
  const cleanedString = title === SHOPIFY_DEFAULT_VARIANT_TITLE ? '' : title;
  return cleanedString;
};

/**
 * Checks and returns what the StoreSellerTypeChoice is based on the user's
 * selected items for return.
 * @param lineItems Array of line items
 * @returns StoreSellerTypeChoice or null.
 */
export const getStoreSellerTypeChoice = (lineItems: ReturnItem[]) => {
  const isMarketPlaceOwned = lineItems.some(
    (element) => element.lineItem.store?.sellerType === StoreSellerTypeChoice.MarketplaceOwned
  );
  const isMarketPlaceThirdParty = lineItems.some(
    (element) => element.lineItem.store?.sellerType === StoreSellerTypeChoice.MarketplaceThirdParty
  );
  const isD2C = lineItems.some(
    (element) => element.lineItem.store?.sellerType === StoreSellerTypeChoice.D2C
  );

  if (isMarketPlaceOwned) {
    return StoreSellerTypeChoice.MarketplaceOwned;
  }

  if (isMarketPlaceThirdParty) {
    return StoreSellerTypeChoice.MarketplaceThirdParty;
  }

  if (isD2C) {
    return StoreSellerTypeChoice.D2C;
  }

  return null;
};
