import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useTranslation, Trans, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';

import { useProductVariants } from 'app/hooks';
import { ReturnReasonsDropdown } from 'app/components';
import {
  ExchangeOrReturn,
  ReturnItem,
  StoreConfigInterface,
  StoreOrderLineItem,
  ExchangeFormRecord,
  ReturnDataState,
  StoreOrderWithLookupParams_LineItem_StoreType,
  ReturnAcceptanceCriteria,
} from 'app/types';
import { Checkbox, ProductThumbnail, ToggleTokens, SelectItemShape, Placeholder } from 'ui';
import {
  buildItemSelectAlertMessage,
  getFeeAmount,
  formatPrice,
  buildReturnFeeDisplay,
  getItemPayoutMethods,
  getProductImage,
  MISSING_IMAGE_URL,
  removeDefaultTitle,
} from 'utils';
import { ReturnCaseItemReturnMethod } from 'generated/graphql';

import styles from './ProductReturnCard.module.scss';

import { AdditionalCommentsInput } from '../AdditionalCommentsInput';
import { ExchangeOptionPicker } from '../ExchangeOptionPicker';

interface ProductReturnCardProps {
  /**
   * The item index in the list, used to create a unique id
   */
  index: number;

  /** The currency of the order */
  currency?: string;

  /**
   * The list of selected item ids.
   */
  selectedItems: string[];
  /**
   * A function that updates the list of selected item ids
   */
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;

  /**
   * The lineItem from the storeOrder object
   */
  lineItem: StoreOrderLineItem;

  /**
   * A function that updates the list of valid item data
   * This updates the line item data if the values are valid
   * Also allows for the item to be removed if invalid or unselected.
   */
  setReturnItems: React.Dispatch<React.SetStateAction<ReturnDataState>>;

  /**
   * The initial values to display
   */
  initialData?: Partial<ReturnItem>;
  criteria?: ReturnAcceptanceCriteria[];
  storeConfig?: StoreConfigInterface | null;
  hasStoreCreditFee?: boolean;
  hasExchangeFee?: boolean;
  hasRefundFee?: boolean;
  hasExchangeItemSelected?: boolean;
  invalidStoreCreditFee?: boolean;
  invalidReturnCaseFee?: boolean;
  storeReturnReasons: SelectItemShape[];
  selectedItemStore?: StoreOrderWithLookupParams_LineItem_StoreType | null;
  disabled?: boolean;
}

/**
 * Creates a dynamic object schema for the exchange options
 * with each value requiring an input
 */
const ExhangeOptionsValidationSchema = (
  exchangeOptions: ExchangeFormRecord,
  t: TFunction
): Yup.AnyObjectSchema => {
  const validationObject: { [key: string]: Yup.StringSchema } = {};
  Object.keys(exchangeOptions).forEach((key) => {
    validationObject[key] = Yup.string().required(t('errors.required'));
  });
  return Yup.object(validationObject).required(t('errors.required'));
};

/**
 * Yup schema for the product return card options
 */
const productReturnCardSchema = (t: TFunction) => {
  return Yup.object({
    returnReasonUid: Yup.string().required(t('errors.required')),
    returnMethod: Yup.string().required(t('errors.required')),
    additionalComments: Yup.string().nullable(),
    exchangeVariantId: Yup.string().when('returnMethod', {
      is: (val: ExchangeOrReturn) => val === ExchangeOrReturn.Exchange,
      then: Yup.string().required(t('errors.required')),
    }),
    exchangeOption: Yup.object().when('returnMethod', {
      is: (val: ExchangeOrReturn) => val === ExchangeOrReturn.Exchange,
      then: Yup.lazy((obj) => ExhangeOptionsValidationSchema(obj, t)),
    }),
    isSelected: Yup.boolean().required(t('errors.required')),
  });
};

/**
 * Renders a component that handles displaying an item, as well as capturing return flow
 * data for the selected item
 */
export const ProductReturnCard = ({
  index,
  currency,
  hasExchangeFee,
  hasRefundFee,
  hasStoreCreditFee,
  initialData,
  lineItem,
  selectedItems,
  setSelectedItems,
  setReturnItems,
  storeConfig,
  invalidReturnCaseFee,
  invalidStoreCreditFee,
  hasExchangeItemSelected,
  storeReturnReasons,
  selectedItemStore,
  disabled,
  criteria,
}: ProductReturnCardProps) => {
  const { t } = useTranslation();
  const { product, subtotal, variant, quantity } = lineItem;
  const singleItemSubtotal = subtotal / quantity;
  const a11yFocusRef = useRef<HTMLDivElement>(null);
  const availableReturnMethods = getItemPayoutMethods(lineItem, storeConfig?.returnMethod);

  const storeCreditAvailable = availableReturnMethods.includes(
    ReturnCaseItemReturnMethod.StoreCredit
  );
  const exchangeAvailable =
    (lineItem.product &&
      lineItem.variant &&
      availableReturnMethods.includes(ReturnCaseItemReturnMethod.Exchange)) ||
    false;
  const refundAvailable = availableReturnMethods.includes(ReturnCaseItemReturnMethod.Refund);

  const returnMethods = useMemo(() => {
    const methods = [];
    if (exchangeAvailable) {
      methods.push({ value: ExchangeOrReturn.Exchange, label: t('common.exchange') });
    }

    if (storeCreditAvailable || refundAvailable) {
      methods.push({ value: ExchangeOrReturn.Return, label: t('common.return') });
    }

    return methods;
  }, [exchangeAvailable, refundAvailable, storeCreditAvailable, t]);

  const defaultExchangeOption: ExchangeFormRecord = {};
  const selectedItemId = `${index}-${lineItem.id}`;
  const defaultReturnMethod = exchangeAvailable
    ? ExchangeOrReturn.Exchange
    : ExchangeOrReturn.Return;

  const initialValues = {
    isSelected: false,
    selectedItemId,
    lineItem,
    returnReasonText: '',
    returnReasonUid: '',
    returnMethod: defaultReturnMethod,
    additionalComments: '',
    exchangeVariantId: lineItem.variant?.id || '',
    exchangeOption: defaultExchangeOption,
    ...initialData,
  };

  const { values, submitForm, isValid, ...formik } = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: productReturnCardSchema(t),
    onSubmit: (values: ReturnItem) => {
      // validation runs before the onSubmit
      // https://formik.org/docs/guides/validation
      // therefore only check if the item is selected
      if (values.isSelected) {
        setReturnItems((prevState) => {
          return { ...prevState, [selectedItemId]: values };
        });
      }
    },
  });

  const {
    exchangeOptions,
    loading: variantsLoading,
    validatedVariant,
    validatedVariantId,
  } = useProductVariants(lineItem.product?.id || '', values.exchangeOption, lineItem.variant, {
    skip: !values.isSelected || !exchangeAvailable,
  });

  useEffect(() => {
    if (values === null) {
      return;
    }

    // update the selected items when ever the values change
    setSelectedItems((prevState) => {
      let newState: string[] = prevState;

      // add the line item to the selected array if selected and not already included
      if (values.isSelected && !prevState.includes(selectedItemId)) {
        newState = [...prevState, selectedItemId];
      }
      // remove the line item whenever it is unselected
      if (!values.isSelected) {
        newState = prevState.filter((variantId) => variantId !== selectedItemId);
      }
      return newState;
    });
    // Trigger the 'submitForm' initially when the component renders so that the
    // parent component can build it's initial data
    // this triggers whenever a form value changes since there's no button to submit the line item form
    submitForm();
  }, [values, submitForm, setSelectedItems, selectedItemId]);

  // the form submits on change therefore
  // check these conditions for adding or removing from the selected item list
  useEffect(() => {
    const removeItemFromSelectedList = () => {
      setReturnItems((prevState) => {
        const newState = { ...prevState };
        delete newState[selectedItemId];
        return { ...newState };
      });
    };
    // the item needs to be removed or added on change if valid
    !isValid && removeItemFromSelectedList();
    // the item needs to be removed if deselected
    !values.isSelected && removeItemFromSelectedList();
  }, [isValid, values, setReturnItems, selectedItemId]);

  const handleContainerClick = (_: React.SyntheticEvent) => {
    if (values.isSelected) {
      return;
    }
    formik.setFieldValue('isSelected', true);

    if (a11yFocusRef.current) {
      a11yFocusRef.current.focus();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (values.isSelected) {
      return;
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      formik.setFieldValue('isSelected', true);

      if (a11yFocusRef.current) {
        a11yFocusRef.current.focus();
      }
    }
  };

  const returnLinkButton = (
    <button
      className={styles.returnLinkButton}
      onClick={() => formik.setFieldValue('returnMethod', ExchangeOrReturn.Return)}
    />
  );

  const hasReturnFee = hasStoreCreditFee || hasRefundFee;
  const exchangeFee = getFeeAmount(singleItemSubtotal, storeConfig?.fees?.exchange);
  const alertMessages = currency
    ? buildItemSelectAlertMessage(
        values,
        t,
        { amount: exchangeFee, currency },
        availableReturnMethods
      )
    : [];
  const storeCreditFee = getFeeAmount(singleItemSubtotal, storeConfig?.fees?.storeCredit);
  const refundFee = getFeeAmount(singleItemSubtotal, storeConfig?.fees?.refund);
  const returnFeeDisplay = currency
    ? buildReturnFeeDisplay(
        storeCreditAvailable && storeCreditFee
          ? { amount: storeCreditFee, currency }
          : { amount: 0, currency },
        refundFee && refundAvailable ? { amount: refundFee, currency } : { amount: 0, currency }
      )
    : '';

  // show the appropriate fee for the approproate method
  // its possible that this line item is store credit only but the total value is less than the total fees
  const invalidLineItemFee = storeCreditAvailable
    ? invalidStoreCreditFee
    : refundAvailable
    ? invalidReturnCaseFee
    : false;

  // update the exchange variant id after the option selects update
  useEffect(() => {
    formik.setFieldValue(`exchangeVariantId`, validatedVariantId);
    // don't include formik to prevent unlimited re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatedVariantId, exchangeOptions]);

  // update the exchange variant after the option dropdowns are updated
  useEffect(() => {
    formik.setFieldValue(`exchangeVariant`, validatedVariant);
    // don't include formik to prevent unlimited re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatedVariant]);

  // validate the exchange drop down options
  useEffect(() => {
    if (exchangeOptions && values.exchangeOption) {
      Object.keys(values.exchangeOption).forEach((key) => {
        // check if the previous items exist and arent disabled
        const option = exchangeOptions[key].find(
          (option) => option.value === values.exchangeOption[key]
        );
        const isValid = option && !option.disabled;
        if (!isValid) {
          formik.setFieldValue(`exchangeOption.${key}`, '');
        }
      });
    }
    // don't include formik to prevent unlimited re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeOptions]);

  const fullDisable = disabled ?? false;
  const isDisabled =
    (!!selectedItems.length &&
      !values.isSelected &&
      values.lineItem.store?.uid !== selectedItemStore?.uid) ||
    fullDisable;

  const handleReturnReasonChange = (reasonUid: string) => {
    formik.setFieldValue('returnReasonUid', reasonUid);

    // storeReturnReasons.find((reason) => reason.uid.)
  };

  return (
    <div
      // If the product isn't selected, you can click on the entire card like a
      // button. Otherwise, you need to click on the checkbox to deselect it.
      role={!values.isSelected ? 'button' : undefined}
      className={clsx(
        styles.container,
        !values.isSelected && !isDisabled && styles.selectable,
        values.isSelected && styles.selected,
        isDisabled && styles.isDisabled
      )}
      onClick={(event) => {
        if (!isDisabled) {
          handleContainerClick(event);
        }
      }}
      tabIndex={values.isSelected ? -1 : 0}
      onKeyPress={handleKeyPress}
    >
      {!fullDisable && (
        <div className={styles.checkbox}>
          <Checkbox
            tabIndex={values.isSelected ? 0 : -1}
            name="isSelected"
            checked={values.isSelected}
            onChange={formik.handleChange}
            onContainerClick={(e) => e.stopPropagation()}
            disabled={isDisabled}
          />
        </div>
      )}

      <div className={clsx(styles.productThumb, isDisabled && styles.isDisabled)}>
        <img
          src={getProductImage(lineItem)}
          alt={product?.title || lineItem.name || t('common.notApplicable')}
        />
      </div>

      <div className={styles.productInfo}>
        <div className={styles.productDetails}>
          <div className={styles.productName}>
            {product?.title || lineItem.name || t('common.notApplicable')}
          </div>

          {variant && (
            <div className={styles.variantInfo}>{removeDefaultTitle(variant?.title)}</div>
          )}
        </div>
        <div className={styles.price}>
          {currency ? (
            formatPrice({ amount: singleItemSubtotal || 0, currency })
          ) : (
            <Placeholder width={48} />
          )}
        </div>
      </div>

      <div className={styles.returnReason} tabIndex={-1} ref={a11yFocusRef}>
        {values.isSelected && (
          <>
            <ReturnReasonsDropdown
              value={values.returnReasonUid}
              onChange={handleReturnReasonChange}
              label={t('productReturnCard.returnReasonQuestion')}
              returnReasonChoices={storeReturnReasons}
            />
            <AdditionalCommentsInput
              name="additionalComments"
              value={values.additionalComments}
              onChange={formik.handleChange}
              buttonText={t('productReturnCard.addAdditionalComments')}
              labelText={t('productReturnCard.enterAdditionaLComments')}
            />
          </>
        )}
      </div>

      {values.isSelected && values.returnReasonUid && (
        <div className={styles.resolutionArea}>
          {returnMethods.length > 1 && (
            <ToggleTokens
              items={returnMethods}
              value={values.returnMethod}
              onChange={(returnMethod: string) =>
                formik.setFieldValue('returnMethod', returnMethod)
              }
            />
          )}
          {values.returnMethod === ExchangeOrReturn.Exchange && (
            <div className={styles.exchange}>
              <div className={styles.differentSizeOrColor}>
                {t('productReturnCard.exchangeForVariant')}
              </div>
              <div className={styles.finalSaleNotice}>{alertMessages.join(' ')}</div>
              <div className={styles.exchangeProduct}>
                <ProductThumbnail
                  size={136}
                  src={values?.exchangeVariant?.image ?? product?.image ?? MISSING_IMAGE_URL}
                  alt={product?.title || lineItem.name || t('common.notApplicable')}
                />

                <div className={styles.exchangeOptions}>
                  <ExchangeOptionPicker
                    lineItem={lineItem}
                    formikErrors={formik.errors.exchangeOption}
                    chosenOptions={values.exchangeOption}
                    exchangeOptions={exchangeOptions}
                    variantsLoading={variantsLoading}
                    onChange={(value, key) => {
                      formik.setFieldValue(`exchangeOption.${key}`, value);
                    }}
                  />
                </div>
              </div>

              <div className={styles.switchToReturn}>
                <Trans
                  i18nKey="productReturnCard.lookingForDifferentItem"
                  components={[returnLinkButton]}
                />
              </div>
              {hasExchangeFee && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.feeContainer}>
                    <div className={styles.feeName}>{storeConfig?.fees?.exchange.feeName}</div>
                    <div className={styles.feeAmount}>
                      {currency ? (
                        `- ${formatPrice({ amount: exchangeFee, currency })}`
                      ) : (
                        <Placeholder width={48} />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {values.returnMethod === ExchangeOrReturn.Return && (
            <div className={styles.returnOption}>
              {!lineItem.variant && (
                <div className={styles.returnInfoReturnOnly}>
                  {t('productReturnCard.deletedItem')}
                </div>
              )}

              <div className={styles.returnInfo}>{alertMessages.join(' ')}</div>

              {hasReturnFee && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.feeContainer}>
                    <div className={styles.feeName}>
                      {refundAvailable && storeCreditAvailable
                        ? t('productReturnCard.returnFee')
                        : refundAvailable && hasRefundFee
                        ? storeConfig?.fees?.refund.feeName
                        : storeCreditAvailable && hasStoreCreditFee
                        ? storeConfig?.fees?.storeCredit.feeName
                        : t('productReturnCard.returnFee')}
                    </div>
                    <div className={styles.feeAmount}>{returnFeeDisplay}</div>
                  </div>
                  {invalidLineItemFee ? (
                    <div className={styles.feeContainer}>
                      <div className={styles.errorMessage}>
                        {hasExchangeItemSelected
                          ? t('productReturnCard.feeWarning')
                          : t('productReturnCard.creditAmountError')}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          )}

          {/* optional display of RA Criteria */}
          <Grid sx={{ mt: 2 }}>
            {criteria
              ?.filter((x) => x.displayOnConsumerPortal === true)
              .map((criterion) => {
                return (
                  <Grid
                    item
                    container
                    direction={'row'}
                    spacing={1}
                    className={styles.criteria}
                    alignItems={'center'}
                    key={criterion.id}
                  >
                    <Grid item>
                      <Checkbox
                        id={`itemDeclaration.${criterion.uid}`}
                        name={`itemDeclaration.${criterion.uid}`}
                        checked={values.itemDeclaration?.[criterion.uid] ?? true}
                        onChange={(event) =>
                          formik.setFieldValue(
                            `itemDeclaration.${criterion.uid}`,
                            (event.target as HTMLInputElement).checked
                          )
                        }
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item sm>
                      <Typography
                        component={'label'}
                        htmlFor={`itemDeclaration.${criterion.uid}`}
                        variant="subtitle2"
                      >
                        {criterion.description}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </div>
  );
};
