import { createContext, useContext } from 'react';
import { Analytics } from '@segment/analytics-next';

import { useSegmentProvider } from './useSegmentProvider';

export interface SegmentContextInterface {
  analytics?: Analytics;
}

export const SegmentContext = createContext<SegmentContextInterface>({
  analytics: undefined,
});

/**
 * Provides a context wrapper that loads segment
 */
export const SegmentContextProvider: React.FC<React.ReactNode> = ({ children }) => (
  <SegmentContext.Provider value={useSegmentProvider()}>{children}</SegmentContext.Provider>
);

export const useSegmentContext = () => {
  return useContext(SegmentContext);
};
