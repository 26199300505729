import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { RETAILER_ADMIN_URL } from 'app/environment';
import { useInGeo } from 'app/hooks/useInGeo';
import { useStoreConfig } from 'app/contexts';
import { useReturnFlow, useSaveAndNavigate } from 'app/hooks';
import { ReturnSteps } from 'app/constants';
import { IconArrowLeft } from 'icons';
import { constructURL } from 'utils/url';
import { Chip } from 'ui/Chip';

import { StepperLink } from './components/StepperLink';
import styles from './Stepper.module.scss';

import { Button, Modal } from '../../../ui';

interface Step {
  label: string;
  path: { route: string; fullPath: string };
  isVisible?: boolean;
  isClickable?: boolean;
  isCompleted?: boolean;
}

/**
 * Renders the progress stepper used at the top of most pages
 *
 * @returns React.FC
 */
export const Stepper = () => {
  const { goBack, reset } = useSaveAndNavigate();
  const { themeConfig } = useStoreConfig();
  const location = useLocation();
  const returnData = useReturnFlow();
  const inGeo = useInGeo();
  const { t } = useTranslation();
  const { isAdmin } = useReturnFlow();

  const [cookies] = useCookies();
  const cookieFromRetailerAdmin = cookies['consumer_portal_admin'] ?? '';

  const subpath = useMemo(() => {
    const urlParts = location.pathname.split('/');
    return urlParts[2];
  }, [location]);

  const steps = useMemo<Step[]>(() => {
    return [
      {
        label: t('stepper.locateOrder'),
        path: {
          route: ReturnSteps.LocateOrder,
          fullPath: constructURL(ReturnSteps.LocateOrder, inGeo),
        },
        isClickable: true,
        isCompleted: returnData.hasOrderData,
      },
      {
        label: t('stepper.selectItems'),
        path: {
          route: ReturnSteps.SelectItems,
          fullPath: constructURL(ReturnSteps.SelectItems, inGeo),
        },
        isClickable: returnData.returnItems.length > 0,
        isCompleted: returnData.returnItems.length > 0,
      },
      {
        label: t('stepper.returnMethod'),
        path: {
          route: ReturnSteps.ReturnMethod,
          fullPath: constructURL(ReturnSteps.ReturnMethod, inGeo),
        },
        isClickable: returnData.returnMethod !== undefined,
        isCompleted: returnData.returnMethod !== undefined,
      },
      {
        label: t('stepper.payoutMethod'),
        path: {
          route: ReturnSteps.PayoutMethod,
          fullPath: constructURL(ReturnSteps.PayoutMethod, inGeo),
        },
        isClickable: returnData.payoutMethod !== undefined,
        isCompleted: returnData.payoutMethod !== undefined,
        // hide this step if the user has no items that can select a payout method
        isVisible: returnData.remainingItems.length > 0,
      },
      {
        label: t('stepper.review'),
        path: {
          route: ReturnSteps.Review,
          fullPath: constructURL(ReturnSteps.Review, inGeo),
        },
        isClickable:
          returnData.returnItems.length > 0 &&
          returnData.returnMethod !== undefined &&
          returnData.payoutMethod !== undefined,
      },
    ].filter((step) => step.isVisible === undefined || step.isVisible === true);
  }, [returnData, t, inGeo]);

  // `currentStep` and `currentStepName` are used for the mobile version
  const [currentStep, currentStepName] = useMemo(() => {
    const currentStepIndex = steps.findIndex((x) => x.path.route === subpath);

    return [currentStepIndex, steps[currentStepIndex]?.label];
  }, [steps, subpath]);

  const handleBackClick = () => {
    // go to order lookup and reset state
    if (currentStep == 1) {
      const nextRoute = constructURL(ReturnSteps.LocateOrder, inGeo);
      return reset(nextRoute);
    }
    const prevStep = steps[currentStep - 1];
    if (prevStep) {
      const nextRoute = constructURL(prevStep.path.route, inGeo);
      return goBack(nextRoute);
    }
  };

  return (
    <>
      <Modal
        title={t('common.adminSessionExpired')}
        isVisible={isAdmin && !cookieFromRetailerAdmin}
        customFooterClassName={styles.customFooterStyles}
        footer={
          <Button className={styles.ctaButton}>
            <a
              href={RETAILER_ADMIN_URL}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              {t('common.btnSignInToAdmin')}
            </a>
          </Button>
        }
        className={styles.container}
        modalClass={styles.modal}
      >
        <p>{t('common.adminSessionExpiredMsg', { storeName: themeConfig?.name })}</p>
      </Modal>
      <div className={styles.fullWidthStepper}>
        <div className={styles.flexboxStepperWrapper}>
          <div className={styles.fullWidthStepperWrapper}>
            <div className={styles.storeBranding}>
              {themeConfig?.logoImageUrl && (
                <NavLink to="/">
                  <img
                    src={themeConfig?.logoImageUrl}
                    alt={themeConfig.name}
                    className={styles.logo}
                  />
                </NavLink>
              )}
            </div>

            <div className={styles.steps}>
              {steps.map((step, index) => (
                <span key={step.label} className={styles.step}>
                  <StepperLink
                    subpath={step.path.fullPath}
                    isClickable={step.isClickable}
                    isCompleted={step.isCompleted}
                    isActive={step.path.route === subpath}
                    stepNumber={index + 1}
                  >
                    {step.label}
                  </StepperLink>
                </span>
              ))}
            </div>
          </div>
          {returnData.isAdmin && (
            <div className={styles.chipAdminStepperWrapper}>
              <Chip text={t('common.adminMode')} color={'info'} />
            </div>
          )}
        </div>
      </div>

      <div className={styles.mobileStepper}>
        <div className={styles.stepperLeftContent}>
          <div className={styles.backArrowContainer}>
            <button onClick={handleBackClick} className={styles.backArrowIcon}>
              <IconArrowLeft color="#303030" />
            </button>
          </div>
        </div>

        <div className={styles.stepDetails}>
          <div className={styles.progress}>
            {t('stepper.progress', { currentStep: currentStep + 1, totalSteps: steps.length })}
          </div>
          <div className={styles.currentStep}>{currentStepName}</div>
          {returnData.isAdmin && (
            <Chip text={t('common.adminModeShort')} color={'info'} size={'small'} />
          )}
        </div>
        {/* NOTE: At some point this may become an ellipsis icon button */}
        <div className={styles.stepperRightContent}>&nbsp;</div>
      </div>
    </>
  );
};
