import { ReturnMethods } from 'app/constants';
import {
  CreateReturnCaseWithOrderLookupParamsOpMutation,
  ReturnCaseItemInput,
  ReturnCaseItemReturnMethod,
} from 'generated/graphql';

import { PolicyRule, StoreOrderLineItem } from '.';

import { ExchangeFormRecord, ProductVariant } from './product';
import { OrderLookupParamInput, ShippingAddress } from './store';

export enum ExchangeOrReturn {
  Exchange = 'exchange',
  Return = 'return',
}

type CriteriaUID = string;

// the wip return case item
export interface ReturnItem {
  lineItem: StoreOrderLineItem;
  returnMethod: ExchangeOrReturn | null;
  additionalComments?: string;
  returnReasonText?: string;
  returnReasonUid?: string;
  exchangeOptions?: [];
  exchangeOption: ExchangeFormRecord;
  isSelected?: boolean;
  exchangeVariantId?: string;
  exchangeVariant?: ProductVariant;
  itemDeclaration?: Record<CriteriaUID, boolean>;
}

export interface DocumentDownloadType {
  url?: string | null;
  pdf?: string | null;
  png?: string | null;
  data?: string | null;
}

export interface ShippingDocumentsType {
  label?: DocumentDownloadType | null;
  customsInvoice?: DocumentDownloadType | null;
  packingSlip?: DocumentDownloadType | null;
}

export interface ReturnFlowData {
  // Control
  isAdmin: boolean;
  sessionId: string;

  // Step 0
  orderLookupInput?: OrderLookupParamInput[];

  // Step 1
  returnItems: ReturnItem[];
  reviewedPolicyRules: PolicyRule[];

  // Step 2
  returnMethod?: ReturnMethods;
  dropoffLocationId: string;
  shippingAddress: ShippingAddress;
  shippingEstimate: number;
  logisticsFee: number;
  isLocationsListVisible: boolean;

  // Step 3 (optional)
  payoutMethod?: ReturnCaseItemReturnMethod;

  // Data
  orderNumber: string;
  payoutSubTotal: number;
  hasPayoutItems: boolean;
  hasExchangeItems: boolean;
  storeCreditSubtotal: number;
  refundSubtotal: number;
  storeCreditOnlyItemsFee: number;
  refundOnlyItemsFee: number;
  exchangeOnlyItemsFee: number;
  eligibleStoreCreditAmountFee: number;
  hasStoreCreditOnlyItems: boolean;
  eligibleReturnItems: ReturnItem[];
  eligibleStoreCreditItems: ReturnItem[];
  refundOnlyItems: ReturnItem[];
  storeCreditOnlyItems: ReturnItem[];
  remainingItems: ReturnItem[];
  hasRefundOnlyItems: boolean;
  remainingItemFeeRefund: number;
  remainingItemFeeStoreCredit: number;
  totalStoreCreditFee: number;
  totalRefundFee: number;
  totalTaxes: number;
  createdReturnCase:
    | CreateReturnCaseWithOrderLookupParamsOpMutation['createReturnCaseWithOrderLookupParams'];
  returnCaseItemInput: Array<ReturnCaseItemInput>;
  isCanadianOrder: boolean;
  hasInboundShippingFee?: boolean;
  hasFlatRateFee?: boolean;
  cognitoTokenFromRetailerAdmin?: string;
}

// The id should be a unique id of index-variantid
// this handles for duplicate items
export interface ReturnDataState {
  [id: string]: ReturnItem;
}

export enum ReturnCaseStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  IN_TRANSIT_TO_HUB = 'IN_TRANSIT_TO_HUB',
  AWAITING_PROCESSING = 'AWAITING_PROCESSING',
  ASSESSED = 'ASSESSED',
  DROPPED_OFF = 'DROPPED_OFF',
  RECEIVED = 'RECEIVED',
  PARTIALLY_ASSESSED = 'PARTIALLY_ASSESSED',
  PARTIALLY_DROPPED_OFF = 'PARTIALLY_DROPPED_OFF',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  DONATED = 'DONATED',
  ADDED_TO_BATCH = 'ADDED_TO_BATCH',
  IN_TRANSIT_TO_MERCHANT = 'IN_TRANSIT_TO_MERCHANT',
  ARRIVED_AT_MERCHANT = 'ARRIVED_AT_MERCHANT',
  ACCEPTED_BY_MERCHANT = 'ACCEPTED_BY_MERCHANT',
  DENIED_BY_MERCHANT = 'DENIED_BY_MERCHANT',
  PARTIALLY_PROCESSED_INTO_INVENTORY = 'PARTIALLY_PROCESSED_INTO_INVENTORY',
  PROCESSED_INTO_INVENTORY = 'PROCESSED_INTO_INVENTORY',
  REFUNDED = 'REFUNDED',
}
export enum ReturnCaseItemStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  ASSESSED = 'ASSESSED',
  DROPPED_OFF = 'DROPPED_OFF',
  RECEIVED = 'RECEIVED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_TRANSIT_TO_HUB = 'IN_TRANSIT_TO_HUB',
  AWAITING_PROCESSING = 'AWAITING_PROCESSING',
  DONATED = 'DONATED',
  ADDED_TO_BATCH = 'ADDED_TO_BATCH',
  IN_TRANSIT_TO_MERCHANT = 'IN_TRANSIT_TO_MERCHANT',
  ARRIVED_AT_MERCHANT = 'ARRIVED_AT_MERCHANT',
  ACCEPTED_BY_MERCHANT = 'ACCEPTED_BY_MERCHANT',
  DENIED_BY_MERCHANT = 'DENIED_BY_MERCHANT',
  PARTIALLY_PROCESSED_INTO_INVENTORY = 'PARTIALLY_PROCESSED_INTO_INVENTORY',
  PROCESSED_INTO_INVENTORY = 'PROCESSED_INTO_INVENTORY',
  REFUNDED = 'REFUNDED',
}
