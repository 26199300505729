import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: Date;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: number;
  /**
   * Arbitrary JSON Properties for features.
   * https://github.com/graphql-python/graphene/issues/904
   */
  JSONScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: string;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: string;
  /**
   * Custom scalar for a condition value that expects a string, int,
   *  decimal or a list containing a string or decimal.
   */
  ValueScalar: any;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressResidentialIndicator?: Maybe<Scalars['String']>;
  cityLocality?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AdjustmentAppliedLevelEnum {
  ReturnCase = 'RETURN_CASE',
  ReturnCaseItem = 'RETURN_CASE_ITEM',
}

/** An enumeration. */
export enum AdjustmentCategoryEnum {
  Bonus = 'BONUS',
  FlatFee = 'FLAT_FEE',
  ManualMerchantAdjustment = 'MANUAL_MERCHANT_ADJUSTMENT',
  Other = 'OTHER',
  RemitExchangeFee = 'REMIT_EXCHANGE_FEE',
  RemitMethod = 'REMIT_METHOD',
  RemitRefundFee = 'REMIT_REFUND_FEE',
  RemitStoreCreditFee = 'REMIT_STORE_CREDIT_FEE',
  ShippingFee = 'SHIPPING_FEE',
  ShippingLogisticsFee = 'SHIPPING_LOGISTICS_FEE',
  WithheldTaxes = 'WITHHELD_TAXES',
}

export type AdjustmentSummaryType = {
  __typename?: 'AdjustmentSummaryType';
  itemAdjustmentSummary: ItemAdjustmentSummaryType;
  total: MoneyType;
  totalFees: MoneyType;
  totalIncentives: MoneyType;
  totalIsIncentive: Scalars['Boolean'];
};

export type AdjustmentType = {
  __typename?: 'AdjustmentType';
  appliedLevel: AdjustmentAppliedLevelEnum;
  category: AdjustmentCategoryEnum;
  id: Scalars['ID'];
  isApplied: Scalars['Boolean'];
  isIncentive: Scalars['Boolean'];
  name: Scalars['String'];
  nameFr: Scalars['String'];
  value: MoneyType;
};

export type Asset = {
  __typename?: 'Asset';
  pdf?: Maybe<Scalars['String']>;
  png?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ConfigShippingLabelDisplayTypeChoices {
  OrderNumber = 'ORDER_NUMBER',
  RmaNumber = 'RMA_NUMBER',
  ShortUid = 'SHORT_UID',
}

export type CoordinatesType = {
  __typename?: 'CoordinatesType';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CreateReturnCaseMutation = {
  __typename?: 'CreateReturnCaseMutation';
  exchangeItems?: Maybe<Array<ExchangeCaseItemType>>;
  returnCase?: Maybe<ReturnCaseType>;
  returnItems?: Maybe<Array<ReturnCaseItemType>>;
  shippingDocuments?: Maybe<ShippingDocumentsType>;
  shippingLabel?: Maybe<ShippingLabelObject>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateReturnCaseWithOrderLookupParamsMutation = {
  __typename?: 'CreateReturnCaseWithOrderLookupParamsMutation';
  exchangeItems?: Maybe<Array<ExchangeCaseItemType>>;
  returnCase?: Maybe<ReturnCaseType>;
  returnItems?: Maybe<Array<ReturnCaseItemType>>;
  shippingDocuments?: Maybe<ShippingDocumentsType>;
  shippingLabel?: Maybe<ShippingLabelObject>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CreditCardType {
  Amex = 'AMEX',
  Hbc = 'HBC',
  Hbcmast = 'HBCMAST',
  Mast = 'MAST',
  Paypal = 'PAYPAL',
  Unknown = 'UNKNOWN',
  Visa = 'VISA',
}

export type CriteriaInputType = {
  /** reference to merchant criteria UID */
  criteriaUid?: InputMaybe<Scalars['UUID']>;
  passed?: InputMaybe<Scalars['Boolean']>;
};

export type CriteriaType = {
  __typename?: 'CriteriaType';
  /** The reason type list that will cause this criteria to be false */
  autoFalseIfReasonIn: Array<ReturnReasonReasonType>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  descriptionFr: Scalars['String'];
  displayOnConsumerPortal: Scalars['Boolean'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  localisation: Localisation;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum CurrencyEnum {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD',
}

export type CustomShippingLabelConfig = {
  __typename?: 'CustomShippingLabelConfig';
  canCreateCustomShippingLabel?: Maybe<Scalars['Boolean']>;
  defaultCourier?: Maybe<Scalars['String']>;
};

export type CustomsType = {
  __typename?: 'CustomsType';
  countryCodeOfOrigin?: Maybe<Scalars['String']>;
  harmonizedSystemCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sku?: Maybe<Scalars['String']>;
};

export type DecimalPrice = {
  __typename?: 'DecimalPrice';
  amount?: Maybe<Scalars['Decimal']>;
  currency: CurrencyEnum;
};

export type DecimalPriceAndQuantity = {
  __typename?: 'DecimalPriceAndQuantity';
  amount?: Maybe<Scalars['Decimal']>;
  currency: CurrencyEnum;
  quantity?: Maybe<Scalars['Int']>;
};

export type DefaultReturnReasonType = {
  __typename?: 'DefaultReturnReasonType';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DocumentDownloadType = {
  __typename?: 'DocumentDownloadType';
  data?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  png?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DropOffLocation = Node & {
  __typename?: 'DropOffLocation';
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<LocationCoordinates>;
  distanceFromOriginInKilometres?: Maybe<Scalars['Float']>;
  hoursOfOperationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type DropOffLocationConnection = {
  __typename?: 'DropOffLocationConnection';
  count: Scalars['Int'];
  edges: Array<DropOffLocationEdge>;
  originCoordinates?: Maybe<LocationCoordinates>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

export type DropOffLocationEdge = {
  __typename?: 'DropOffLocationEdge';
  cursor: Scalars['String'];
  node: DropOffLocation;
};

/** An enumeration. */
export enum ExchangeCaseDraftOrderStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InventoryProblem = 'INVENTORY_PROBLEM',
  None = 'NONE',
  Open = 'OPEN',
}

export type ExchangeCaseItemOrderLineItemType = {
  __typename?: 'ExchangeCaseItemOrderLineItemType';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<OrderProductType>;
  quantity?: Maybe<Scalars['Int']>;
  returnCaseUid?: Maybe<Scalars['ID']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<OrderVariantType>;
};

export type ExchangeCaseItemType = {
  __typename?: 'ExchangeCaseItemType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  draftLineItemId: Scalars['String'];
  exchangeCase: ExchangeCaseType;
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<UserType>;
  lineItem?: Maybe<ExchangeCaseItemOrderLineItemType>;
  /** Store the order line item id once a draft order has been complete */
  orderLineItemId?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  returnCaseItem: ReturnCaseItemType;
  returnCaseUid?: Maybe<Scalars['ID']>;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  /** Variant id is used to identify the line item from between the draft order and final order */
  variantId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ExchangeCaseOrderStatus {
  Fulfilled = 'FULFILLED',
  None = 'NONE',
  Unfulfilled = 'UNFULFILLED',
}

export type ExchangeCaseType = {
  __typename?: 'ExchangeCaseType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  draftOrderId?: Maybe<Scalars['String']>;
  draftOrderNumber?: Maybe<Scalars['String']>;
  draftOrderStatus?: Maybe<ExchangeCaseDraftOrderStatus>;
  draftOrderUrl?: Maybe<Scalars['String']>;
  exchangeCaseItems: Array<ExchangeCaseItemType>;
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<UserType>;
  orderId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<ExchangeCaseOrderStatus>;
  orderUrl?: Maybe<Scalars['String']>;
  returnCase: ReturnCaseType;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum GiftCardProductCreationMethod {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export type GiftCardProductType = {
  __typename?: 'GiftCardProductType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  creationMethod?: Maybe<GiftCardProductCreationMethod>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<UserType>;
  productId?: Maybe<Scalars['String']>;
  store?: Maybe<StoreType>;
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum GiftCardProductVariantCurrency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD',
}

/** An enumeration. */
export enum GiftCardProductVariantStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type GiftCardProductVariantsType = {
  __typename?: 'GiftCardProductVariantsType';
  bonusAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  currency?: Maybe<GiftCardProductVariantCurrency>;
  giftCardProduct?: Maybe<GiftCardProductType>;
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<UserType>;
  returnCase?: Maybe<ReturnCaseType>;
  /** The list of return case ids attached with this returns, this is useful in the scenario of partially returned items */
  returnCaseItemIds?: Maybe<Array<Scalars['Int']>>;
  status: GiftCardProductVariantStatus;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
  variantId?: Maybe<Scalars['String']>;
  variantJson: Scalars['JSONString'];
};

export type InboundShippingFees = {
  __typename?: 'InboundShippingFees';
  CA?: Maybe<Scalars['Boolean']>;
  US?: Maybe<Scalars['Boolean']>;
};

export type ItemAdjustmentSummaryType = {
  __typename?: 'ItemAdjustmentSummaryType';
  total: MoneyType;
  totalExchangeFees: MoneyType;
  totalExchangeWithheldTaxes: MoneyType;
  totalIsIncentive: Scalars['Boolean'];
  totalRefundFees: MoneyType;
  totalRefundWithheldTaxes: MoneyType;
  totalStoreCreditFees: MoneyType;
  totalStoreCreditWithheldTaxes: MoneyType;
  totalWithheldTaxes: MoneyType;
};

export type Label = {
  __typename?: 'Label';
  carrierCode?: Maybe<Scalars['String']>;
  carrierId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  labelDownload?: Maybe<Asset>;
  labelId?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  shipDate?: Maybe<Scalars['String']>;
  shipmentCost?: Maybe<Price>;
  shipmentId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trackable?: Maybe<Scalars['Boolean']>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingStatus?: Maybe<Scalars['String']>;
};

export type Localisation = {
  __typename?: 'Localisation';
  en: LocalisedCopy;
  fr: LocalisedCopy;
};

export type LocalisedAllMetSelectUnmet = {
  __typename?: 'LocalisedAllMetSelectUnmet';
  allMet: LocalisedSingleMultiple;
  selectUnmet: LocalisedSingleMultiple;
};

export type LocalisedCopy = {
  __typename?: 'LocalisedCopy';
  body: LocalisedAllMetSelectUnmet;
  description: LocalisedAllMetSelectUnmet;
};

export type LocalisedSingleMultiple = {
  __typename?: 'LocalisedSingleMultiple';
  multiple: Scalars['String'];
  single: Scalars['String'];
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LocationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

export type LocationCoordinates = {
  __typename?: 'LocationCoordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** A Relay edge containing a `Location` and its cursor. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LocationType>;
};

export type LocationType = {
  __typename?: 'LocationType';
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesType>;
  externalGid: Scalars['String'];
  hoursOfOperationUrl?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  uid: Scalars['UUID'];
};

export type MoneyType = {
  __typename?: 'MoneyType';
  amount: Scalars['Decimal'];
  currencyCode: CurrencyEnum;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type OptionType = {
  __typename?: 'OptionType';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type OrderLineItemType = {
  __typename?: 'OrderLineItemType';
  discountedPrice?: Maybe<Scalars['Float']>;
  discountedTotalPrice?: Maybe<Scalars['Float']>;
  externalMerchantId?: Maybe<Scalars['String']>;
  fulfilledDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isRemitted?: Maybe<Scalars['Boolean']>;
  lineType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalUnitPriceSet?: Maybe<TotalPriceType>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<OrderProductType>;
  quantity?: Maybe<Scalars['Int']>;
  refundableQuantity?: Maybe<Scalars['Int']>;
  requiresShipping?: Maybe<Scalars['Boolean']>;
  returnCaseExists?: Maybe<Scalars['Boolean']>;
  returnCaseItemsQuantity?: Maybe<Scalars['Int']>;
  returnCaseStatus?: Maybe<Scalars['String']>;
  returnCasesInfo: Array<OrderLineReturnCaseInfoType>;
  returnConstraint?: Maybe<ReturnConstraintType>;
  ruleOutcome?: Maybe<Array<Outcome>>;
  sku?: Maybe<Scalars['String']>;
  store?: Maybe<StoreType>;
  subtotal?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
  variant?: Maybe<OrderVariantType>;
};

export type OrderLineReturnCaseInfoType = {
  __typename?: 'OrderLineReturnCaseInfoType';
  created: Scalars['String'];
  qty: Scalars['Int'];
  rmaNumber: Scalars['String'];
  status: Scalars['String'];
};

export type OrderLookupParamInput = {
  field?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OrderProductType = {
  __typename?: 'OrderProductType';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type OrderShippingAddressType = {
  __typename?: 'OrderShippingAddressType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderType = {
  __typename?: 'OrderType';
  closed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fulfillmentStatus?: Maybe<Scalars['String']>;
  lineItems?: Maybe<Array<OrderLineItemType>>;
  /** The order queried */
  orderId: Scalars['String'];
  orderNumber?: Maybe<Scalars['String']>;
  originalTracking?: Maybe<Array<TrackingType>>;
  ruleOutcome?: Maybe<Array<Outcome>>;
  shippingAddress?: Maybe<OrderShippingAddressType>;
  /** The store id queried */
  storeId: Scalars['String'];
  storeUid?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Decimal']>;
};

export type OrderVariantType = {
  __typename?: 'OrderVariantType';
  barcode?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  customs?: Maybe<CustomsType>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Outcome = {
  __typename?: 'Outcome';
  isVisible?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<OutcomeMessage>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Array<OutcomeTarget>>;
  /** Types: String, Decimal, [String || Decimal] */
  value?: Maybe<Scalars['ValueScalar']>;
  valueType?: Maybe<Scalars['String']>;
};

export type OutcomeMessage = {
  __typename?: 'OutcomeMessage';
  english?: Maybe<Scalars['String']>;
  french?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum OutcomeTarget {
  Exchange = 'EXCHANGE',
  Refund = 'REFUND',
  StoreCredit = 'STORE_CREDIT',
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentType {
  ApplePay = 'APPLE_PAY',
  CreditCard = 'CREDIT_CARD',
  CreditCardHbc = 'CREDIT_CARD_HBC',
  CreditCardPvtHbc = 'CREDIT_CARD_PVT_HBC',
  Hbgc = 'HBGC',
  Paybright = 'PAYBRIGHT',
  PaypalHbc = 'PAYPAL_HBC',
  RefundToOther = 'REFUND_TO_OTHER',
  Rewards = 'REWARDS',
  Unknown = 'UNKNOWN',
}

export type PolicyRuleType = {
  __typename?: 'PolicyRuleType';
  rule: Scalars['String'];
  store: StoreType;
  uid: Scalars['UUID'];
};

export type PortalMutations = {
  __typename?: 'PortalMutations';
  /**
   * Creates a return case
   * @deprecated This mutation is replaced with createReturnCaseWithOrderLookupParams.
   */
  createReturnCase?: Maybe<CreateReturnCaseMutation>;
  /** Creates a return case using dynamic order lookup parameters */
  createReturnCaseWithOrderLookupParams?: Maybe<CreateReturnCaseWithOrderLookupParamsMutation>;
  /** Returns shipping cost estimation based on postal code */
  shippingEstimate?: Maybe<ShippingEstimateMutation>;
  /** Validates the provided shipping address using Shipengine's api */
  validateAddress?: Maybe<ValidateAddressMutation>;
};

export type PortalMutationsCreateReturnCaseArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressResidentialIndicator: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  method?: InputMaybe<ReturnCaseMethods>;
  orderId: Scalars['ID'];
  orderJson?: InputMaybe<Scalars['JSONScalar']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  province: Scalars['String'];
  returnItems: Array<ReturnCaseItemInput>;
  reviewedPolicyRules: Array<InputMaybe<ReviewedPolicyRuleInput>>;
  storeId: Scalars['ID'];
  zipCode: Scalars['String'];
};

export type PortalMutationsCreateReturnCaseWithOrderLookupParamsArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressResidentialIndicator: Scalars['String'];
  city: Scalars['String'];
  consumerSessionId?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  lookupParams: Array<OrderLookupParamInput>;
  method?: InputMaybe<ReturnCaseMethods>;
  orderJson?: InputMaybe<Scalars['JSONScalar']>;
  phone: Scalars['String'];
  province: Scalars['String'];
  returnItems: Array<ReturnCaseItemInput>;
  reviewedPolicyRules: Array<InputMaybe<ReviewedPolicyRuleInput>>;
  storeId: Scalars['ID'];
  zipCode: Scalars['String'];
};

export type PortalMutationsShippingEstimateArgs = {
  fromAddress1: Scalars['String'];
  fromAddress2: Scalars['String'];
  fromCity: Scalars['String'];
  fromCompany: Scalars['String'];
  fromCountryCode: Scalars['String'];
  fromFirstName: Scalars['String'];
  fromLastName: Scalars['String'];
  fromName: Scalars['String'];
  fromPhone: Scalars['String'];
  fromPostalCode: Scalars['String'];
  fromProvince: Scalars['String'];
  lookupParams?: InputMaybe<Array<OrderLookupParamInput>>;
  returnItems?: InputMaybe<Array<InputMaybe<Scalars['JSONScalar']>>>;
  storeUid: Scalars['UUID'];
};

export type PortalMutationsValidateAddressArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressResidentialIndicator: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  province: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PortalQueries = {
  __typename?: 'PortalQueries';
  getLocations?: Maybe<Array<LocationType>>;
  getLocationsV2?: Maybe<LocationConnection>;
  getLocationsV3?: Maybe<DropOffLocationConnection>;
  getProductVariants?: Maybe<ProductType>;
  /** get return case using dynamic order lookup parameters and id for return status */
  getReturnCaseWithOrderLookupParams?: Maybe<ReturnCaseStatusType>;
  getStoreConfig?: Maybe<StoreConfig>;
  /** @deprecated This query is replaced with getStoreOrderWithLookupParams */
  getStoreOrder?: Maybe<OrderType>;
  /** Uses dynamic parameters defined for each ecommerce service in order to query order */
  getStoreOrderWithLookupParams?: Maybe<OrderType>;
  getStorePolicyRules?: Maybe<Array<PolicyRuleType>>;
  getStoreReturnReasons?: Maybe<StoreReturnReasonType>;
  getThemeConfig?: Maybe<ThemeConfig>;
  listAcceptanceCriteria?: Maybe<Array<Maybe<CriteriaType>>>;
  returnMethodsUserInterfaceSettings: ReturnMethodsUserInterfaceSettings;
};

export type PortalQueriesGetLocationsArgs = {
  storeUid?: InputMaybe<Scalars['UUID']>;
};

export type PortalQueriesGetLocationsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  storeUid?: InputMaybe<Scalars['UUID']>;
};

export type PortalQueriesGetLocationsV3Args = {
  countryCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  postalCode?: InputMaybe<Scalars['String']>;
  storeUid?: InputMaybe<Scalars['UUID']>;
};

export type PortalQueriesGetProductVariantsArgs = {
  productId: Scalars['String'];
};

export type PortalQueriesGetReturnCaseWithOrderLookupParamsArgs = {
  lookupParams: Array<OrderLookupParamInput>;
  rmaNumber?: InputMaybe<Scalars['String']>;
};

export type PortalQueriesGetStoreConfigArgs = {
  subdomain: Scalars['String'];
};

export type PortalQueriesGetStoreOrderArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  orderId: Scalars['String'];
};

export type PortalQueriesGetStoreOrderWithLookupParamsArgs = {
  lookupParams: Array<OrderLookupParamInput>;
};

export type PortalQueriesGetThemeConfigArgs = {
  subdomain: Scalars['String'];
};

export type PortalQueriesReturnMethodsUserInterfaceSettingsArgs = {
  consumerLatitude: Scalars['Float'];
  consumerLongitude: Scalars['Float'];
  storeId: Scalars['Int'];
};

export type Price = {
  __typename?: 'Price';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type PriceType = {
  __typename?: 'PriceType';
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type PrimaryPaymentMethodDetailsType = {
  __typename?: 'PrimaryPaymentMethodDetailsType';
  creditCardType?: Maybe<CreditCardType>;
  /** Masked credit card information. Usually last 4 digits will be available.Default value is: **** **** **** **** */
  displayableCreditCardNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
};

export type ProductType = {
  __typename?: 'ProductType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<Maybe<ProductVariantType>>>;
};

export type ProductVariantType = {
  __typename?: 'ProductVariantType';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  inventoryQuantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  selectedOptions?: Maybe<Array<OptionType>>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type RetailerType = {
  __typename?: 'RetailerType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationType>;
  uid: Scalars['UUID'];
};

export type ReturnCaseAdjustmentType = {
  __typename?: 'ReturnCaseAdjustmentType';
  adjustments: Array<AdjustmentType>;
  summary: AdjustmentSummaryType;
};

export type ReturnCaseItemAdjustmentType = {
  __typename?: 'ReturnCaseItemAdjustmentType';
  adjustments: Array<AdjustmentType>;
};

export type ReturnCaseItemEvaluationsType = {
  __typename?: 'ReturnCaseItemEvaluationsType';
  failedDropOffCriteriaText: Array<Scalars['String']>;
  failedDropOffCriteriaTextFr: Array<Maybe<Scalars['String']>>;
  failedHubVerificationNotes: Scalars['String'];
  itemFailedDropOffCriteria: Scalars['Boolean'];
  itemFailedHubVerification: Scalars['Boolean'];
  itemMissingAtDropOff: Scalars['Boolean'];
  itemMissingAtHub: Scalars['Boolean'];
};

/** An enumeration. */
export enum ReturnCaseItemFeeCurrency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD',
}

export type ReturnCaseItemInput = {
  comment?: InputMaybe<Scalars['String']>;
  /** The variant id of the item to be exchanged if an exchange type */
  exchangeVariantId?: InputMaybe<Scalars['String']>;
  fulfilledDate?: InputMaybe<Scalars['String']>;
  itemsEvaluations?: InputMaybe<Array<CriteriaInputType>>;
  /** The json field for this line item */
  lineItemJson: Scalars['JSONScalar'];
  orderLineItemId: Scalars['String'];
  quantity: Scalars['Int'];
  returnItemsEvaluations?: InputMaybe<Array<CriteriaInputType>>;
  returnMethod: Scalars['String'];
  returnReason?: InputMaybe<Scalars['String']>;
  returnReasonUid: Scalars['UUID'];
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type ReturnCaseItemOrderLineItemType = {
  __typename?: 'ReturnCaseItemOrderLineItemType';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ReturnCaseItemOrderProductType>;
  quantity?: Maybe<Scalars['Int']>;
  refundableQuantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<ReturnCaseItemOrderVariant>;
};

export type ReturnCaseItemOrderProductType = {
  __typename?: 'ReturnCaseItemOrderProductType';
  color?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type ReturnCaseItemOrderVariant = {
  __typename?: 'ReturnCaseItemOrderVariant';
  barcode?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  pretaxPrice: Price;
  price?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ReturnCaseItemReturnMethod {
  Exchange = 'EXCHANGE',
  Refund = 'REFUND',
  StoreCredit = 'STORE_CREDIT',
}

/** An enumeration. */
export enum ReturnCaseItemStatus {
  AcceptedByMerchant = 'ACCEPTED_BY_MERCHANT',
  AddedToBatch = 'ADDED_TO_BATCH',
  Approved = 'APPROVED',
  ArrivedAtMerchant = 'ARRIVED_AT_MERCHANT',
  Assessed = 'ASSESSED',
  AwaitingProcessing = 'AWAITING_PROCESSING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  DeniedByMerchant = 'DENIED_BY_MERCHANT',
  Donated = 'DONATED',
  DroppedOff = 'DROPPED_OFF',
  InTransitToHub = 'IN_TRANSIT_TO_HUB',
  InTransitToMerchant = 'IN_TRANSIT_TO_MERCHANT',
  New = 'NEW',
  ProcessedIntoInventory = 'PROCESSED_INTO_INVENTORY',
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  ScannedAtHub = 'SCANNED_AT_HUB',
}

export type ReturnCaseItemType = {
  __typename?: 'ReturnCaseItemType';
  acceptedByMerchantAt?: Maybe<Scalars['DateTime']>;
  addedToBatchAt?: Maybe<Scalars['DateTime']>;
  adjustmentDetails: ReturnCaseItemAdjustmentType;
  /** Fee applied to return case item */
  appliedFee?: Maybe<Scalars['Decimal']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  arrivedAtMerchantAt?: Maybe<Scalars['DateTime']>;
  assessedAt?: Maybe<Scalars['DateTime']>;
  awaitingProcessingAt?: Maybe<Scalars['DateTime']>;
  batchShipmentUid?: Maybe<Scalars['UUID']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  deniedAt?: Maybe<Scalars['DateTime']>;
  deniedByMerchantAt?: Maybe<Scalars['DateTime']>;
  donatedAt?: Maybe<Scalars['DateTime']>;
  donationOrganization?: Maybe<Scalars['String']>;
  donationReason?: Maybe<Scalars['String']>;
  droppedOffAt?: Maybe<Scalars['DateTime']>;
  evaluations?: Maybe<ReturnCaseItemEvaluationsType>;
  feeCurrency?: Maybe<ReturnCaseItemFeeCurrency>;
  id: Scalars['ID'];
  inTransitToHubAt?: Maybe<Scalars['DateTime']>;
  inTransitToMerchantAt?: Maybe<Scalars['DateTime']>;
  isDefective?: Maybe<Scalars['Boolean']>;
  isRemitted: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<UserType>;
  lineItem?: Maybe<ReturnCaseItemOrderLineItemType>;
  orderLineItemId?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  quantity?: Maybe<Scalars['Int']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  remittedAt?: Maybe<Scalars['DateTime']>;
  returnCase?: Maybe<ReturnCaseType>;
  returnMethod?: Maybe<ReturnCaseItemReturnMethod>;
  returnReason?: Maybe<Scalars['String']>;
  status: ReturnCaseItemStatus;
  store: StoreType;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum ReturnCaseMethod {
  DropOff = 'DROP_OFF',
  MailIn = 'MAIL_IN',
}

/** An enumeration. */
export enum ReturnCaseMethods {
  DropOff = 'DROP_OFF',
  MailIn = 'MAIL_IN',
}

export type ReturnCaseRefundBreakDownType = {
  __typename?: 'ReturnCaseRefundBreakDownType';
  exchangeAmount?: Maybe<DecimalPriceAndQuantity>;
  /** Total values of refund and store credit items minus the total fees  */
  netRefundAmount?: Maybe<DecimalPrice>;
  /** Total values of store credit items minus the total fees  */
  netStoreCreditAmount?: Maybe<DecimalPrice>;
  refundAmount?: Maybe<DecimalPrice>;
  storeCreditAmount?: Maybe<DecimalPrice>;
  /** Total values of refund and store credit items. Exchanges are excluded. */
  totalAmount?: Maybe<DecimalPrice>;
  /** Total applied fees on return items. All refund, store credit and exchange fees are included. */
  totalFee?: Maybe<DecimalPrice>;
};

/** An enumeration. */
export enum ReturnCaseStatus {
  AcceptedByMerchant = 'ACCEPTED_BY_MERCHANT',
  AddedToBatch = 'ADDED_TO_BATCH',
  Approved = 'APPROVED',
  ArrivedAtMerchant = 'ARRIVED_AT_MERCHANT',
  Assessed = 'ASSESSED',
  AwaitingProcessing = 'AWAITING_PROCESSING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  DeniedByMerchant = 'DENIED_BY_MERCHANT',
  Donated = 'DONATED',
  DroppedOff = 'DROPPED_OFF',
  InTransitToHub = 'IN_TRANSIT_TO_HUB',
  InTransitToMerchant = 'IN_TRANSIT_TO_MERCHANT',
  New = 'NEW',
  PartiallyAssessed = 'PARTIALLY_ASSESSED',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  PartiallyDroppedOff = 'PARTIALLY_DROPPED_OFF',
  PartiallyProcessedIntoInventory = 'PARTIALLY_PROCESSED_INTO_INVENTORY',
  PartiallyReceived = 'PARTIALLY_RECEIVED',
  ProcessedIntoInventory = 'PROCESSED_INTO_INVENTORY',
  Received = 'RECEIVED',
  Refunded = 'REFUNDED',
  ScannedAtHub = 'SCANNED_AT_HUB',
}

export type ReturnCaseStatusEventType = {
  __typename?: 'ReturnCaseStatusEventType';
  eventTime: Scalars['DateTime'];
  status: ReturnCaseStatus;
};

export type ReturnCaseStatusType = {
  __typename?: 'ReturnCaseStatusType';
  returnCase: ReturnCaseType;
  timeline?: Maybe<Array<ReturnCaseStatusEventType>>;
};

/** An enumeration. */
export enum ReturnCaseStepState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  Future = 'FUTURE',
  PendingUserAction = 'PENDING_USER_ACTION',
}

export type ReturnCaseTimelineType = {
  __typename?: 'ReturnCaseTimelineType';
  state: ReturnCaseStepState;
  status: ReturnCaseStatus;
  step?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type ReturnCaseType = {
  __typename?: 'ReturnCaseType';
  acceptedByMerchantAt?: Maybe<Scalars['DateTime']>;
  addedToBatchAt?: Maybe<Scalars['DateTime']>;
  adjustmentDetails: ReturnCaseAdjustmentType;
  /** This value is set if the fee is enabled on the store when creating a return case */
  appliedCrossBorderLogisticsFee?: Maybe<Scalars['Decimal']>;
  /** Original shipping cost applied on this return case. If set to None, then no shipping cost needs to be paid. */
  appliedShippingCost?: Maybe<Scalars['Decimal']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  areAppliedAdjustmentsEditable?: Maybe<Scalars['Boolean']>;
  arrivedAtMerchantAt?: Maybe<Scalars['DateTime']>;
  assessedAt?: Maybe<Scalars['DateTime']>;
  awaitingProcessingAt?: Maybe<Scalars['DateTime']>;
  bags?: Maybe<Array<Scalars['String']>>;
  canApproveByCurrentStatus?: Maybe<Scalars['Boolean']>;
  canCancelByCurrentStatus?: Maybe<Scalars['Boolean']>;
  canDenyByCurrentStatus?: Maybe<Scalars['Boolean']>;
  canReturnByCurrentStatus?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  currency?: Maybe<Scalars['String']>;
  deliveryReferenceId?: Maybe<Scalars['String']>;
  denialReason?: Maybe<Scalars['String']>;
  deniedAt?: Maybe<Scalars['DateTime']>;
  deniedByMerchantAt?: Maybe<Scalars['DateTime']>;
  donatedAt?: Maybe<Scalars['DateTime']>;
  droppedOffAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  exchangeCase?: Maybe<ExchangeCaseType>;
  exchangeQuantity: Scalars['Int'];
  externalGid: Scalars['String'];
  /** Flat rate used for all returns that require shipping */
  flatRateFee?: Maybe<Scalars['Decimal']>;
  giftCardProductVariants: Array<GiftCardProductVariantsType>;
  hasDefectiveItems?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inTransitToHubAt?: Maybe<Scalars['DateTime']>;
  inTransitToMerchantAt?: Maybe<Scalars['DateTime']>;
  isRemitted: Scalars['Boolean'];
  isTest: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<UserType>;
  method: ReturnCaseMethod;
  notes?: Maybe<Scalars['String']>;
  orderAdminUrl?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderShippingAddress?: Maybe<Address>;
  policyRulesJson?: Maybe<Array<ReviewedPolicyRuleType>>;
  primaryPaymentMethodDetails?: Maybe<PrimaryPaymentMethodDetailsType>;
  qrCode: Scalars['String'];
  /** Return case has shipping label, which implies that the items have been dropped off and processed. */
  readyForPickup?: Maybe<Scalars['Boolean']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  refundBreakdown?: Maybe<ReturnCaseRefundBreakDownType>;
  remittedAt?: Maybe<Scalars['DateTime']>;
  requestedDropOffLocation?: Maybe<LocationType>;
  returnCaseId?: Maybe<Scalars['Int']>;
  returnCaseIsWithinReturnWindow: Scalars['Boolean'];
  returnCaseItems: Array<ReturnCaseItemType>;
  returnQuantity: Scalars['Int'];
  rmaNumber?: Maybe<Scalars['String']>;
  shippingLabel?: Maybe<ShippingLabelObject>;
  shippingLabelEstimate?: Maybe<ShippingLabelEstimate>;
  shippingLabels: Array<ShippingLabelType>;
  /** @deprecated Will be deprecated. Please use deliveryReferenceId as pen writable id. */
  shortRmaNumber?: Maybe<Scalars['String']>;
  /** A User friendly uid to be sent to the user in order to find their return case in the backend */
  shortUid?: Maybe<Scalars['String']>;
  shouldDisplayMerchantRemitRiskConfirmationModal?: Maybe<Scalars['Boolean']>;
  status: ReturnCaseStatus;
  storeExternalGid: Scalars['String'];
  storeId?: Maybe<Scalars['Int']>;
  timeline?: Maybe<Array<Maybe<ReturnCaseTimelineType>>>;
  totalPrice: Scalars['Float'];
  totalPriceApplied: Scalars['Float'];
  totalQuantity: Scalars['Int'];
  totalTax: Scalars['Float'];
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type ReturnConstraintType = {
  __typename?: 'ReturnConstraintType';
  payoutMethods: Array<Maybe<ReturnMethodChoices>>;
  returnWindow?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ReturnMethodChoices {
  Exchange = 'EXCHANGE',
  Refund = 'REFUND',
  StoreCredit = 'STORE_CREDIT',
}

export type ReturnMethodsUserInterfaceSettings = {
  __typename?: 'ReturnMethodsUserInterfaceSettings';
  dropOffIsPreferred: Scalars['Boolean'];
};

/** An enumeration. */
export enum ReturnReasonReasonType {
  ChangedMind = 'CHANGED_MIND',
  Defective = 'DEFECTIVE',
  IncorrectFit = 'INCORRECT_FIT',
  OrderIssue = 'ORDER_ISSUE',
  Other = 'OTHER',
  UnmetExpectations = 'UNMET_EXPECTATIONS',
}

export type ReturnReasonType = {
  __typename?: 'ReturnReasonType';
  isDeleted: Scalars['Boolean'];
  reasonType: ReturnReasonReasonType;
  sortOrder?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  uid: Scalars['UUID'];
};

export type ReviewedPolicyRuleInput = {
  /** Whether the policy rule is accepted or not */
  accepted: Scalars['Boolean'];
  /** The UID of the policy rule being reviewed */
  uid: Scalars['UUID'];
};

export type ReviewedPolicyRuleType = {
  __typename?: 'ReviewedPolicyRuleType';
  accepted?: Maybe<Scalars['Boolean']>;
  rule?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['UUID']>;
};

export type ShippingDocumentsType = {
  __typename?: 'ShippingDocumentsType';
  customsInvoice?: Maybe<DocumentDownloadType>;
  label?: Maybe<DocumentDownloadType>;
  packingSlip?: Maybe<DocumentDownloadType>;
};

export type ShippingEstimateMutation = {
  __typename?: 'ShippingEstimateMutation';
  estimateAmount?: Maybe<Scalars['Decimal']>;
  estimateCurrency?: Maybe<Scalars['String']>;
  logisticsFee?: Maybe<Scalars['Decimal']>;
};

export type ShippingLabelEstimate = {
  __typename?: 'ShippingLabelEstimate';
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isBatch?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Label>;
  labelType?: Maybe<Scalars['String']>;
  matchedAddress?: Maybe<Address>;
  shipFrom?: Maybe<Address>;
  shipTo?: Maybe<Address>;
  shipmentCost?: Maybe<Scalars['Decimal']>;
  shipmentCurrency?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ShippingLabelLabelType {
  Estimate = 'ESTIMATE',
  Label = 'LABEL',
}

/**
 * reason for the weird class name:
 * https://github.com/graphql-python/graphene-django/issues/185#issuecomment-314130927
 *
 * TODO: We should keep a single graphene object type for a ShippingLabel.
 * Ideally we remove/transition from this "ShippingLabelObject" to the
 * "ShippingLabelType" above.
 */
export type ShippingLabelObject = {
  __typename?: 'ShippingLabelObject';
  courier?: Maybe<Scalars['String']>;
  documents?: Maybe<ShippingDocumentsType>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isBatch?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Label>;
  labelDownloadUrl?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['String']>;
  labelType?: Maybe<Scalars['String']>;
  matchedAddress?: Maybe<Address>;
  shipFrom?: Maybe<Address>;
  shipTo?: Maybe<Address>;
  shipmentCost?: Maybe<Scalars['Decimal']>;
  shipmentCurrency?: Maybe<Scalars['String']>;
};

export type ShippingLabelType = {
  __typename?: 'ShippingLabelType';
  courier?: Maybe<Scalars['String']>;
  documents?: Maybe<ShippingDocumentsType>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBatch?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Label>;
  labelDownloadUrl?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['String']>;
  labelType: ShippingLabelLabelType;
  matchedAddress?: Maybe<Address>;
  returnCase?: Maybe<ReturnCaseType>;
  serviceCode?: Maybe<Scalars['String']>;
  shipFrom?: Maybe<Address>;
  shipTo?: Maybe<Address>;
  shipmentCost?: Maybe<Scalars['Decimal']>;
  shipmentCurrency?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type StoreConfig = {
  __typename?: 'StoreConfig';
  approvalDays?: Maybe<Scalars['String']>;
  bonusAmount?: Maybe<Scalars['String']>;
  canEditReturnPolicy?: Maybe<Scalars['Boolean']>;
  canEditReturnReason?: Maybe<Scalars['Boolean']>;
  canEditRuleAutomation?: Maybe<Scalars['Boolean']>;
  canEditShippingCarrier?: Maybe<Scalars['Boolean']>;
  canEditStoreConfigurations?: Maybe<Scalars['Boolean']>;
  canImportOrdersCsv?: Maybe<Scalars['Boolean']>;
  canImportReturnsCsv?: Maybe<Scalars['Boolean']>;
  canUploadProductData?: Maybe<Scalars['Boolean']>;
  customShippingLabel?: Maybe<CustomShippingLabelConfig>;
  customerServiceEmail?: Maybe<Scalars['String']>;
  dropOffSurveyLink?: Maybe<Scalars['String']>;
  enableManualRefunds?: Maybe<Scalars['Boolean']>;
  enableMerchantVerification?: Maybe<Scalars['Boolean']>;
  enableOrdersView?: Maybe<Scalars['Boolean']>;
  enableReturnCaseRefunds?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<StoreConfigFees>;
  giftCardProductId?: Maybe<Scalars['String']>;
  hasInboundShippingFee?: Maybe<InboundShippingFees>;
  hasShippingLogisticFee?: Maybe<Scalars['Boolean']>;
  hasTaxesWithheld?: Maybe<Scalars['Boolean']>;
  hubId: Scalars['Int'];
  isCrossBorderReturnEnabled?: Maybe<Scalars['Boolean']>;
  mailInSurveyLink?: Maybe<Scalars['String']>;
  merchantReturnMethodPageBanner?: Maybe<StoreConfigMerchantReturnMethodPageBanner>;
  /** field list in order to use getStoreOrderWithLookupParams query */
  orderLookupParams?: Maybe<Array<Scalars['String']>>;
  packingSlip?: Maybe<Scalars['String']>;
  retailerPolicyUrl?: Maybe<Scalars['String']>;
  returnChannel?: Maybe<Array<Scalars['String']>>;
  returnMethod?: Maybe<Array<Scalars['String']>>;
  /** Dynamic field based on the language passed in to the request headers. Options returnPolicyUrl_en or returnPolicyUrl_fr */
  returnPolicyUrl?: Maybe<Scalars['String']>;
  returnPolicyUrl_en?: Maybe<Scalars['String']>;
  returnPolicyUrl_fr?: Maybe<Scalars['String']>;
  returnWindow?: Maybe<Scalars['String']>;
  returnsPortalUrl?: Maybe<Scalars['String']>;
  shippingCourier?: Maybe<Scalars['String']>;
  shippingLabelDisplayType?: Maybe<ConfigShippingLabelDisplayTypeChoices>;
  /** Dynamic field based on the language passed in to the request headers. Options shoppingUrl_en or shoppingUrl_fr */
  shoppingUrl?: Maybe<Scalars['String']>;
  shoppingUrl_en?: Maybe<Scalars['String']>;
  shoppingUrl_fr?: Maybe<Scalars['String']>;
  useReturnAcceptance?: Maybe<Scalars['Boolean']>;
  useTosAgreement?: Maybe<Scalars['Boolean']>;
  visibleEditReturnMethods?: Maybe<Array<Scalars['String']>>;
};

/** An enumeration. */
export enum StoreConfigFeeTypeChoices {
  Number = 'NUMBER',
  Percent = 'PERCENT',
}

export type StoreConfigFees = {
  __typename?: 'StoreConfigFees';
  exchange?: Maybe<StoreConfigFeesExchange>;
  flatRate?: Maybe<StoreConfigFeesFlatRate>;
  refund?: Maybe<StoreConfigFeesRefund>;
  storeCredit?: Maybe<StoreConfigFeesStoreCredit>;
};

export type StoreConfigFeesExchange = {
  __typename?: 'StoreConfigFeesExchange';
  canEditExchangeFees?: Maybe<Scalars['Boolean']>;
  canEditExchangeShipping?: Maybe<Scalars['Boolean']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAmountType?: Maybe<StoreConfigFeeTypeChoices>;
  feeName?: Maybe<Scalars['String']>;
  feeNameEn?: Maybe<Scalars['String']>;
  feeNameFr?: Maybe<Scalars['String']>;
  feeReason?: Maybe<Scalars['String']>;
  feeReasonEn?: Maybe<Scalars['String']>;
  feeReasonFr?: Maybe<Scalars['String']>;
  hasShippingFee?: Maybe<Scalars['Boolean']>;
};

export type StoreConfigFeesFlatRate = {
  __typename?: 'StoreConfigFeesFlatRate';
  canEditFlatRateFees?: Maybe<Scalars['Boolean']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAmountType?: Maybe<StoreConfigFeeTypeChoices>;
  feeName?: Maybe<Scalars['String']>;
  feeNameEn?: Maybe<Scalars['String']>;
  feeNameFr?: Maybe<Scalars['String']>;
  feeReason?: Maybe<Scalars['String']>;
  feeReasonEn?: Maybe<Scalars['String']>;
  feeReasonFr?: Maybe<Scalars['String']>;
  returnMethods: Array<ReturnCaseMethods>;
  returnRemitMethods: Array<ReturnMethodChoices>;
};

export type StoreConfigFeesRefund = {
  __typename?: 'StoreConfigFeesRefund';
  canEditRefundFees?: Maybe<Scalars['Boolean']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAmountType?: Maybe<StoreConfigFeeTypeChoices>;
  feeName?: Maybe<Scalars['String']>;
  feeNameEn?: Maybe<Scalars['String']>;
  feeNameFr?: Maybe<Scalars['String']>;
  feeReason?: Maybe<Scalars['String']>;
  feeReasonEn?: Maybe<Scalars['String']>;
  feeReasonFr?: Maybe<Scalars['String']>;
};

export type StoreConfigFeesStoreCredit = {
  __typename?: 'StoreConfigFeesStoreCredit';
  canEditStoreCreditFees?: Maybe<Scalars['Boolean']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAmountType?: Maybe<StoreConfigFeeTypeChoices>;
  feeName?: Maybe<Scalars['String']>;
  feeNameEn?: Maybe<Scalars['String']>;
  feeNameFr?: Maybe<Scalars['String']>;
  feeReason?: Maybe<Scalars['String']>;
  feeReasonEn?: Maybe<Scalars['String']>;
  feeReasonFr?: Maybe<Scalars['String']>;
};

export type StoreConfigMerchantReturnMethodPageBanner = {
  __typename?: 'StoreConfigMerchantReturnMethodPageBanner';
  ctaLinkEn?: Maybe<Scalars['String']>;
  ctaLinkFr?: Maybe<Scalars['String']>;
  ctaMessageEn?: Maybe<Scalars['String']>;
  ctaMessageFr?: Maybe<Scalars['String']>;
  messageEn?: Maybe<Scalars['String']>;
  messageFr?: Maybe<Scalars['String']>;
};

export type StoreReturnReasonType = {
  __typename?: 'StoreReturnReasonType';
  defaultReturnReasons?: Maybe<Array<DefaultReturnReasonType>>;
  returnReasons?: Maybe<Array<ReturnReasonType>>;
};

/** An enumeration. */
export enum StoreSellerTypeChoice {
  D2C = 'D2C',
  MarketplaceOwned = 'MARKETPLACE_OWNED',
  MarketplaceThirdParty = 'MARKETPLACE_THIRD_PARTY',
}

/** An enumeration. */
export enum StoreShippingCourier {
  CanadaPostRegular = 'CANADA_POST_REGULAR',
  None = 'NONE',
  PurolatorExpress = 'PUROLATOR_EXPRESS',
  UpsStandard = 'UPS_STANDARD',
}

export type StoreType = {
  __typename?: 'StoreType';
  displayTosAgreement?: Maybe<Scalars['Boolean']>;
  ecommercePlatform?: Maybe<StoresStoreEcommercePlatformChoices>;
  externalGid: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  needsTosSigning?: Maybe<Scalars['Boolean']>;
  retailer?: Maybe<RetailerType>;
  sellerType?: Maybe<StoreSellerTypeChoice>;
  shippingCourier: StoreShippingCourier;
  storeConfig?: Maybe<StoreConfig>;
  subdomain: Scalars['String'];
  uid: Scalars['UUID'];
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum StoresStoreEcommercePlatformChoices {
  /** HBC */
  Hbc = 'HBC',
  /** Magento Community */
  MagentoCommunity = 'MAGENTO_COMMUNITY',
  /** Magento Enterprise */
  MagentoEnterprise = 'MAGENTO_ENTERPRISE',
  /** Merchant Data Upload */
  MerchantDataUpload = 'MERCHANT_DATA_UPLOAD',
  /** Shopify */
  Shopify = 'SHOPIFY',
  /** Shopify plus */
  ShopifyPlus = 'SHOPIFY_PLUS',
  /** Simple */
  Simple = 'SIMPLE',
}

export type ThemeConfig = {
  __typename?: 'ThemeConfig';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  buttonBackgroundColor?: Maybe<Scalars['String']>;
  buttonTextColor?: Maybe<Scalars['String']>;
  defaultFontFamily?: Maybe<Scalars['String']>;
  headerBackgroundColor?: Maybe<Scalars['String']>;
  headerImageUrl?: Maybe<Scalars['String']>;
  linkTextColor?: Maybe<Scalars['String']>;
  logoImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Dynamic field based on the language passed in to the request headers. Options welcomeMessage_en or welcomeMessage_fr */
  welcomeMessage?: Maybe<Scalars['String']>;
  welcomeMessage_en?: Maybe<Scalars['String']>;
  welcomeMessage_fr?: Maybe<Scalars['String']>;
};

export type TotalPriceType = {
  __typename?: 'TotalPriceType';
  presentmentMoney?: Maybe<PriceType>;
  shopMoney?: Maybe<PriceType>;
};

export type TrackingType = {
  __typename?: 'TrackingType';
  company?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  cognitoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type ValidateAddressMutation = {
  __typename?: 'ValidateAddressMutation';
  errorMessage?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  matchedAddress?: Maybe<Address>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ReturnMethodsUserInterfaceSettingsQueryVariables = Exact<{
  storeId: Scalars['Int'];
  consumerLatitude: Scalars['Float'];
  consumerLongitude: Scalars['Float'];
}>;

export type ReturnMethodsUserInterfaceSettingsQuery = {
  __typename?: 'PortalQueries';
  returnMethodsUserInterfaceSettings: {
    __typename?: 'ReturnMethodsUserInterfaceSettings';
    dropOffIsPreferred: boolean;
  };
};

export type GetLocationsQueryVariables = Exact<{
  storeUid: Scalars['UUID'];
}>;

export type GetLocationsQuery = {
  __typename?: 'PortalQueries';
  getLocations?: Array<{
    __typename?: 'LocationType';
    uid: string;
    name: string;
    address?: string | null;
    hoursOfOperationUrl?: string | null;
    instructions?: string | null;
    isActive: boolean;
    externalGid: string;
    coordinates?: {
      __typename?: 'CoordinatesType';
      lat?: number | null;
      lng?: number | null;
    } | null;
  }> | null;
};

export type GetStoreOrderWithLookupParamsQueryVariables = Exact<{
  lookupParams: Array<OrderLookupParamInput> | OrderLookupParamInput;
}>;

export type GetStoreOrderWithLookupParamsQuery = {
  __typename?: 'PortalQueries';
  getStoreOrderWithLookupParams?: {
    __typename?: 'OrderType';
    orderId: string;
    orderNumber?: string | null;
    storeId: string;
    storeUid?: string | null;
    createdAt?: string | null;
    email?: string | null;
    currency?: string | null;
    closed?: string | null;
    fulfillmentStatus?: string | null;
    shippingAddress?: {
      __typename?: 'OrderShippingAddressType';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      name?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      zipCode?: string | null;
    } | null;
    lineItems?: Array<{
      __typename?: 'OrderLineItemType';
      id: string;
      isRemitted?: boolean | null;
      name?: string | null;
      quantity?: number | null;
      refundableQuantity?: number | null;
      returnCaseItemsQuantity?: number | null;
      fulfilledDate?: string | null;
      sku?: string | null;
      requiresShipping?: boolean | null;
      subtotal?: number | null;
      price?: number | null;
      totalTax?: number | null;
      totalPrice?: number | null;
      returnCaseExists?: boolean | null;
      lineType?: string | null;
      returnCaseStatus?: string | null;
      returnConstraint?: {
        __typename?: 'ReturnConstraintType';
        returnWindow?: number | null;
      } | null;
      ruleOutcome?: Array<{
        __typename?: 'Outcome';
        key?: string | null;
        target?: Array<OutcomeTarget> | null;
        value?: any | null;
        valueType?: string | null;
        message?: {
          __typename?: 'OutcomeMessage';
          english?: string | null;
          french?: string | null;
        } | null;
      }> | null;
      store?: {
        __typename?: 'StoreType';
        uid: string;
        name?: string | null;
        sellerType?: StoreSellerTypeChoice | null;
        storeConfig?: {
          __typename?: 'StoreConfig';
          fees?: {
            __typename?: 'StoreConfigFees';
            flatRate?: {
              __typename?: 'StoreConfigFeesFlatRate';
              feeAmount?: string | null;
              feeAmountType?: StoreConfigFeeTypeChoices | null;
              canEditFlatRateFees?: boolean | null;
              feeName?: string | null;
              feeReason?: string | null;
              feeNameEn?: string | null;
              feeNameFr?: string | null;
              returnMethods: Array<ReturnCaseMethods>;
              returnRemitMethods: Array<ReturnMethodChoices>;
            } | null;
          } | null;
        } | null;
      } | null;
      product?: {
        __typename?: 'OrderProductType';
        id: string;
        title?: string | null;
        description?: string | null;
        image?: string | null;
        color?: string | null;
        size?: string | null;
      } | null;
      variant?: {
        __typename?: 'OrderVariantType';
        id: string;
        barcode?: string | null;
        title?: string | null;
        price?: string | null;
        weight?: string | null;
        weightUnit?: string | null;
        image?: string | null;
        color?: string | null;
        size?: string | null;
        customs?: {
          __typename?: 'CustomsType';
          id?: string | null;
          countryCodeOfOrigin?: string | null;
          harmonizedSystemCode?: string | null;
          sku?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetShippingEstimateMutationVariables = Exact<{
  fromAddress1: Scalars['String'];
  fromAddress2: Scalars['String'];
  fromCity: Scalars['String'];
  fromProvince: Scalars['String'];
  fromCountryCode: Scalars['String'];
  fromPostalCode: Scalars['String'];
  fromPhone: Scalars['String'];
  fromName: Scalars['String'];
  fromFirstName: Scalars['String'];
  fromLastName: Scalars['String'];
  fromCompany: Scalars['String'];
  lookupParams: Array<OrderLookupParamInput> | OrderLookupParamInput;
  returnItems?: InputMaybe<
    Array<InputMaybe<Scalars['JSONScalar']>> | InputMaybe<Scalars['JSONScalar']>
  >;
  storeUid: Scalars['UUID'];
}>;

export type GetShippingEstimateMutation = {
  __typename?: 'PortalMutations';
  shippingEstimate?: {
    __typename?: 'ShippingEstimateMutation';
    estimateAmount?: number | null;
    estimateCurrency?: string | null;
    logisticsFee?: number | null;
  } | null;
};

export type CreateReturnCaseWithOrderLookupParamsOpMutationVariables = Exact<{
  consumerSessionId: Scalars['String'];
  lookupParams: Array<OrderLookupParamInput> | OrderLookupParamInput;
  storeId: Scalars['ID'];
  orderJson: Scalars['JSONScalar'];
  returnItems: Array<ReturnCaseItemInput> | ReturnCaseItemInput;
  currency?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  province: Scalars['String'];
  country: Scalars['String'];
  zipCode: Scalars['String'];
  phone: Scalars['String'];
  addressResidentialIndicator: Scalars['String'];
  method: ReturnCaseMethods;
  reviewedPolicyRules:
    | Array<InputMaybe<ReviewedPolicyRuleInput>>
    | InputMaybe<ReviewedPolicyRuleInput>;
  locationId?: InputMaybe<Scalars['UUID']>;
}>;

export type CreateReturnCaseWithOrderLookupParamsOpMutation = {
  __typename?: 'PortalMutations';
  createReturnCaseWithOrderLookupParams?: {
    __typename?: 'CreateReturnCaseWithOrderLookupParamsMutation';
    success?: boolean | null;
    returnCase?: {
      __typename?: 'ReturnCaseType';
      id: string;
      uid: string;
      shortUid?: string | null;
      returnCaseId?: number | null;
      orderId?: string | null;
      orderNumber?: string | null;
      email?: string | null;
      status: ReturnCaseStatus;
      storeId?: number | null;
      totalQuantity: number;
      totalPrice: number;
      currency?: string | null;
      qrCode: string;
      orderShippingAddress?: {
        __typename?: 'Address';
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        cityLocality?: string | null;
        stateProvince?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        addressResidentialIndicator?: string | null;
      } | null;
    } | null;
    shippingLabel?: {
      __typename?: 'ShippingLabelObject';
      labelDownloadUrl?: string | null;
      errorMessage?: string | null;
      label?: {
        __typename?: 'Label';
        labelId?: string | null;
        status?: string | null;
        trackingNumber?: string | null;
        trackable?: boolean | null;
        trackingStatus?: string | null;
        labelDownload?: { __typename?: 'Asset'; pdf?: string | null; png?: string | null } | null;
      } | null;
    } | null;
    shippingDocuments?: {
      __typename?: 'ShippingDocumentsType';
      label?: {
        __typename?: 'DocumentDownloadType';
        url?: string | null;
        pdf?: string | null;
        png?: string | null;
        data?: string | null;
      } | null;
      customsInvoice?: {
        __typename?: 'DocumentDownloadType';
        url?: string | null;
        pdf?: string | null;
        png?: string | null;
        data?: string | null;
      } | null;
      packingSlip?: {
        __typename?: 'DocumentDownloadType';
        url?: string | null;
        pdf?: string | null;
        png?: string | null;
        data?: string | null;
      } | null;
    } | null;
    exchangeItems?: Array<{
      __typename?: 'ExchangeCaseItemType';
      id: string;
      orderLineItemId?: string | null;
      returnCaseItem: { __typename?: 'ReturnCaseItemType'; uid: string };
      price?: { __typename?: 'Price'; amount?: number | null; currency?: string | null } | null;
      lineItem?: {
        __typename?: 'ExchangeCaseItemOrderLineItemType';
        id: string;
        name?: string | null;
        sku?: string | null;
        product?: {
          __typename?: 'OrderProductType';
          id: string;
          title?: string | null;
          description?: string | null;
          image?: string | null;
          color?: string | null;
          size?: string | null;
        } | null;
        variant?: {
          __typename?: 'OrderVariantType';
          id: string;
          barcode?: string | null;
          title?: string | null;
          price?: string | null;
          weight?: string | null;
          weightUnit?: string | null;
          image?: string | null;
          color?: string | null;
          size?: string | null;
        } | null;
      } | null;
    }> | null;
    returnItems?: Array<{
      __typename?: 'ReturnCaseItemType';
      id: string;
      uid: string;
      quantity?: number | null;
      orderLineItemId?: string | null;
      returnReason?: string | null;
      returnMethod?: ReturnCaseItemReturnMethod | null;
      comment?: string | null;
      status: ReturnCaseItemStatus;
      price?: { __typename?: 'Price'; amount?: number | null; currency?: string | null } | null;
      lineItem?: {
        __typename?: 'ReturnCaseItemOrderLineItemType';
        id: string;
        name?: string | null;
        quantity?: number | null;
        refundableQuantity?: number | null;
        sku?: string | null;
        product?: {
          __typename?: 'ReturnCaseItemOrderProductType';
          id: string;
          title?: string | null;
          description?: string | null;
          image?: string | null;
          color?: Array<string | null> | null;
          size?: Array<string | null> | null;
        } | null;
        variant?: {
          __typename?: 'ReturnCaseItemOrderVariant';
          id: string;
          barcode?: string | null;
          title?: string | null;
          price?: string | null;
          weight?: string | null;
          weightUnit?: string | null;
          image?: string | null;
          color?: string | null;
          size?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetStoreConfigQueryVariables = Exact<{
  subdomain: Scalars['String'];
}>;

export type GetStoreConfigQuery = {
  __typename?: 'PortalQueries';
  getStoreConfig?: {
    __typename?: 'StoreConfig';
    packingSlip?: string | null;
    customerServiceEmail?: string | null;
    returnPolicyUrl?: string | null;
    returnPolicyUrl_en?: string | null;
    returnPolicyUrl_fr?: string | null;
    approvalDays?: string | null;
    shoppingUrl?: string | null;
    shoppingUrl_en?: string | null;
    shoppingUrl_fr?: string | null;
    returnChannel?: Array<string> | null;
    retailerPolicyUrl?: string | null;
    returnWindow?: string | null;
    orderLookupParams?: Array<string> | null;
    hasShippingLogisticFee?: boolean | null;
    hasTaxesWithheld?: boolean | null;
    returnMethod?: Array<string> | null;
    bonusAmount?: string | null;
    hasInboundShippingFee?: {
      __typename?: 'InboundShippingFees';
      US?: boolean | null;
      CA?: boolean | null;
    } | null;
    fees?: {
      __typename?: 'StoreConfigFees';
      refund?: {
        __typename?: 'StoreConfigFeesRefund';
        feeAmount?: string | null;
        feeAmountType?: StoreConfigFeeTypeChoices | null;
        canEditRefundFees?: boolean | null;
        feeName?: string | null;
        feeReason?: string | null;
      } | null;
      exchange?: {
        __typename?: 'StoreConfigFeesExchange';
        feeAmount?: string | null;
        feeAmountType?: StoreConfigFeeTypeChoices | null;
        canEditExchangeFees?: boolean | null;
        canEditExchangeShipping?: boolean | null;
        feeName?: string | null;
        feeReason?: string | null;
      } | null;
      storeCredit?: {
        __typename?: 'StoreConfigFeesStoreCredit';
        feeAmount?: string | null;
        feeAmountType?: StoreConfigFeeTypeChoices | null;
        canEditStoreCreditFees?: boolean | null;
        feeName?: string | null;
        feeReason?: string | null;
      } | null;
      flatRate?: {
        __typename?: 'StoreConfigFeesFlatRate';
        feeAmount?: string | null;
        feeAmountType?: StoreConfigFeeTypeChoices | null;
        canEditFlatRateFees?: boolean | null;
        feeName?: string | null;
        feeReason?: string | null;
        feeNameEn?: string | null;
        feeNameFr?: string | null;
        returnMethods: Array<ReturnCaseMethods>;
        returnRemitMethods: Array<ReturnMethodChoices>;
      } | null;
    } | null;
    merchantReturnMethodPageBanner?: {
      __typename?: 'StoreConfigMerchantReturnMethodPageBanner';
      messageEn?: string | null;
      messageFr?: string | null;
      ctaMessageEn?: string | null;
      ctaMessageFr?: string | null;
      ctaLinkEn?: string | null;
      ctaLinkFr?: string | null;
    } | null;
  } | null;
};

export type ListAcceptanceCriteriaQueryVariables = Exact<{ [key: string]: never }>;

export type ListAcceptanceCriteriaQuery = {
  __typename?: 'PortalQueries';
  listAcceptanceCriteria?: Array<{
    __typename?: 'CriteriaType';
    id: string;
    uid: string;
    description: string;
    descriptionFr: string;
    autoFalseIfReasonIn: Array<ReturnReasonReasonType>;
    displayOnConsumerPortal: boolean;
  } | null> | null;
};

export type GetProductVariantsQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type GetProductVariantsQuery = {
  __typename?: 'PortalQueries';
  getProductVariants?: {
    __typename?: 'ProductType';
    variants?: Array<{
      __typename?: 'ProductVariantType';
      id?: string | null;
      title?: string | null;
      price?: string | null;
      sku?: string | null;
      image?: string | null;
      displayName?: string | null;
      inventoryQuantity?: number | null;
      selectedOptions?: Array<{
        __typename?: 'OptionType';
        name?: string | null;
        value?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type ValidateAddressOperationMutationVariables = Exact<{
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressResidentialIndicator: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  province: Scalars['String'];
  zipCode: Scalars['String'];
}>;

export type ValidateAddressOperationMutation = {
  __typename?: 'PortalMutations';
  validateAddress?: {
    __typename?: 'ValidateAddressMutation';
    errorMessage?: string | null;
    matchedAddress?: {
      __typename?: 'Address';
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressResidentialIndicator?: string | null;
      cityLocality?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      stateProvince?: string | null;
    } | null;
  } | null;
};

export const ReturnMethodsUserInterfaceSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReturnMethodsUserInterfaceSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumerLatitude' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumerLongitude' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnMethodsUserInterfaceSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumerLatitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumerLatitude' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumerLongitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumerLongitude' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dropOffIsPreferred' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReturnMethodsUserInterfaceSettingsQuery,
  ReturnMethodsUserInterfaceSettingsQueryVariables
>;
export const GetLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeUid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeUid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeUid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoursOfOperationUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalGid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetStoreOrderWithLookupParamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStoreOrderWithLookupParams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderLookupParamInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStoreOrderWithLookupParams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lookupParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeUid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'provinceCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lineItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRemitted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refundableQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnCaseItemsQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfilledDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnCaseExists' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnCaseStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returnConstraint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'returnWindow' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ruleOutcome' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'valueType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'english' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'french' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sellerType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storeConfig' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fees' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'flatRate' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeAmount' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeAmountType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'canEditFlatRateFees',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeReason' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeNameEn' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'feeNameFr' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'returnMethods' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'returnRemitMethods' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'countryCodeOfOrigin' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'harmonizedSystemCode' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStoreOrderWithLookupParamsQuery,
  GetStoreOrderWithLookupParamsQueryVariables
>;
export const GetShippingEstimateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetShippingEstimate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromAddress1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromAddress2' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromCity' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromProvince' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromCountryCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromPostalCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromPhone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromFirstName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromLastName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromCompany' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderLookupParamInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnItems' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSONScalar' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeUid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingEstimate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromAddress1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromAddress1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromAddress2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromAddress2' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromCity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromProvince' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromProvince' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCountryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromCountryCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromPostalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromPostalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromPhone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromPhone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromFirstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromFirstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromLastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromLastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCompany' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromCompany' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lookupParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnItems' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'returnItems' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeUid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeUid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'estimateAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimateCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logisticsFee' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShippingEstimateMutation, GetShippingEstimateMutationVariables>;
export const CreateReturnCaseWithOrderLookupParamsOpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReturnCaseWithOrderLookupParamsOp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumerSessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderLookupParamInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderJson' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSONScalar' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnItems' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReturnCaseItemInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'province' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressResidentialIndicator' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReturnCaseMethods' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reviewedPolicyRules' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewedPolicyRuleInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReturnCaseWithOrderLookupParams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumerSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumerSessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lookupParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lookupParams' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderJson' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderJson' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnItems' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'returnItems' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address2' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'province' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'province' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressResidentialIndicator' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressResidentialIndicator' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'method' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewedPolicyRules' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reviewedPolicyRules' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returnCase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortUid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnCaseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderShippingAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cityLocality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressResidentialIndicator' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnCaseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qrCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingLabel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'labelDownloadUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelDownload' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'png' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'png' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customsInvoice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'png' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packingSlip' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'png' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exchangeItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returnCaseItem' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderLineItemId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineItem' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returnItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderLineItemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineItem' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'refundableQuantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReturnCaseWithOrderLookupParamsOpMutation,
  CreateReturnCaseWithOrderLookupParamsOpMutationVariables
>;
export const GetStoreConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoreConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subdomain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStoreConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subdomain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subdomain' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'packingSlip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerServiceEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPolicyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPolicyUrl_en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPolicyUrl_fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shoppingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shoppingUrl_en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shoppingUrl_fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnChannel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retailerPolicyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnWindow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderLookupParams' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasShippingLogisticFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTaxesWithheld' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasInboundShippingFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'US' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'CA' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'returnMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bonusAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refund' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEditRefundFees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeReason' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exchange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEditExchangeFees' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canEditExchangeShipping' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeReason' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeCredit' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmountType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canEditStoreCreditFees' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeReason' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flatRate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEditFlatRateFees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeNameEn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeNameFr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'returnMethods' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'returnRemitMethods' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchantReturnMethodPageBanner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'messageEn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'messageFr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaMessageEn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaMessageFr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkEn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkFr' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoreConfigQuery, GetStoreConfigQueryVariables>;
export const ListAcceptanceCriteriaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAcceptanceCriteria' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listAcceptanceCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionFr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoFalseIfReasonIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayOnConsumerPortal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListAcceptanceCriteriaQuery, ListAcceptanceCriteriaQueryVariables>;
export const GetProductVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProductVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventoryQuantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedOptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductVariantsQuery, GetProductVariantsQueryVariables>;
export const ValidateAddressOperationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateAddressOperation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressResidentialIndicator' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'province' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address2' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressResidentialIndicator' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressResidentialIndicator' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'province' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'province' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchedAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressResidentialIndicator' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cityLocality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stateProvince' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateAddressOperationMutation,
  ValidateAddressOperationMutationVariables
>;
