import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { uniqBy, isEqual } from 'lodash-es';
import { TFunction } from 'i18next';

import {
  UseProductVariantsOptions,
  ExchangeOptions,
  ProductVariant,
  ExchangeRecord,
  ExchangeFormRecord,
  OrderVariantType,
} from 'app/types';
import { GetProductVariantsDocument } from 'generated/graphql';
import { getIdFromShopifyProductUrl } from 'utils';
import { notEmpty } from 'utils/arrays';

export const useProductVariants = (
  shopifyProductId: string,
  selectedOptions: ExchangeFormRecord,
  orderVariant: OrderVariantType,
  { skip = false }: UseProductVariantsOptions = {}
) => {
  const { t } = useTranslation();
  const productId = useMemo(() => getIdFromShopifyProductUrl(shopifyProductId), [shopifyProductId]);

  const { data: variantData, ...query } = useQuery(GetProductVariantsDocument, {
    variables: {
      productId,
    },
    skip,
    fetchPolicy: 'cache-first',
  });

  const variants = useMemo(
    () => variantData?.getProductVariants?.variants?.filter(notEmpty) ?? [],
    [variantData]
  );

  const validatedVariant = useMemo(() => {
    const _matchedVariant = variants?.find((v) => {
      const record: ExchangeFormRecord = {};
      v?.selectedOptions?.forEach((option) => {
        if (option.name && option.value) {
          record[option.name] = option.value;
        }
      });
      return isEqual(record, selectedOptions);
    });

    return _matchedVariant;
  }, [variants, selectedOptions]);

  // set the variant as the users purchased variant or the first
  // this provides the exchange options for the dropdowns
  // at the momment shopify adds a default value to all variants
  // https://help.shopify.com/en/manual/products/variants/edit-variants
  // this may change with other platforms
  const currentReturnedVariant = variants
    ? variants.find((v) => v.id === orderVariant.id) || getFirstInstockVariant(variants)
    : undefined;

  // builds options for the dropdown
  const exchangeOptions = useMemo<ExchangeOptions | null>(() => {
    if (!variantData || !variantData.getProductVariants) {
      return null;
    }

    const dropDropOptions: ExchangeOptions = {};

    // assumes that selected variant will have all keys for all variants
    const selectedVariantExchangeOptions =
      (currentReturnedVariant?.selectedOptions &&
        Object.values(currentReturnedVariant?.selectedOptions)) ||
      [];

    // js objs tend to add keys in alphabetical order
    const selectedVariantExchangeKeys = selectedVariantExchangeOptions
      .flatMap((option) => (option.name ? [option.name] : []))
      .sort();

    // get all dropdown options
    variants &&
      selectedVariantExchangeKeys.forEach((key, index) => {
        // mappedOptions = records of options for dropdown
        let mappedOptions: ExchangeRecord[] | undefined = [];

        if (!index) {
          mappedOptions = variants.map((variant) => {
            const variantValue =
              variant.selectedOptions?.find((option) => option.name === key)?.value || '';

            // Only apply changes to the products that have a single variant key
            const hasSingleVariant = selectedVariantExchangeKeys.length <= 1;
            const inventoryQuantity = variant.inventoryQuantity ?? 0;
            const isSoldOut = inventoryQuantity <= 0;
            const hasPriceDifference = Number(orderVariant.price) !== Number(variant.price);
            const isVariantSoldOutOrHasPriceDifference =
              hasSingleVariant && (isSoldOut || hasPriceDifference);

            const soldOutOrHasPriceDifferenceText =
              hasSingleVariant && isSoldOut
                ? `${variantValue} (${t('selectItemsPage.soldOut')})`
                : hasSingleVariant && hasPriceDifference
                ? `${variantValue} (${t('selectItemsPage.priceDifference')})`
                : variantValue;

            return {
              label: soldOutOrHasPriceDifferenceText,
              value: variantValue,
              disabled: isVariantSoldOutOrHasPriceDifference,
            };
          });
          // if its the second key or greater than modify the label text
        } else {
          const optionKeys = Object.keys(dropDropOptions);

          // Users are forced to complete the previous option if there are multiple
          // exchange variant keys before they can continue therefore the current option can
          // only be a subset of the previous option value
          const previousOptionValue = selectedOptions[optionKeys[index - 1]];
          const relevantVariants = getRelatedVariants(variants, previousOptionValue);
          mappedOptions =
            currentReturnedVariant &&
            relevantVariants.map((variant) => buildOptionRecord(key, variant, orderVariant, t));
        }

        const uniqOptions = uniqBy(mappedOptions, 'label');
        const sortedValues = uniqOptions.sort((a, b) => Number(a.disabled) - Number(b.disabled));
        const newObj = {
          [key]: sortedValues,
        };
        Object.assign(dropDropOptions, newObj);
      });
    return dropDropOptions;
  }, [variantData, t, selectedOptions, variants, currentReturnedVariant, orderVariant]);

  const validatedVariantId = validatedVariant ? validatedVariant?.id : '';

  return {
    ...query,
    variantData,
    exchangeOptions,
    validatedVariant,
    validatedVariantId,
  };
};

/**
 * Builds a record for an option drop down
 * @param  key the formik values
 * @param  variant The variant to build a dropdown option for
 * @param  currentReturnedVariant The customers historical variant used for determining price differences
 * @param  t i18tn TFunction
 * @param  exchangeFee The exchange fee for an alert
 * @returns {ExchangeRecord} a record for the option drop down
 */
const buildOptionRecord = (
  key: string,
  variant: ProductVariant,
  currentReturnedVariant: OrderVariantType,
  t: TFunction
) => {
  const currentExchangeOption = variant.selectedOptions?.find((x) => x.name === key);

  const inventoryQuantity = variant.inventoryQuantity ?? 0;
  let label = currentExchangeOption?.value || '';
  const value = currentExchangeOption?.value || '';
  const isSoldOut = inventoryQuantity <= 0;
  const hasPriceDifference = Number(variant.price) !== Number(currentReturnedVariant.price);
  let disabled = false;

  if (isSoldOut) {
    label = `${label} (${t('selectItemsPage.soldOut')})`;
    disabled = true;
  } else if (hasPriceDifference) {
    label = `${label} (${t('selectItemsPage.priceDifference')})`;
    disabled = true;
  }
  const result = {
    label,
    disabled,
    value,
  };

  return result;
};

/**
 * Gets variants with the same value
 * @param  variants product variants
 * @param  optionValue the value to filter by
 * @returns {filteredVariants} an array of ProductVariants
 */
const getRelatedVariants = (variants: ProductVariant[], optionValue: string) => {
  const filteredVariants = variants.filter((v) =>
    v.selectedOptions?.find((option) => option.value === optionValue)
  );
  return filteredVariants;
};
/**
 *
 * Gets variants with the same value
 * @param  variants product variants
 * @returns {variant} the first variant with stock
 */
const getFirstInstockVariant = (variants: ProductVariant[]) => {
  const filteredVariants = variants.find((v) => (v.inventoryQuantity ?? 0) > 1);
  return filteredVariants;
};
