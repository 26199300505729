import { createContext, useContext } from 'react';

import {
  PolicyRule,
  ReturnAcceptanceCriteria,
  ReturnReasonInterface,
  StoreConfigInterface,
  ThemeConfigInterface,
} from 'app/types';

import { useStoreConfigProvider } from './useStoreConfigProvider';

export interface StoreConfigContextInterface {
  returnAcceptanceCriteria: ReturnAcceptanceCriteria[];
  storeConfig: StoreConfigInterface | null;
  themeConfig: ThemeConfigInterface | null;
  policyRules: PolicyRule[];
  policyRulesLoading: boolean;
  isInitializing: boolean;
  hasExchangeFee?: boolean;
  hasRefundFee?: boolean;
  hasFlatRateFee?: boolean;
  hasStoreCreditFee?: boolean;
  storeReturnReasons?: ReturnReasonInterface[];
  hasTaxesWithheld?: boolean;
}

export const StoreConfigContext = createContext<StoreConfigContextInterface>({
  returnAcceptanceCriteria: [],
  storeConfig: null,
  themeConfig: null,
  isInitializing: true,
  policyRules: [],
  policyRulesLoading: true,
});

/**
 * Provides a context wrapper that loads store configuration based on the current subdomain
 */
export const StoreConfigProvider: React.FC<React.ReactNode> = ({ children }) => (
  <StoreConfigContext.Provider value={useStoreConfigProvider()}>
    {children}
  </StoreConfigContext.Provider>
);

export const useStoreConfig = () => {
  return useContext(StoreConfigContext);
};
