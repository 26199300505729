import styles from './ToggleTokens.module.scss';

interface ToggleTokensProps {
  /**
   * The options to render
   */
  items: { value: string; label: string }[];

  /**
   * A callback function that will be triggered whenever a new value is selected
   */
  onChange: (currentValue: string) => void;

  /**
   * The currently selected value
   */
  value?: string | null;
}

export const ToggleTokens = ({ items, value, onChange }: ToggleTokensProps) => (
  <div className={styles.container}>
    {items.map((item) => {
      const isSelected = item.value === value;
      return (
        <button
          tabIndex={isSelected ? -1 : 0}
          type="button"
          className={isSelected ? styles.activeToken : styles.token}
          onClick={() => {
            if (typeof onChange === 'function') {
              onChange(item.value);
            }
          }}
          key={item.value}
        >
          {item.label}
        </button>
      );
    })}
  </div>
);
