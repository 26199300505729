import { Coordinate, DropoffLocation } from 'app/types';

export const parseJson = (jsonString?: string | null) => {
  let parsedJson;
  try {
    if (jsonString) {
      return JSON.parse(jsonString);
    }
  } catch (error) {
    return parsedJson;
  }
};

export const parseNonPilotStoresJson = (jsonString?: string | null): Coordinate[] => {
  const parsedPilotStores: Coordinate[] = parseJson(jsonString);
  if (!parsedPilotStores) {
    /* eslint-disable-next-line no-console */
    console.warn('Unable to parse non pilot store locations');
  }

  return parsedPilotStores || [];
};

export const convertNonPilotStoresToDropoffLocations = (
  allCoordinates: Coordinate[]
): DropoffLocation[] => {
  return allCoordinates.map((coordinates) => ({
    address: null,
    coordinates: coordinates,
    hoursOfOperationUrl: null,
    instructions: null,
    isActive: false,
    externalGid: '',
    name: '',
    uid: '',
  }));
};
