import { useMemo } from 'react';
import { useSelect } from 'downshift';
import clsx from 'clsx';

import { IconChevronDown } from 'icons';

import styles from './Select.module.scss';

export interface SelectItemShape {
  /**
   * The value of the item
   */
  value: string;

  /**
   * The label to display in the dropdown
   *
   * This value also gets displayed in the button prompt
   */
  label: string;

  /**
   * Whether or not to disable this item from selection
   */
  disabled?: boolean;
}

export interface SelectProps {
  /**
   * The text to display in the button prompt before a value gets selected
   */
  buttonText: React.ReactNode;

  /**
   * An optional className to be appended to the outer container
   */
  className?: string;

  /**
   * An array of items to display inside the dropdown
   */
  items: SelectItemShape[];

  /**
   * The text to be displayed in the select's <label> element
   */
  label?: React.ReactNode;

  /**
   * A callback function to be fired whenever the selected value is changed
   */
  onChange?: (selectedValue: string) => void;

  /**
   * The currently selected value
   */
  value?: string | null;

  /**
   * Whether or not to disable the select
   */
  disabled?: boolean;

  /**
   * Whether or not to show the error
   */
  error?: string | null;

  /**
   * Whether or not the select is required
   */
  required?: boolean;
}

export const Select = ({
  buttonText,
  className,
  items,
  label,
  onChange,
  value,
  disabled,
  error,
  required,
}: SelectProps) => {
  const selectedItem = useMemo(() => {
    return items.find((item) => item.value === value) || null;
  }, [value, items]);
  const {
    isOpen,
    getLabelProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (typeof onChange === 'function' && selectedItem) {
        if (!selectedItem.disabled) {
          onChange(selectedItem?.value);
        }
      }
    },
  });
  return (
    <div className={clsx(styles.container, disabled && styles.containerDisabled, className)}>
      <label {...getLabelProps()} className={styles.label}>
        {required ? `${label}*` : label}
      </label>
      <button
        type="button"
        {...getToggleButtonProps()}
        className={clsx(
          styles.button,
          disabled && styles.buttonDisabled,
          error && styles.buttonError
        )}
      >
        <div className={styles.currentValue}>
          <div className={styles.buttonText}>{selectedItem?.label ?? buttonText}</div>
          <IconChevronDown size={10} className={styles.chevronDownIcon} />
        </div>
      </button>
      {error && <span className={styles.error}>{error}</span>}
      <ul
        {...getMenuProps({
          className: clsx(
            styles.dropdown,
            isOpen && styles.visible,
            disabled && styles.dropDownDisabled
          ),
        })}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              key={`${item}${index}`}
              className={clsx(
                styles.dropdownItem,
                highlightedIndex === index && styles.dropdownItemActive,
                item.disabled && styles.lineItemDisabled
              )}
              {...getItemProps({ item, index })}
            >
              {item?.label}
            </li>
          ))}
      </ul>
    </div>
  );
};
