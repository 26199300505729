import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Stack, useMediaQuery } from '@mui/material';

import { DropoffLocation } from 'app/types';
import { IconExternalLink, IconReturnBear } from 'icons';
import { Button, Placeholder, SelectableItem } from 'ui';

import styles from './LocationsList.module.scss';

interface LocationsListProps {
  dropoffLocations: DropoffLocation[];
  showLocationsModal: () => void;
  handleContinueClick: (location: string) => void;
}

const LocationsList = ({
  dropoffLocations,
  showLocationsModal,
  handleContinueClick,
}: LocationsListProps) => {
  const { t } = useTranslation();
  // TODO: We need to use Material ui for breakpoints instead of hardcoding values
  const isMobile = useMediaQuery('(max-width:599px)');
  const selectableLocationsLimit = 3;
  const selectableDropoffLocations = dropoffLocations.slice(0, selectableLocationsLimit);

  return (
    <div className={styles.locationsList}>
      {selectableDropoffLocations.map((location) => {
        const calculatedDistance = isMobile
          ? location.distance
          : t('returnMethodPage.locationsList.locationDistance', {
              distance: location.distance,
            });
        return (
          <SelectableItem
            id={`list-location-${location.uid}`}
            key={location.uid}
            onClick={() => handleContinueClick(location.uid)}
            className={styles.location}
          >
            <div className={clsx(styles.locationDropOffIcon, styles.isSelected)}>
              <IconReturnBear size={25} />
            </div>

            <div className={styles.locationInfo}>
              <div className={styles.locationName}>{location.name}</div>

              <div
                className={clsx(styles.locationAddress, isMobile && styles.locationAddressMobile)}
              >
                {location.address}
              </div>

              <div className={styles.linkSection}>
                <Button
                  appearance="link"
                  className={styles.mapButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    showLocationsModal();
                  }}
                >
                  <Stack alignItems="center" direction="row">
                    <MapOutlinedIcon fontSize="small" />
                    <div className={styles.mapText}>{t('returnMethodPage.locationsList.map')}</div>
                  </Stack>
                </Button>

                {location?.hoursOfOperationUrl && (
                  <a
                    href={location.hoursOfOperationUrl}
                    target="_locationHours"
                    className={styles.hoursOfOperationUrl}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {t('returnMethodPage.viewHours')} <IconExternalLink />
                  </a>
                )}
              </div>
            </div>

            <div className={styles.detailsRightSection}>
              <div className={styles.locationDistance}>
                {location.distance === undefined ? <Placeholder height={20} /> : calculatedDistance}
              </div>

              <div className={styles.arrowRightIcon}>
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </SelectableItem>
        );
      })}
      <div className={styles.moreOptionsButtonArea}>
        <Button className={styles.moreOptionsButton} onClick={showLocationsModal}>
          {t('returnMethodPage.moreOptions')}
        </Button>
      </div>
    </div>
  );
};

export default LocationsList;
