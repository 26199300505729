import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReturnFlow } from 'app/hooks';

import styles from './ContinueCta.module.scss';

interface Props {
  /**
   * If provided, renders the provided button in the top right of the layout
   */
  continueButton?: React.ReactNode;

  /**
   * OPTIONAL
   * The count of the total number of selected items.
   *
   * Because the items count is dynamic on Step 2 (<SelectItems />), we need to
   * be able to use that dynamic count only for Step 2. The steps after that
   * will have the selected items count pulled from the useReturnFlow context,
   * which is set AFTER step 2 which is a static, unchanging number.
   */
  selectedItemsCount?: number | null;
}

const ContinueCta = ({ continueButton, selectedItemsCount }: Props) => {
  const { t } = useTranslation();
  const { returnItems } = useReturnFlow();
  const commonContent = (
    <>
      <div className={styles.itemsSelected}>
        {t('selectItemsPage.selectedItemCount', {
          count: selectedItemsCount ?? returnItems.length,
        })}
      </div>
      {continueButton && <div className={styles.continueButton}>{continueButton}</div>}
    </>
  );
  return (
    <>
      <div className={styles.fullWidthStepper}>
        <div className={styles.fullWidthStepperWrapper}>{commonContent}</div>
      </div>
      <div className={styles.mobileStepper}>{commonContent}</div>
    </>
  );
};

export default ContinueCta;
