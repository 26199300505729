import React from 'react';
import clsx from 'clsx';

import styles from './Chip.module.scss';

export interface ChipProps {
  text: string;

  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

  /**
   * An optional className to append to the container element
   */
  className?: string;

  size?: 'small' | 'medium';
}

export const Chip = ({ text, color, size, className }: ChipProps) => {
  return (
    <div
      className={clsx(
        styles.chipRoot,
        color === 'primary' && styles.chipColorPrimary,
        color === 'secondary' && styles.chipColorSecondary,
        color === 'error' && styles.chipColorError,
        color === 'info' && styles.chipColorInfo,
        color === 'success' && styles.chipColorSuccess,
        color === 'warning' && styles.chipColorWarning,
        size === 'small' && styles.chipSizeSmall,
        className
      )}
    >
      <span className={styles.chipLabel}>{text}</span>
    </div>
  );
};

Chip.defaultProps = {
  type: 'text',
};
