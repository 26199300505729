import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { canadianProvinces, countries, usStates } from 'app/constants';
import { AddressContext, useStoreOrder } from 'app/contexts';
import { ShippingAddress } from 'app/types';
import { ValidateAddressOperationDocument } from 'generated/graphql';
import { Button, Modal, Select, TextInput } from 'ui';

import styles from './ReturnMethodChangeAddressModal.module.scss';
import { AddressValidationModalFormValues } from './types';

interface ReturnMethodChangeAddressProps {
  /**
   * Determines whether or not the StorePolicyChecklistModal is visible
   *
   * When not visible, the element is removed from the DOM
   */
  isVisible: boolean;

  /**
   * A callback function to be called whenever the modal's Close button is clicked
   */
  onClose?: () => void;

  /**
   * A callback function to be called when the Continue button at the bottom of the modal
   * is clicked
   *
   * This function receives an array of PolicyRules filled with objects containing { uid, rule, accepted }
   */
  onSubmit?: (success: boolean) => void;
}

/**
 * Renders a modal containing a change address form.
 */
export const ReturnMethodChangeAddressModal = ({
  isVisible,
  onClose,
  onSubmit,
}: ReturnMethodChangeAddressProps) => {
  const { storeOrder } = useStoreOrder();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { setAddressInfo } = useContext(AddressContext);
  const [apiError, setApiError] = useState<string>('');

  const [validateAddress, { loading: isApiLoading, data: apiData }] = useMutation(
    ValidateAddressOperationDocument,
    {
      onCompleted: (data) => {
        if (data?.validateAddress?.errorMessage) {
          return setApiError(data?.validateAddress?.errorMessage);
        }

        if (data?.validateAddress?.matchedAddress) {
          setEditMode(false);
          const matchedAddress = data?.validateAddress?.matchedAddress;
          // Update the form values to use the correct information from the valid matchedAddress
          setValues({
            address1: matchedAddress?.addressLine1 || values.address1,
            address2: matchedAddress?.addressLine2 || values.address2,
            addressResidentialIndicator:
              matchedAddress?.addressResidentialIndicator || values.addressResidentialIndicator,
            city: matchedAddress?.cityLocality || values.city,
            country: matchedAddress?.countryCode || values.country,
            province: matchedAddress?.stateProvince || values.province,
            zipCode: matchedAddress?.postalCode || values.zipCode,
          });
        }
      },
    }
  );

  const { values, setFieldValue, setValues, errors, handleChange, submitForm, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        address1: storeOrder?.shippingAddress?.address1 ?? '',
        address2: storeOrder?.shippingAddress?.address2 ?? '',
        addressResidentialIndicator: 'yes',
        city: storeOrder?.shippingAddress?.city ?? '',
        country: storeOrder?.shippingAddress?.countryCode ?? '',
        province: storeOrder?.shippingAddress?.provinceCode ?? '',
        zipCode: storeOrder?.shippingAddress?.zipCode ?? '',
      },
      validate: (values) => {
        const errors: Partial<AddressValidationModalFormValues> = {};
        if (!values.address1) {
          errors.address1 = `${t('common.address')} ${t('errors.required')}`;
        }
        if (!values.city) {
          errors.city = `${t('common.city')} ${t('errors.required')}`;
        }
        if (!values.zipCode) {
          errors.zipCode = `${t('common.postalOrZipCode')} ${t('errors.required')}`;
        }

        return errors;
      },
      onSubmit: (formValues) => {
        return validateAddress({
          variables: {
            address1: formValues.address1,
            address2: formValues.address2,
            addressResidentialIndicator: formValues.addressResidentialIndicator,
            city: formValues.city,
            country: formValues.country,
            province: formValues.province,
            zipCode: formValues.zipCode,
          },
        });
      },
    });

  /**
   * Handles what happens when the Continue button at the bottom of the modal is clicked
   */
  const handleConfirmClick = () => {
    if (storeOrder?.shippingAddress) {
      const addr: ShippingAddress = {
        id: '',
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        countryCode: values.country,
        country: countryNameFromCode(values.country),
        provinceCode: values.province,
        province: provinceNameFromCode(values.country, values.province),
        zipCode: values.zipCode,
      };
      setAddressInfo(addr);
    }
    if (typeof onSubmit === 'function') {
      onSubmit(true);
    }

    handleClose();
  };

  const handleClose = () => {
    setEditMode(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const provinceOptions = provinceOptionsForCountry(values.country);

  return (
    <Modal
      title={t('returnMethodPage.modal.title')}
      isVisible={isVisible}
      onClose={handleClose}
      customTitleClassName={styles.titleOverrides}
      customFooterClassName={styles.footer}
      closeButtonLabel={t('common.close')}
      footer={
        <>
          {editMode && (
            <>
              <Button className={styles.ctaButton} onClick={handleClose} appearance={'text'}>
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                className={styles.ctaButton}
                isWorking={isApiLoading}
                onClick={() => {
                  // Clear any previous api errors
                  setApiError('');
                  submitForm();
                }}
                disabled={isApiLoading}
              >
                {t('common.submit')}
              </Button>
            </>
          )}

          {!editMode && (
            <>
              <Button
                className={styles.ctaButton}
                onClick={() => setEditMode(true)}
                appearance={apiData?.validateAddress?.matchedAddress ? 'text' : undefined}
              >
                {t('common.edit')}
              </Button>
              {/* 
                We only show this modal if the user's address is not valid, so we hide the "Confirm"
                button until the user enters a valid address that is validated by the api.
              */}
              {apiData?.validateAddress?.matchedAddress && (
                <Button className={styles.ctaButton} onClick={handleConfirmClick}>
                  {t('common.confirm')}
                </Button>
              )}
            </>
          )}
        </>
      }
      className={styles.container}
      modalClass={styles.modalOverrides}
    >
      {!editMode && (
        <div className={styles.readOnlyWrapper}>
          <p>{values.address1 || storeOrder?.shippingAddress?.address1}</p>
          <p>{values.address2 || storeOrder?.shippingAddress?.address2}</p>
          <p>
            {values.city || storeOrder?.shippingAddress?.city}{' '}
            {values.zipCode || storeOrder?.shippingAddress?.zipCode}{' '}
            {values.province || storeOrder?.shippingAddress?.province}
          </p>
          <p>{values.country || storeOrder?.shippingAddress?.country}</p>
        </div>
      )}
      {editMode && (
        <form onSubmit={handleSubmit}>
          <div className={styles.mainWrapper}>
            {apiError && <div className={styles.globalErrorsText}>{apiError}</div>}
            <TextInput
              label={t('common.address')}
              className={styles.input}
              onChange={handleChange}
              name={'address1'}
              value={values.address1}
              errorText={errors.address1}
            />

            <TextInput
              label={t('common.aptSuiteBuildingUnitOptional')}
              className={styles.input}
              onChange={handleChange}
              name={'address2'}
              value={values.address2}
              errorText={errors.address2}
            />

            <TextInput
              label={t('common.city')}
              className={styles.input}
              onChange={handleChange}
              name={'city'}
              value={values.city}
              errorText={errors.city}
            />

            <div className={styles.wrapper}>
              <div className={styles.leftWrapper}>
                <TextInput
                  label={t('common.postalOrZipCode')}
                  className={styles.input}
                  onChange={handleChange}
                  name={'zipCode'}
                  value={values.zipCode}
                  errorText={errors.zipCode}
                />
              </div>

              {provinceOptions.length ? (
                <div className={styles.selectRightWrapper}>
                  <Select
                    className={styles.input}
                    label={t('common.provinceOrState')}
                    onChange={(e) => {
                      setFieldValue('province', e);
                    }}
                    buttonText={''}
                    value={values.province}
                    items={provinceOptions}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.selectLeftWrapper}>
              <Select
                onChange={(e) => {
                  setFieldValue('country', e);
                  const newProvinces = provinceOptionsForCountry(e);

                  if (!newProvinces.find((p) => p.value === values.province)) {
                    setFieldValue('province', '');
                  }
                }}
                className={styles.input}
                label={t('common.country')}
                buttonText={''}
                value={values.country}
                items={countries}
              />
            </div>
            <br />
          </div>
        </form>
      )}
    </Modal>
  );
};

function provinceOptionsForCountry(country: string) {
  switch (country) {
    case 'CA':
      return canadianProvinces;
    case 'US':
      return usStates;
    default:
      return [];
  }
}

function countryNameFromCode(countryCode: string): string | undefined {
  return countries.find((i) => i.value === countryCode)?.label;
}

function provinceNameFromCode(countryCode: string, provinceCode: string): string | undefined {
  const provinces = provinceOptionsForCountry(countryCode);

  return provinces.find((i) => i.value === provinceCode)?.label;
}
