import { Icon, IconProps } from './Icon';

export const InfoIcon = ({ className, size, ...spreadProps }: IconProps): JSX.Element => (
  <Icon {...spreadProps} size={size} className={className} viewBox="0 0 20 20">
    <path
      d="M9.08337 5.41658H10.9167V7.24992H9.08337V5.41658ZM9.08337 9.08325H10.9167V14.5833H9.08337V9.08325ZM10 0.833252C4.94004 0.833252 0.833374 4.93992 0.833374 9.99992C0.833374 15.0599 4.94004 19.1666 10 19.1666C15.06 19.1666 19.1667 15.0599 19.1667 9.99992C19.1667 4.93992 15.06 0.833252 10 0.833252ZM10 17.3333C5.95754 17.3333 2.66671 14.0424 2.66671 9.99992C2.66671 5.95742 5.95754 2.66659 10 2.66659C14.0425 2.66659 17.3334 5.95742 17.3334 9.99992C17.3334 14.0424 14.0425 17.3333 10 17.3333Z"
      fill="#0288D1"
    />
  </Icon>
);
