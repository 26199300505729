import clsx from 'clsx';
import { useMemo } from 'react';

import styles from './ProductThumbnail.module.scss';

interface ProductThumbnailProps {
  alt: string;
  children?: React.ReactNode;
  className?: string;
  containerClass?: string;
  size: number;
  src: string;
}

export const ProductThumbnail = ({
  alt,
  children,
  className,
  containerClass,
  size,
  src,
}: ProductThumbnailProps) => {
  const containerStyle = useMemo(
    () => ({
      height: size,
      width: size,
    }),
    [size]
  );

  return (
    <div className={clsx(styles.container, containerClass)}>
      <div className={clsx(styles.thumbnailWrapper, className)} style={containerStyle}>
        <img src={src} alt={alt} />
      </div>

      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};

ProductThumbnail.defaultProps = {
  size: 124,
};
