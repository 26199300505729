/**
 * Used to suppress the outline on clickable elements when clicked, but not when tabbed to
 */
export const outlineSuppressionHandlers = {
  onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.style.outline = 'none';
    e.currentTarget.style.boxShadow = 'none';
  },

  onBlur: (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.style.outline = '';
    e.currentTarget.style.boxShadow = '';
  },
};
