// TODO: We could also have a FormattedPrice component that automatically pulls
// in the locale using the useTranslation hook and uses this function to format
// prices.

export type MoneyBag = {
  amount: number;
  currency: string;
};

export const formatPrice = (moneybag: MoneyBag, decimalPlaces = 2, locale?: string) => {
  // TODO: We should potentially use 'fr-ca' for french, instead of 'fr'.
  let formattedLocale = 'en-ca';
  if (locale?.toLowerCase().startsWith('fr')) {
    formattedLocale = 'fr-ca';
  }

  return moneybag.amount.toLocaleString(formattedLocale, {
    style: 'currency',
    currency: moneybag.currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};
