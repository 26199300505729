import { Icon, IconProps } from './Icon';

export const IconCheck = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 16 11">
    <path
      d="M1 6.48021L5 10.3202L15 0.720215"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      fill="none"
    />
  </Icon>
);
