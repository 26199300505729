import { useTranslation } from 'react-i18next';

import { useStoreConfig } from 'app/contexts';
import { useReturnFlow, useViewportHeight } from 'app/hooks';

import styles from './OrderLookupLayout.module.scss';

import { Chip } from '../../ui/Chip';

interface OrderLookupLayoutProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export const OrderLookupLayout = ({ children, title }: OrderLookupLayoutProps) => {
  const { boxStyle } = useViewportHeight();
  const { themeConfig } = useStoreConfig();
  const { isAdmin } = useReturnFlow();
  const { t } = useTranslation();

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.content}>
        {themeConfig?.logoImageUrl && (
          <img
            src={themeConfig?.logoImageUrl}
            alt={themeConfig.name}
            className={isAdmin ? styles.logoWithoutMarginBottom : styles.logo}
          />
        )}

        {isAdmin ? (
          <div className={styles.flexbox}>
            <Chip text={t('common.adminMode')} color={'info'} />
          </div>
        ) : null}

        <h1 data-testid="order-lookup-layout-title" className={styles.title}>
          {title}
        </h1>
        {children}
      </div>

      <div className={styles.storeBackground} />
    </div>
  );
};
