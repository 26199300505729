import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useStoreConfig } from 'app/contexts';
import { useReturnFlow } from 'app/hooks';
import { ConfirmationLayout } from 'layouts';

import styles from './DropOffConfirmation.module.scss';

export const DropOffConfirmation = () => {
  const { t, i18n } = useTranslation();
  const { storeConfig } = useStoreConfig();
  const { createdReturnCase } = useReturnFlow();
  const returnCaseQrCodeBase64 = createdReturnCase?.returnCase?.qrCode;

  const nextSteps = useMemo(
    () => [
      t('returnConfirmationPage.dropOff.dropoffItems'),
      t('returnConfirmationPage.returnProcessed'),
      t('returnConfirmationPage.receiveRefund'),
    ],
    [t]
  );
  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  return (
    <ConfirmationLayout
      title={t('returnConfirmationPage.title')}
      subTitle={t('returnConfirmationPage.subTitle')}
      hero={
        <div className={styles.hero}>
          <div>
            <h2>{t('returnConfirmationPage.dropOff.yourQrCode')}</h2>
            <p>{t('returnConfirmationPage.dropOff.qrCodeInstructions')}</p>
          </div>

          <div className={styles.qrCodeWrapper}>
            <img src={`data:image/png;base64, ${returnCaseQrCodeBase64}`} alt="qrCode" />
          </div>
        </div>
      }
      nextSteps={nextSteps}
    >
      <ol className={styles.nextSteps}>
        <li>
          <h3>{t('returnConfirmationPage.dropOff.dropoffItems')}</h3>
          {t('returnConfirmationPage.dropOff.dropoffItemsInstructions')}
        </li>
        <li>
          <h3>{t('returnConfirmationPage.dropOff.showQrCode')}</h3>
          {t('returnConfirmationPage.dropOff.showQrCodeInstructions')}
        </li>
        <li>
          <h3> {t('returnConfirmationPage.dropOff.itemsProcessed')}</h3>
          {t('returnConfirmationPage.dropOff.itemsProcessedInfo')}
        </li>
        <li>
          <h3> {t('returnConfirmationPage.dropOff.receiveRefundOrCredit')}</h3>
          {t('returnConfirmationPage.dropOff.receiveRefundOrCreditInstructions')}
        </li>
      </ol>

      <footer className={styles.actions}>
        <a
          href={
            i18n.resolvedLanguage.startsWith('fr')
              ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
              : englishShoppingUrl
          }
          className={styles.backToShopping}
        >
          {t('returnConfirmationPage.backToShopping')}
        </a>

        <Link to="/" className={styles.createAnotherReturn}>
          {t('returnConfirmationPage.submitAnotherReturn')}
        </Link>
      </footer>
    </ConfirmationLayout>
  );
};
