import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { LanguageSelector, ReturnBearLogo } from 'app/components';
import { useStoreConfig } from 'app/contexts';
import { IconArrowUpRight } from 'icons';

import styles from './Footer.module.scss';

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const { themeConfig, storeConfig } = useStoreConfig();

  const hasLogoUrl = !!themeConfig?.logoImageUrl;
  const storeNameOrLogo = hasLogoUrl ? (
    <img src={themeConfig?.logoImageUrl} alt={themeConfig.name} className={styles.logo} />
  ) : (
    themeConfig?.name
  );

  const englishPolicyUrl = storeConfig?.returnPolicyUrl_en || storeConfig?.returnPolicyUrl;
  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.leftColumn}>
          <div className={clsx(styles.storeName, !hasLogoUrl && styles.noLogo)}>
            {storeNameOrLogo}
          </div>

          <div className={styles.footerLinkWrapper}>
            <a
              href={
                i18n.resolvedLanguage.startsWith('fr')
                  ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
                  : englishShoppingUrl
              }
              className={styles.footerLink}
              target="_rbShop"
            >
              {t('footer.shop')} <IconArrowUpRight className={styles.footerLinkArrow} />
            </a>
          </div>

          <div className={styles.footerLinkWrapper}>
            <a
              href={
                i18n.resolvedLanguage.startsWith('fr')
                  ? storeConfig?.returnPolicyUrl_fr || englishPolicyUrl
                  : englishPolicyUrl
              }
              className={styles.footerLink}
              target="_rbStoreReturnPolicy"
            >
              {t('footer.returnPolicy')} <IconArrowUpRight className={styles.footerLinkArrow} />
            </a>
          </div>
        </div>

        <div className={styles.centerLinks}>
          <div className={styles.poweredByReturnBear}>
            <ReturnBearLogo size={30} className={styles.rbLogo} />
          </div>

          <div className={styles.footerLinkWrapper}>
            <a
              href="https://www.returnbear.com/privacy-policy"
              className={styles.footerLink}
              target="_rbPrivacyPolicy"
            >
              {t('footer.privacyPolicy')} <IconArrowUpRight className={styles.footerLinkArrow} />
            </a>
          </div>
        </div>

        <div className={styles.rightLinks}>
          <LanguageSelector />
        </div>
      </div>
    </footer>
  );
};
