import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useStoreConfig } from 'app/contexts';
import { ReturnMethods } from 'app/constants';
import { ConfirmationHeader } from 'app/components';

import confirmationImage from './checkmarkInCircle.png';
import styles from './ManualApprovalConfirmation.module.scss';

interface ManualApprovalConfirmationProps {
  /**
   * The return method to show specific instructions for.
   */
  returnMethod?: ReturnMethods;
}

export const ManualApprovalConfirmation: React.FC<ManualApprovalConfirmationProps> = ({
  returnMethod,
}) => {
  const { storeConfig } = useStoreConfig();
  const { t, i18n } = useTranslation();
  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  return (
    <div className={styles.container}>
      <ConfirmationHeader />
      <img
        src={confirmationImage}
        alt={t('returnConfirmationPage.manuapApproval.imageAltText')}
        className={styles.confirmationImage}
      />

      <h2>{t('returnConfirmationPage.manualApproval.title')}</h2>
      <p>
        {returnMethod === ReturnMethods.MailIn
          ? t('returnConfirmationPage.manualApproval.mailInInstructions', {
              approvalDays: storeConfig?.approvalDays,
            })
          : t('returnConfirmationPage.manualApproval.dropOffInstructions', {
              approvalDays: storeConfig?.approvalDays,
            })}
      </p>

      <footer className={styles.actions}>
        <a
          href={
            i18n.resolvedLanguage.startsWith('fr')
              ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
              : englishShoppingUrl
          }
          className={styles.backToShopping}
        >
          {t('returnConfirmationPage.backToShopping')}
        </a>

        <Link to="/" className={styles.createAnotherReturn}>
          {t('returnConfirmationPage.submitAnotherReturn')}
        </Link>
      </footer>
    </div>
  );
};
