import { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

import { Coordinate } from 'app/types';
import { GOOGLE_MAPS_API_KEY } from 'app/environment';
import { googleLibraries } from 'app/constants';

/**
 * A hook that returns the coordinates for a given address
 */
export const useGeocodeLocation = (address: string) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleLibraries,
  });
  const [isGeolocating, setIsGeolocating] = useState(false);
  const [location, setLocation] = useState<Coordinate | null>(null);

  // Geocode the inputted address using the Google Maps JS Geocoder API
  useEffect(() => {
    // Exit early if the google maps script isn't loaded or no address is
    // provided
    if (!isLoaded || !address) {
      return;
    }

    // Note: Using the geocode URL directly isn't allowed if the API key is
    // restricted to HTTP Referrers (AKA web sites). The geocode URL looked
    // something like this:
    // https://maps.googleapis.com/maps/api/geocode/json?key=123&address=
    // Instead of using that URL, we use the Google Maps JS Geocoding API.
    const geocoder = new google.maps.Geocoder();

    setIsGeolocating(true);
    geocoder.geocode({ address }, function (results, status) {
      setIsGeolocating(false);
      if (status == 'OK') {
        const coords = results?.[0]?.geometry?.location;
        if (coords) {
          setLocation({ lat: coords.lat(), lng: coords.lng() });
        }
      }
    });
  }, [isLoaded, address]);

  return {
    isGeolocating,
    location,
  };
};
