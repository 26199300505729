import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { useStoreOrder } from 'app/contexts';
import { NON_PILOT_STORE_LOCATIONS } from 'app/environment';
import { Coordinate, DropoffLocation } from 'app/types';
import { GetLocationsDocument } from 'generated/graphql';
import { getAddressForGeocoding } from 'utils';
import { notEmpty } from 'utils/arrays';

import { useDropoffDistances } from './useDropoffDistances';
import { useGeocodeLocation } from './useGeocodeLocation';

// Prevent infinite loop when there aren't any drop off locations by using an
// empty array reference that doesn't change.
const emptyDropoffLocations: DropoffLocation[] = [];

interface UseDropoffLocations {
  /**
   * The starting location as an address to calculate distances to drop off
   * locations.
   * Example: "123 Fake Street, Toronto, ON"
   */
  startingLocation?: Coordinate | null;
}

/**
 * Hook the provides logic for fetching ReturnBear dropoff locations
 *
 * This hook also handles fetching "straight line" distances from Google Maps
 * API, and if successful will augment the dropoff locations with that data.
 *
 * @returns DropoffLocation[]
 */
export const useDropoffLocations = ({ startingLocation }: UseDropoffLocations = {}) => {
  const { storeOrder } = useStoreOrder();

  const defaultStartingLocation = useMemo(() => {
    return getAddressForGeocoding(storeOrder?.shippingAddress);
  }, [storeOrder]);
  const { location: defaultStartingLocationCoordinates, isGeolocating } =
    useGeocodeLocation(defaultStartingLocation);
  const storeUUID = storeOrder?.storeUid;

  const [getLocs, { data: dropoffLocationsData, loading: dropoffLocationsDataLoading, ...query }] =
    useLazyQuery(GetLocationsDocument);
  useEffect(() => {
    if (storeUUID != null) {
      getLocs({ variables: { storeUid: storeUUID } });
    }
  }, [getLocs, storeUUID]);

  // Memoize originalDropoffLocations because it caused a re-rendering issue
  // wherever this useDropoffLocations hook was used (DropOffLocationsModal,
  // Review Page, etc.), and would possibly crash the map after a certain amount
  // of time (Also caused rendering/animation issues and a broken text field).
  const originalDropoffLocations: DropoffLocation[] = useMemo(
    () => dropoffLocationsData?.getLocations?.filter(notEmpty) ?? emptyDropoffLocations,
    [dropoffLocationsData?.getLocations]
  );

  const originLocation = startingLocation || defaultStartingLocationCoordinates;

  const dropoffLocationsWithDistance = useDropoffDistances({
    dropoffLocations: originalDropoffLocations,
    startingLocation: originLocation,
  });

  // Exclude non-pilot locations that may already be active
  const nonPilotStoreLocations = useMemo(
    () =>
      NON_PILOT_STORE_LOCATIONS.filter((nonPilotLocation) => {
        return !originalDropoffLocations.find(
          (activeLocation) =>
            activeLocation.coordinates?.lat === nonPilotLocation.coordinates?.lat &&
            activeLocation.coordinates?.lng === nonPilotLocation.coordinates?.lng
        );
      }),
    [originalDropoffLocations]
  );

  const nonPilotStoreLocationsWithDistance = useDropoffDistances({
    dropoffLocations: nonPilotStoreLocations,
    startingLocation: originLocation,
  });

  const closestNonPilotStoreDistance =
    nonPilotStoreLocationsWithDistance?.[0]?.distanceInMetres ?? Number.MAX_SAFE_INTEGER;
  const closestDropOffDistance = dropoffLocationsWithDistance?.[0]?.distanceInMetres ?? 0;
  const isCloserToNonPilotStore = closestNonPilotStoreDistance < closestDropOffDistance;

  // Always prefer showing users locations with distances over the original
  // locations (without distances)
  const returnedLocations = dropoffLocationsWithDistance?.length
    ? dropoffLocationsWithDistance
    : originalDropoffLocations;

  const loading = !storeUUID || dropoffLocationsDataLoading;

  return {
    ...query,
    loading, // Maintains compatibility with non storeUUID dependent hook
    dropoffLocations: returnedLocations,
    isGeolocating,
    isCloserToNonPilotStore,
    defaultStartingLocationCoordinates,
  };
};
