import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { getStoreOrderWithLookupParamsGql } from 'app/graphql/queries';
import {
  GetStoreOrderWithLookupParamsQueryResult,
  GetStoreOrderWithLookupParamsQueryVariables,
  OrderLineItemLineType,
  OrderLookupParamInput,
  StoreOrderInterface,
  StoreOrderLineItem,
} from 'app/types';
import { useStoreConfig } from 'app/contexts';
import { getItemPayoutRuleOutcome, hasExceededReturnWindow, getItemPayoutMethods } from 'utils';
import { useReturnFlow } from 'app/hooks';

import { StoreOrderContextInterface } from './';

/**
 * Provides context values for the StoreOrderProvider component
 *
 * This component handles loading the storeOrder object from the server. The storeOrder
 * plus meta data can be used by all children of the provider using the `useStoreOrder()` hook
 *
 * @param orderLookupInput The array of objects that contains variables such as the
 * email, order_number, or postal_code to send to API
 */
export const useStoreOrderProvider = (
  orderLookupInput?: OrderLookupParamInput[]
): StoreOrderContextInterface => {
  const { t, i18n } = useTranslation();
  const [storeOrder, setStoreOrder] = useState<StoreOrderInterface | null>(null);
  const { storeConfig } = useStoreConfig();
  const { isAdmin } = useReturnFlow();

  const { data, loading } = useQuery<
    GetStoreOrderWithLookupParamsQueryResult,
    GetStoreOrderWithLookupParamsQueryVariables
  >(getStoreOrderWithLookupParamsGql, {
    variables: {
      lookupParams: orderLookupInput,
    },
    skip: !orderLookupInput || Object.values(orderLookupInput).filter(Boolean).length === 0,
  });

  useEffect(() => {
    if (data?.getStoreOrderWithLookupParams) {
      setStoreOrder(data?.getStoreOrderWithLookupParams);
    }
  }, [data]);

  // Get items with specific lineType.
  const nonReturnableItems = useMemo(() => {
    const items: StoreOrderLineItem[] = [];
    const itemsWithNonReturnableItems = storeOrder?.lineItems.filter(
      (item) =>
        item.lineType === OrderLineItemLineType.LESS_THAN_TRUCKLOAD ||
        item.lineType === OrderLineItemLineType.MAJOR_HOME_FASHION
    );

    itemsWithNonReturnableItems?.forEach((item) => {
      let refundedQty = item.refundableQuantity;
      while (refundedQty > 0) {
        items.push(item);
        refundedQty -= 1;
      }
    });
    return items;
    // Should re-render whenever the t function updates, else the translations don't update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrder, t]);

  // These items can't be returned
  const ineligibleItems = useMemo(() => {
    const items: StoreOrderLineItem[] = [];
    storeOrder?.lineItems.forEach((lineItem) => {
      // if isAdmin is true, remove any rule outcomes that would have been applied to the line item
      const ruleOutcome = isAdmin ? null : getItemPayoutRuleOutcome(lineItem);
      const noRefundQty = lineItem.refundableQuantity === 0;
      const hasReturnCase = lineItem.returnCaseExists;
      const outsideReturnQuantity =
        lineItem.quantity - lineItem.returnCaseItemsQuantity - lineItem.refundableQuantity;
      const hasOutsideReturns = outsideReturnQuantity > 0;
      const hasNoVariant = !lineItem.variant;
      const hasFulfilledDate = lineItem.fulfilledDate != null;
      const hasStore = !!lineItem.store?.uid;
      const isNonReturnable = !!nonReturnableItems.find(
        (nonReturnableItem) => nonReturnableItem.id === lineItem.id
      );
      const hasRequiresShipping = lineItem.requiresShipping ?? true;

      // take out submitted items
      const quantityToMap = lineItem.quantity - lineItem.returnCaseItemsQuantity;

      // If the line item has "MHF" or "LTL" set for lineType, skip it.
      if (isNonReturnable) {
        return;
      }

      // Items cant be refunded if they have no store
      if (!hasStore) {
        const item = { ...lineItem, disableMessage: t('errors.returnNotEligible') };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // Item has no payout methods
      if (ruleOutcome?.target.length === 0) {
        // Retailers can't set french reasons yet
        const item = {
          ...lineItem,
          disableMessage: i18n.language.startsWith('en')
            ? ruleOutcome.message.english
            : ruleOutcome.message.french,
        };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // Item has no product variant
      if (hasNoVariant && !isAdmin) {
        const item = { ...lineItem, disableMessage: t('errors.noProductVariant') };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // items cant be refunded
      if (noRefundQty && !hasReturnCase) {
        const item = { ...lineItem, disableMessage: t('errors.returnNotEligible') };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // items returned outside of returnbear
      if (hasOutsideReturns) {
        const item = { ...lineItem, disableMessage: t('errors.returnNotEligible') };

        for (let quantity = 0; quantity < outsideReturnQuantity; quantity++) {
          items.push(item);
        }
        return;
      }

      if (!hasFulfilledDate) {
        const item = { ...lineItem, disableMessage: t('errors.itemNotFulfilled') };

        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // items can't be refunded and lapsed the return window
      if (
        !isAdmin &&
        hasExceededReturnWindow(
          lineItem.fulfilledDate,
          lineItem.returnConstraint.returnWindow ?? storeConfig?.returnWindow
        ) &&
        !hasReturnCase
      ) {
        const item = {
          ...lineItem,
          disableMessage: t('errors.outsideReturnWindow', {
            returnWindow: lineItem.returnConstraint.returnWindow ?? storeConfig?.returnWindow,
          }),
        };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      const validItemPayoutMethods = getItemPayoutMethods(lineItem, storeConfig?.returnMethod);

      // No valid item payout methods
      if (validItemPayoutMethods.length === 0) {
        const item = { ...lineItem, disableMessage: t('errors.returnNotEligible') };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }

      // Item is not a physical fullfilled item and does not require shipping
      if (!hasRequiresShipping) {
        const item = { ...lineItem, disableMessage: t('errors.itemDoesNotRequireShipping') };
        for (let quantity = 0; quantity < quantityToMap; quantity++) {
          items.push(item);
        }
        return;
      }
    });
    return items;
    // Should re-render whenever the t function updates, else the translations don't update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrder, nonReturnableItems, t, isAdmin]);

  // submitted items aren't unique
  const submittedItems = useMemo(() => {
    const items: StoreOrderLineItem[] = [];
    const itemsWithReturnCase = storeOrder?.lineItems.filter((l) => l.returnCaseExists);
    // should list dupes if they've returned it more than once.
    itemsWithReturnCase?.forEach((item) => {
      let refundedQty = item.returnCaseItemsQuantity;
      while (refundedQty > 0) {
        items.push(item);
        refundedQty -= 1;
      }
    });
    return items;
    // Should re-render whenever the t function updates, else the translations don't update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrder, t]);

  // this can have overlaps with submitted items if the refund qty is positive
  const eligibleItems = useMemo(() => {
    let items: StoreOrderLineItem[] = [];

    storeOrder?.lineItems?.forEach((item) => {
      const matchedIneligibleItem = ineligibleItems.filter(
        (ineligibleItem) => ineligibleItem.id === item.id
      );

      const matchedNoReturnItem = nonReturnableItems.filter(
        (noReturnItem) => noReturnItem.id === item.id
      );

      const matchedSubmittedItems = submittedItems.filter(
        (submittedItem) => submittedItem.id === item.id
      );
      const existingItemQty =
        matchedIneligibleItem.length + matchedSubmittedItems.length + matchedNoReturnItem.length;

      let availableQty = item.quantity - existingItemQty;

      while (availableQty >= 1) {
        items.push(item);
        availableQty -= 1;
      }
    });

    // create a unique id in the case there are duplicates created from multiple quantities
    items = items.map((item, index) => {
      return {
        ...item,
        uniqueId: `${item.id}-${index}`,
        ruleOutcome: isAdmin ? null : item.ruleOutcome,
      };
    });
    return items;
  }, [storeOrder, submittedItems, ineligibleItems, nonReturnableItems, isAdmin]);

  return {
    storeOrder,
    eligibleItems,
    submittedItems,
    ineligibleItems,
    isInitializing: loading,
    orderExists: storeOrder !== null,
    nonReturnableItems,
  };
};
