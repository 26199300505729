import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { IconExternalLink } from 'icons';

import styles from './Alert.module.scss';

interface AlertProps {
  iconComponent: React.ReactNode;
  message: string;
  cta_message?: string;
  cta_link?: string;
}

const Alert = ({ iconComponent, message, cta_message, cta_link }: AlertProps) => {
  const markdownContainerRef = useRef<HTMLDivElement>(null);

  // Images need to be responsive
  useEffect(() => {
    if (markdownContainerRef.current) {
      const images = markdownContainerRef.current.querySelectorAll('img');
      images.forEach((img) => {
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
      });
    }
  }, [message]);

  return (
    <Stack
      direction={'row'}
      spacing={2}
      justifyContent={'space-between'}
      whiteSpace={'pre-line'}
      className={styles.alertContainer}
    >
      <Box>{iconComponent}</Box>
      <Grid container spacing={0}>
        <Grid xs={12} sm={cta_link ? 9 : 12} item>
          <div ref={markdownContainerRef}>
            <ReactMarkdown className={styles.alertContainerText}>{message}</ReactMarkdown>
          </div>
        </Grid>

        {cta_link && (
          <Grid xs={12} sm={3} item textAlign={'right'}>
            <a href={cta_link} target="_blank" rel="noreferrer">
              <Stack
                className={styles.alertUrlContainer}
                alignItems={'center'}
                direction="row"
                spacing={1}
              >
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  lineHeight={'16px'}
                  className={styles.url}
                >
                  {cta_message}
                  <IconExternalLink />
                </Typography>
              </Stack>
            </a>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default Alert;
