import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (isLocked: boolean) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = isLocked ? 'hidden' : originalStyle;
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isLocked]); // Empty array ensures effect is only run on mount and unmount
};
