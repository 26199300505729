import { TFunction } from 'react-i18next';

import { ShippingAddress, ReturnCaseItemStatus, ReturnReasonInterface } from 'app/types';
import { formatPrice, MoneyBag } from 'utils/number';
import { SelectItemShape } from 'ui';

/**
 * Extracts the ID portion of a Shopify product URL
 *
 * @param shopifyGid The shopify gid (e.g. "gid://shopify/Product/6965347090589")
 * @returns {string} The id portion of the
 */
export const getIdFromShopifyProductUrl = (shopifyGid: string) => {
  return shopifyGid.substring(shopifyGid.lastIndexOf('/') + 1);
};

/**
 * Creates the fee text for Product return card
 *
 * @param storeCreditFee
 * @param refundFee
 * @returns {string} The formatted fee string
 */
export const buildReturnFeeDisplay = (storeCreditFee: MoneyBag, refundFee: MoneyBag) => {
  if (storeCreditFee.amount && refundFee.amount) {
    const sortedMoneybags = [refundFee, storeCreditFee].sort((a, b) => a.amount - b.amount);
    return `${formatPrice(sortedMoneybags[0])} - ${formatPrice(sortedMoneybags[1])}`;
  }
  if (storeCreditFee.amount) {
    return `- ${formatPrice(storeCreditFee)}`;
  }
  if (refundFee.amount) {
    return `- ${formatPrice(refundFee)}`;
  }
  return '';
};

/**
 * Return a partial string representation of an address to be used for geocoding.
 * @param address An address object
 * @returns A partial string representation of an address
 */
export const getAddressForGeocoding = (address: ShippingAddress | undefined) => {
  return [address?.address1, address?.city, address?.province, address?.zipCode, address?.country]
    .filter(Boolean)
    .join(', ');
};

/**
 * Return a translation ready map of return case item statuses
 * @param TFunction
 * @returns record of labels and tfunction translations
 */
export const getReturnItemStatusDisplayValue = (t: TFunction): Record<string, string> => {
  return {
    [ReturnCaseItemStatus.NEW]: t('statusLabel.new'),
    [ReturnCaseItemStatus.APPROVED]: t('statusLabel.approved'),
    [ReturnCaseItemStatus.DENIED]: t('statusLabel.denied'),
    [ReturnCaseItemStatus.CANCELLED]: t('statusLabel.cancelled'),
    [ReturnCaseItemStatus.COMPLETED]: t('statusLabel.completed'),
    [ReturnCaseItemStatus.AWAITING_PROCESSING]: t('statusLabel.awaitingProcessing'),
    [ReturnCaseItemStatus.IN_TRANSIT_TO_HUB]: t('statusLabel.inTransitToHub'),
    [ReturnCaseItemStatus.DONATED]: t('statusLabel.donated'),
    [ReturnCaseItemStatus.ADDED_TO_BATCH]: t('statusLabel.addedToBatch'),
    [ReturnCaseItemStatus.IN_TRANSIT_TO_MERCHANT]: t('statusLabel.inTransitToMerchant'),
    [ReturnCaseItemStatus.ARRIVED_AT_MERCHANT]: t('statusLabel.arrivedAtMerchant'),
    [ReturnCaseItemStatus.DROPPED_OFF]: t('statusLabel.droppedOff'),
    [ReturnCaseItemStatus.RECEIVED]: t('statusLabel.received'),
    [ReturnCaseItemStatus.ASSESSED]: t('statusLabel.assessed'),
    [ReturnCaseItemStatus.ACCEPTED_BY_MERCHANT]: t('statusLabel.acceptedByMerchant'),
    [ReturnCaseItemStatus.DENIED_BY_MERCHANT]: t('statusLabel.deniedByMerchant'),
    [ReturnCaseItemStatus.PARTIALLY_PROCESSED_INTO_INVENTORY]: t(
      'statusLabel.partiallyProcessedIntoInventory'
    ),
    [ReturnCaseItemStatus.PROCESSED_INTO_INVENTORY]: t('statusLabel.processedIntoInventory'),
    [ReturnCaseItemStatus.REFUNDED]: t('statusLabel.refunded'),
  };
};

export const mapStoreReturnReasonsToOptions = (
  storeReturnReasons: ReturnReasonInterface[] | undefined
): SelectItemShape[] => {
  const items =
    storeReturnReasons?.map((reason) => {
      return {
        value: reason.uid,
        label: reason.text,
        disabled: false,
      };
    }) || [];
  return items;
};
