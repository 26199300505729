import { Outlet } from 'react-router-dom';

import { StoreOrderProvider } from 'app/contexts';
import { useReturnFlow } from 'app/hooks';

/**
 * Provides an Outlet component to allow for easily loading an order number and using
 * it as a context
 *
 * @see https://reactrouter.com/docs/en/v6/getting-started/concepts#outlets
 * @returns JSX.Element
 */
export const StoreOrderOutlet = () => {
  const { orderLookupInput } = useReturnFlow();

  return (
    <StoreOrderProvider orderLookupInput={orderLookupInput}>
      <Outlet />
    </StoreOrderProvider>
  );
};
