import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

import { ExchangeOptions, StoreOrderLineItem, ExchangeFormRecord } from 'app/types';
import { Select, ThreeDots } from 'ui';

import styles from './ExchangeOptionPicker.module.scss';

interface ExchangeOptionPickerProps {
  lineItem: StoreOrderLineItem;
  onChange: (value: string, key: string) => void;
  chosenOptions: ExchangeFormRecord;
  exchangeOptions: ExchangeOptions | null;
  variantsLoading: boolean;
  formikErrors?: FormikErrors<ExchangeFormRecord>;
}

export const ExchangeOptionPicker = ({
  onChange,
  chosenOptions,
  exchangeOptions,
  variantsLoading,
  formikErrors,
}: ExchangeOptionPickerProps) => {
  const { t } = useTranslation();
  const chosenOptionsKeys = Object.keys(chosenOptions);
  return (
    <>
      {variantsLoading && (
        <div className={styles.variantsLoading}>
          <ThreeDots className={styles.threeDots} />
          {t('productReturnCard.loadingOptions')}
        </div>
      )}

      {exchangeOptions &&
        Object.entries(exchangeOptions).map(([name, items], index) => {
          return (
            <Select
              required
              key={index}
              label={t('productReturnCard.whatVariant', { variantName: name.toLocaleLowerCase() })}
              items={items}
              className={styles.exchangeOptionDropdown}
              buttonText={t('common.select')}
              onChange={(selectValue) => onChange(selectValue, name)}
              value={chosenOptions[name]}
              error={formikErrors?.[name]}
              // don't disable the first select
              // disable the others if no value in the previous
              // this simplifies the variant dropdowns
              disabled={!chosenOptions[chosenOptionsKeys[index - 1]] && index !== 0}
            />
          );
        })}
    </>
  );
};
