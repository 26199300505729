import { createContext, useContext } from 'react';

import { OrderLookupParamInput, StoreOrderInterface, StoreOrderLineItem } from 'app/types';

import { useStoreOrderProvider } from './useStoreOrderProvider';

export interface StoreOrderContextInterface {
  storeOrder: StoreOrderInterface | null;
  isInitializing: boolean;
  orderExists: boolean;
  eligibleItems: StoreOrderLineItem[];
  ineligibleItems: StoreOrderLineItem[];
  submittedItems: StoreOrderLineItem[];
  nonReturnableItems: StoreOrderLineItem[];
}

const StoreOrderContext = createContext<StoreOrderContextInterface>({
  storeOrder: null,
  isInitializing: true,
  orderExists: false,
  eligibleItems: [],
  ineligibleItems: [],
  submittedItems: [],
  nonReturnableItems: [],
});

interface StoreOrderProviderProps {
  children: React.ReactNode;
  orderLookupInput?: OrderLookupParamInput[];
}

/**
 * Provides a context for easily loading a storeOrder object
 */
export const StoreOrderProvider = ({
  children,
  orderLookupInput,
}: StoreOrderProviderProps): JSX.Element => (
  <StoreOrderContext.Provider value={useStoreOrderProvider(orderLookupInput)}>
    {children}
  </StoreOrderContext.Provider>
);

/**
 * Returns the currently loaded storeOrder object, if it exists
 *
 * @returns StoreOrderContext
 */
export const useStoreOrder = () => useContext(StoreOrderContext);
