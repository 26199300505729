import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { googleLibraries } from 'app/constants';
import { GOOGLE_MAPS_API_KEY } from 'app/environment';
import { Coordinate, DropoffLocation } from 'app/types';

interface useDropoffDistancesOptions {
  dropoffLocations: DropoffLocation[];
  startingLocation: Coordinate | null;
}

/**
 * A hook that handles loading distance data from Google Maps API for ReturnBear dropoff locations
 */
export const useDropoffDistances = ({
  dropoffLocations,
  startingLocation,
}: useDropoffDistancesOptions) => {
  const { i18n } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleLibraries,
  });
  const [locationsWithDistance, setLocationsWithDistance] = useState<DropoffLocation[] | null>(
    null
  );

  useEffect(() => {
    if (!isLoaded || !startingLocation) {
      // Google Maps script not loaded yet
      return;
    }

    const startingLocationLatLong = new google.maps.LatLng(
      startingLocation.lat,
      startingLocation.lng
    );

    const dropoffLocationsLatLongs = dropoffLocations
      .map((location) => {
        if (location.coordinates?.lat == null || location.coordinates?.lng == null) {
          return {
            ...location,
            distance: '',
            distanceInMetres: 0,
          };
        }

        const dropoffLocationLatLong = new google.maps.LatLng(
          location.coordinates?.lat,
          location.coordinates?.lng
        );

        const calculatedDistanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(
          startingLocationLatLong,
          dropoffLocationLatLong
        );

        const calculatedDistanceInKilometers = calculatedDistanceInMeters / 1000;

        const formattedCalculatedDistance = Intl.NumberFormat(i18n.language, {
          // Only show decimals if calculcated distance is 25 KM or lower, and
          // divide by 1000 to convert from meters to kilometers.
          maximumFractionDigits: calculatedDistanceInKilometers > 25 ? 0 : 1,
          style: 'unit',
          unit: 'kilometer',
        }).format(calculatedDistanceInKilometers);

        return {
          ...location,
          distance: formattedCalculatedDistance,
          distanceInMetres: calculatedDistanceInMeters,
        };
      })
      .sort((a, b) =>
        (a.distanceInMetres || a.distanceInMetres === 0) &&
        (b.distanceInMetres || b.distanceInMetres === 0)
          ? a.distanceInMetres - b.distanceInMetres
          : 0
      );

    setLocationsWithDistance(dropoffLocationsLatLongs);
  }, [isLoaded, dropoffLocations, startingLocation, i18n.language]);

  return locationsWithDistance;
};
