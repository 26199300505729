import type { Theme } from '@mui/material/styles';

// XL buttons:
// https://www.figma.com/file/r9k8Vwb0HaYJYVimNR4tCR/MUI-for-Figma-Material-Pro-v5.9.0?node-id=6543%3A36744

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}

export const components: Partial<Theme['components']> = {
  MuiButton: {
    variants: [
      {
        props: { size: 'xlarge' },
        style: ({ theme }) => ({
          padding: '14px 22px',
          fontSize: theme.typography.pxToRem(18),
        }),
      },
      {
        props: { size: 'xlarge', variant: 'text' },
        style: { padding: '14px 11px' },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
          paddingInline: theme.spacing(4),
        },
      }),
    },
  },
};
