import { Icon, IconProps } from './Icon';

export const IconChevronDown = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 12 8">
    <path
      d="M1 1L6 7L11 0.999999"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
);
