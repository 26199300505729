import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStoreConfig } from 'app/contexts';
import { ErrorLayout } from 'layouts';

import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
  const { storeConfig, themeConfig } = useStoreConfig();
  const { t, i18n } = useTranslation();
  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  return (
    <ErrorLayout title={t('errorPage.title')}>
      {t('errorPage.generalErrorMessage')}
      <div className={styles.actions}>
        <Link to="/" className={styles.buttonLink}>
          {t('errorPage.createReturn')}
        </Link>

        <a
          href={
            i18n.resolvedLanguage.startsWith('fr')
              ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
              : englishShoppingUrl
          }
          className={styles.shoppingLink}
        >
          {t('errorPage.backToShopping', { storeName: themeConfig?.name })}
        </a>
      </div>
    </ErrorLayout>
  );
};
