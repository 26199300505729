import { ApolloError, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

import { useStoreConfig } from 'app/contexts';
import { getStoreOrderWithLookupParamsGql } from 'app/graphql/queries';
import { useAnalytics, useReturnFlow, useScrollToTopOnMount } from 'app/hooks';
import { GetStoreOrderWithLookupParamsQueryVariables, OrderLookupParamInput } from 'app/types';
import { LoadingScreen, OrderLookupLayout } from 'layouts';
import { Button, ErrorMessage, Placeholder, TextInput } from 'ui';
import { formatApolloError } from 'utils';
import { GetStoreOrderWithLookupParamsQuery } from 'generated/graphql';
import { useInGeo } from 'app/hooks/useInGeo';

import styles from './OrderLookup.module.scss';

interface FormErrors {
  email: string;
  order_number: string;
  postal_code: string;
}

export const OrderLookup = () => {
  const { saveAndNavigate, orderLookupInput, isAdmin, save } = useReturnFlow();
  const { t, i18n } = useTranslation();
  const routerNavigate = useNavigate();
  const { storeConfig, isInitializing, themeConfig } = useStoreConfig();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasSearchedFromQueryParams, setHasSearchedFromQueryParams] = useState(false);
  const analytics = useAnalytics();
  const [searchParams] = useSearchParams();
  const inGeo = useInGeo();
  const [cookies] = useCookies();
  const cookieFromRetailerAdmin = cookies['consumer_portal_admin'] ?? '';

  useEffect(() => {
    if (isAdmin || searchParams.get('admin_exception_case') === 'true') {
      save({
        isAdmin: true,
      });
    } else {
      save({
        isAdmin: false,
      });
    }
    // We only run this hook on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll to top to hide footer.
  useScrollToTopOnMount();

  const [loadOrderDetail, { loading: loadingSearchOrder }] = useLazyQuery<
    GetStoreOrderWithLookupParamsQuery,
    GetStoreOrderWithLookupParamsQueryVariables
  >(getStoreOrderWithLookupParamsGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const initialValues = useMemo(() => {
    if (!storeConfig?.orderLookupParams) {
      return {};
    }

    if (orderLookupInput) {
      return orderLookupInput.reduce<Record<string, string | undefined>>((prev, current) => {
        prev[current.field] = current.value;
        return prev;
      }, {});
    }
    return storeConfig?.orderLookupParams?.reduce<Record<string, string | undefined>>(
      (prev, current) => {
        const searchParamValue = searchParams.get(current);
        if (searchParamValue) {
          prev[current] = searchParamValue;
        }
        return prev;
      },
      {}
    );
  }, [storeConfig?.orderLookupParams, searchParams, orderLookupInput]);

  const hasPostalCodeInLookupParams = storeConfig?.orderLookupParams?.includes('postal_code');
  const hasOrderNumberInLookupParams = storeConfig?.orderLookupParams?.includes('order_number');
  const hasEmailInLookupParams = storeConfig?.orderLookupParams?.includes('email');

  const listOfQueryParams = useMemo(() => {
    return storeConfig?.orderLookupParams
      ?.map((lookupParam) => {
        return searchParams.get(lookupParam);
      })
      .filter(Boolean);
  }, [searchParams, storeConfig?.orderLookupParams]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate: ({ email, order_number }) => {
      const errors: Partial<FormErrors> = {};

      if (hasEmailInLookupParams && (!email || !validator.isEmail(email?.trim()))) {
        errors.email = t('orderLookupPage.invalidEmail');
      }

      if (hasOrderNumberInLookupParams && order_number?.toString().trim().length === 0) {
        errors.order_number = t('orderLookupPage.orderNumberRequired');
      }

      return errors;
    },
    onSubmit: async (values) => {
      setErrorMessage('');
      if (values.order_number) {
        analytics.searchOrder(values.order_number?.toString().trim().toLowerCase());
      }

      const lookupInput =
        storeConfig?.orderLookupParams?.map<OrderLookupParamInput>((param) => {
          const orderLookupParamInput: OrderLookupParamInput = {
            field: param,
            value: values[param] || '',
          };
          return orderLookupParamInput;
        }) || [];

      try {
        const { data, error } = await loadOrderDetail({
          variables: {
            lookupParams: lookupInput,
          },
        });

        if (data?.getStoreOrderWithLookupParams?.orderId) {
          const storeOrder = data.getStoreOrderWithLookupParams;
          const { shippingAddress } = storeOrder;

          const hasRefundableItems =
            storeOrder?.lineItems &&
            storeOrder?.lineItems.length &&
            storeOrder?.lineItems?.some((item: any) => !item.isCompletedForConsumer);

          let cognitoTokenFromRetailerAdmin = '';

          if (cookieFromRetailerAdmin) {
            cognitoTokenFromRetailerAdmin = `Bearer ${cookieFromRetailerAdmin}`;
          }

          if (!hasRefundableItems) {
            setErrorMessage(t('orderLookupPage.alreadyRefunded'));
          }

          // Clear any existing query params before submitting or else if the
          // user clicks back on the browser, an infinite loop of starting the return
          // occurs because the query params (email, order number etc...) will
          // still exist

          if (listOfQueryParams && listOfQueryParams?.length > 0) {
            // Make sure we're preserving the "in_geo" query string,
            // so it's propagated later to the saveAndNavigate() method.
            if (!inGeo) {
              routerNavigate('/?in_geo=false', {
                replace: true,
              });
            } else {
              routerNavigate('/', {
                replace: true,
              });
            }
          }

          if (!shippingAddress) {
            // TODO: add new string
            setErrorMessage('No shipping address found.');
            return;
          }

          const newUUID = uuidv4();
          saveAndNavigate('/return/select-items', {
            ...formik.values,
            orderLookupInput: lookupInput,
            sessionId: newUUID,
            shippingAddress,
            cognitoTokenFromRetailerAdmin,
          });
        } else {
          setErrorMessage(t('orderLookupPage.orderNotFound'));
        }

        if (error) {
          if (error instanceof ApolloError) {
            const errorMessage = formatApolloError(error, t)?.message;
            setErrorMessage(errorMessage || t('orderLookupPage.orderNotFound'));
            return;
          }
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = formatApolloError(error, t)?.message;
          setErrorMessage(errorMessage || t('orderLookupPage.orderNotFound'));
          return;
        }
        setErrorMessage(t('orderLookupPage.orderNotFound'));
      }
    },
  });

  const isFormValid = !Object.keys(formik.errors).length;
  const submitForm = formik.submitForm;

  useEffect(() => {
    const allInitialValues = Object.values(formik.initialValues);
    const hasAllInitialValues =
      allInitialValues.length > 0 &&
      allInitialValues.filter(Boolean).length === allInitialValues.length;

    if (
      listOfQueryParams &&
      listOfQueryParams?.length > 0 &&
      !hasSearchedFromQueryParams &&
      isFormValid &&
      hasAllInitialValues &&
      !orderLookupInput?.length
    ) {
      formik.submitForm();
      setHasSearchedFromQueryParams(true);
    }
  }, [
    hasSearchedFromQueryParams,
    isFormValid,
    formik,
    orderLookupInput,
    listOfQueryParams,
    submitForm,
  ]);

  const activeFormFields = Object.keys(formik.values);

  const areFormFieldsFilled = storeConfig?.orderLookupParams?.every((param) => {
    return activeFormFields.includes(param) && formik.values[param]?.length;
  });

  const isButtonDisabled =
    // Disabled if there is no user input or form errors
    !areFormFieldsFilled ||
    Object.values(formik.errors).length > 0 ||
    // Disabled while order is loading
    loadingSearchOrder;

  // Show a full page <LoadingScreen /> if the user begins searching for an order
  // with login details taken from query params, so they do not see the order
  // lookup page at all.
  if (
    storeConfig?.orderLookupParams &&
    listOfQueryParams &&
    listOfQueryParams?.length > 0 &&
    loadingSearchOrder
  ) {
    return <LoadingScreen />;
  }

  const englishWelcomeMessage =
    themeConfig?.welcomeMessage_en || themeConfig?.welcomeMessage || t('orderLookupPage.title');

  return (
    <OrderLookupLayout
      title={
        i18n.resolvedLanguage.startsWith('fr')
          ? themeConfig?.welcomeMessage_fr || englishWelcomeMessage
          : englishWelcomeMessage
      }
    >
      <form data-testid="order-lookup-form" onSubmit={formik.handleSubmit} className={styles.form}>
        <ErrorMessage isVisible={errorMessage !== ''}>{errorMessage}</ErrorMessage>

        {hasEmailInLookupParams && (
          <TextInput
            label={t('orderLookupPage.emailAddress')}
            type="email"
            name="email"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            className={styles.textInput}
            // We actually want to autofocus on the email field, so we disable the lint check
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
        )}

        {hasPostalCodeInLookupParams && (
          <TextInput
            label={t('common.postalCode')}
            type="text"
            name="postal_code"
            value={formik.values.postal_code || ''}
            onChange={formik.handleChange}
            className={styles.textInput}
            // When the email field doesn't exist, we want to autofocus on the
            // postal_code field, so we disable the lint check.
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
        )}

        {hasOrderNumberInLookupParams && (
          <TextInput
            label={t('orderLookupPage.orderNumber')}
            type="text"
            name="order_number"
            value={formik.values.order_number || ''}
            onChange={formik.handleChange}
            className={styles.textInput}
            tooltip={t('orderLookupPage.orderNumberTooltip')}
            a11yText={t('orderLookupPage.orderNumberTooltipLabel')}
          />
        )}

        <Button
          type="submit"
          className={styles.startReturnButton}
          disabled={isButtonDisabled}
          isWorking={loadingSearchOrder}
        >
          {t('orderLookupPage.startReturn')}
        </Button>

        <div className={styles.questionsLink}>
          {isInitializing ? (
            <>
              <Placeholder width={110} />
              <Placeholder width={170} className={styles.emailPlaceholder} />
            </>
          ) : (
            <>
              {t('orderLookupPage.haveQuestions')}
              <br />
              <a href={`mailto:${storeConfig?.customerServiceEmail}`}>
                {storeConfig?.customerServiceEmail}
              </a>
            </>
          )}
        </div>
      </form>
    </OrderLookupLayout>
  );
};
