import clsx from 'clsx';
import React from 'react';

import styles from './SelectableItem.module.scss';

interface SelectableItemProps {
  /**
   * The id to use for the HTML element.
   */
  id?: string;

  children: React.ReactNode;

  /**
   * If specified appends an additional className to the outer container of the component
   */
  className?: string;

  /**
   * If true, renders the item with a distinct visual style
   *
   * Using this option also prevents the item from being tabbed to
   */
  isSelected?: boolean;

  /**
   * Specifies a callback function to be called whenever the container is clicked or selected by keyboard
   */
  onClick?: () => void;
}

export const SelectableItem = ({
  className,
  children,
  isSelected,
  onClick,
  ...spreadProps
}: SelectableItemProps) => {
  const isClickable = onClick !== undefined;
  let tabIndex;

  if (isClickable && !isSelected) {
    tabIndex = 0;
  }

  /**
   * Handles what happens when the user triggers a keyPress while the item is focused
   *
   * Whenever the Spacebar or Enter key are pressed the onClick should be triggered for accessbility
   *
   * @param e React.KeyboardEvent
   */
  const handleKeyPress = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        if (typeof onClick === 'function' && !isSelected) {
          onClick();
        }
        break;
      default:
        // Nothing
        break;
    }
  };

  return (
    <div
      {...spreadProps}
      role="button"
      onClick={onClick}
      tabIndex={tabIndex}
      className={clsx(
        styles.container,
        className,
        isSelected && styles.selected,
        isClickable && styles.selectable
      )}
      onKeyPress={handleKeyPress}
    >
      {children}
    </div>
  );
};

SelectableItem.defaultProps = {
  isSelected: false,
};
