import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { StoreTheme } from 'app/components';
import i18n from 'app/locales';
import { CustomThemeProvider } from 'theme';

import {
  SegmentContextProvider,
  StoreConfigProvider,
  AddressInfoContextProvider,
} from './contexts';
import apolloClient from './graphql/client';
import Routes from './routes';

const App = () => (
  <CookiesProvider>
    <SegmentContextProvider>
      <AddressInfoContextProvider>
        <ApolloProvider client={apolloClient}>
          <StoreConfigProvider>
            <CustomThemeProvider>
              <CssBaseline />
              <I18nextProvider i18n={i18n}>
                <StoreTheme>
                  <Router>
                    <Routes />
                  </Router>
                </StoreTheme>
              </I18nextProvider>
            </CustomThemeProvider>
          </StoreConfigProvider>
        </ApolloProvider>
      </AddressInfoContextProvider>
    </SegmentContextProvider>
  </CookiesProvider>
);

export default App;
