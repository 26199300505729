import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProductCard } from 'app/components';
import {
  ExchangeOrReturn,
  MAIN_BAY_FRENCH_URL,
  MAIN_BAY_URL,
  SUPPORT_URL_EN,
  SUPPORT_URL_FR,
} from 'app/constants';
import { AddressContext, useStoreConfig, useStoreOrder } from 'app/contexts';
import {
  useAnalytics,
  useRedirectToErrorPage,
  useReturnFlow,
  useScrollToTopOnMount,
} from 'app/hooks';
import { PolicyRule, ReturnCaseItemStatus, ReturnDataState } from 'app/types';
import { StoreSellerTypeChoice } from 'generated/graphql';
import { InfoIcon } from 'icons/IconInfo';
import { DefaultAppPage } from 'layouts';
import { Button } from 'ui';
import Alert from 'ui/Alert';
import {
  getRefundOnlyItems,
  getReturnItemStatusDisplayValue,
  getStoreCreditOnlyItems,
  getTotalFeeAmountForItems,
  mapStoreReturnReasonsToOptions,
  subtotalReducer,
  taxReducer,
} from 'utils';
import { useInGeo } from 'app/hooks/useInGeo';
import ContinueCtaWrapper from 'app/components/ContinueCtaWrapper';

import {
  ProductReturnCard,
  ProductReturnCardPlaceholder,
  StorePolicyChecklistModal,
} from './components';
import styles from './SelectItems.module.scss';

export const SelectItems = () => {
  const {
    eligibleItems,
    ineligibleItems,
    submittedItems,
    isInitializing: isStoreOrderLoading,
    storeOrder,
    nonReturnableItems,
  } = useStoreOrder();
  const currency = storeOrder?.currency;
  const analytics = useAnalytics();
  const { t, i18n } = useTranslation();
  const { orderNumber, hasOrderData, returnItems, save, saveAndNavigate } = useReturnFlow();
  const {
    storeConfig,
    hasExchangeFee,
    hasRefundFee,
    hasStoreCreditFee,
    policyRules,
    storeReturnReasons,
    returnAcceptanceCriteria,
    isInitializing: isStoreConfigLoading,
    themeConfig,
  } = useStoreConfig();

  const storeName = themeConfig?.name;
  const storeUid = storeOrder?.storeUid;

  const [returnData, setReturnData] = useState<ReturnDataState>({});
  const { setAddressInfo } = useContext(AddressContext);
  // selectedItems tracks all the items a user has selected, but may not have completed.
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showStorePolicyModal, setShowStorePolicyModal] = useState(false);
  const validItemsCount = useMemo(
    () =>
      Object.values(returnData).filter(
        ({ isSelected, returnReasonUid, returnMethod, exchangeVariantId }) =>
          // item needs to be selected
          isSelected &&
          // needs to have a returnreason
          returnReasonUid !== undefined &&
          // if the item is an exchange it needs to have an exchange variant id
          !(returnMethod === ExchangeOrReturn.Exchange && !exchangeVariantId)
      ).length,
    [returnData]
  );
  const selectedCount = selectedItems.length;
  // pre-calc the return case fees before setting the item state.
  // this determines if fees block the user from continuing
  // this should only apply to return cases where the user has selected items with only one payout method
  // for items that can be selected for refund or store credit the fees should be calculated on the payout method page
  // since some of the fees may be redistributed to the other items selected for refund or store credit

  // scroll to content on back
  useScrollToTopOnMount();

  const inGeo = useInGeo();
  const refundOnlyItems = getRefundOnlyItems(Object.values(returnData));
  const storeCreditOnlyItems = getStoreCreditOnlyItems(Object.values(returnData));
  const refundOnlyItemsFees =
    refundOnlyItems.length > 0 && hasRefundFee
      ? getTotalFeeAmountForItems(refundOnlyItems, storeConfig?.fees?.refund)
      : 0;
  const storeCreditOnlyItemsFees =
    storeCreditOnlyItems.length > 0 && hasStoreCreditFee
      ? getTotalFeeAmountForItems(storeCreditOnlyItems, storeConfig?.fees?.storeCredit)
      : 0;
  const storeCreditSubtotal = storeCreditOnlyItems.reduce(subtotalReducer, 0);
  const refundSubtotal = refundOnlyItems.reduce(subtotalReducer, 0);
  const refundTaxTotal = refundOnlyItems.reduce(taxReducer, 0);
  const storeCreditTaxTotal = storeCreditOnlyItems.reduce(taxReducer, 0);
  const invalidRefundFee = refundSubtotal + refundTaxTotal < refundOnlyItemsFees;
  const invalidStoreCreditFee =
    storeCreditSubtotal + storeCreditTaxTotal < storeCreditOnlyItemsFees;
  const invalidReturnCaseFee =
    (invalidRefundFee && refundOnlyItems.length > 0) ||
    (invalidStoreCreditFee && storeCreditOnlyItems.length > 0);
  const hasExchangeItemSelected =
    returnData &&
    Object.keys(returnData)
      .map((key) => returnData[key].returnMethod)
      .includes(ExchangeOrReturn.Exchange);
  const payoutMethodRulesItemsCount = refundOnlyItems.length + storeCreditOnlyItems.length;

  // fees are invalid, there are no other items and exchanges arent picked
  const disableRefundOnlyItems =
    invalidRefundFee && !hasExchangeItemSelected && validItemsCount === payoutMethodRulesItemsCount;

  const disableStoreCreditOnlyItems =
    invalidStoreCreditFee &&
    !hasExchangeItemSelected &&
    validItemsCount === payoutMethodRulesItemsCount;

  const shouldDisableContinue =
    selectedCount !== validItemsCount ||
    validItemsCount === 0 ||
    disableRefundOnlyItems ||
    disableStoreCreditOnlyItems;

  useEffect(() => {
    if (eligibleItems.length) {
      analytics.returnSelectPageViewed(eligibleItems.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleItems]);

  // Prevent the user from going to steps after this current one if they landed
  // here from future steps. There are cases where changes to items will affect
  // the payout methods that are available.
  useEffect(() => {
    save({
      returnMethod: undefined,
      payoutMethod: undefined,
    });

    // Reset custom override address information.
    setAddressInfo(undefined);

    // We only run this hook on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRedirectToErrorPage({
    // Redirect to error page if user doesn't have a stored orderNumber or email
    when: !hasOrderData,
  });

  /**
   * Handles what happens when the user clicks the Continue button at the bottom of the page
   *
   * If there are no StorePolicyRules configured, this button should trigger the click
   * event for the modal continue button instead
   */
  const handleContinueClick = () => {
    if (policyRules.length === 0) {
      // Skip directly to the next step
      handleModalContinueClick();
    } else {
      setShowStorePolicyModal(true);
    }
  };

  /**
   * Handles what happens when the user clicks the continue button in the StorePolicyChecklistModal
   */
  const handleModalContinueClick = (reviewedPolicyRules: PolicyRule[] = []) => {
    setShowStorePolicyModal(false);
    saveAndNavigate('/return/return-method', {
      returnItems: Object.values(returnData),
      reviewedPolicyRules,
      // Clear the payout method since the user may have adjusted the selected
      // items which affects the next steps ("Return Method", "Payout Method").
      // For example, if they previously had selected "Original Payout
      // Method" as the payout method, but selected a store credit only item,
      // then they need to proceed to the "Payout Method" step to see how their
      // payout may have changed.
      payoutMethod: undefined,
    });
  };

  const statusLabelMap = getReturnItemStatusDisplayValue(t);
  const englishPolicyUrl = storeConfig?.returnPolicyUrl_en || storeConfig?.returnPolicyUrl;
  const specialHandlingLink = (
    <a href={i18n.resolvedLanguage.startsWith('fr') ? SUPPORT_URL_FR : SUPPORT_URL_EN}>text</a>
  );
  const storesWithTheirEligibleItems = useMemo(() => {
    return uniqBy(eligibleItems, 'store.uid')
      .map((item) => {
        return item.store;
      })
      .filter(Boolean)
      .map((store) => {
        return {
          store,
          items: eligibleItems.filter((item) => item.store?.uid === store?.uid),
        };
      });
  }, [eligibleItems]);

  const showSelectItemsFromOneVendorAlert = storesWithTheirEligibleItems.length > 1;

  const orderedEligibleItemStoreDetails = useMemo(() => {
    // We want marketplaceProviders as the first store in the product selection list.
    const marketplaceProvider = storesWithTheirEligibleItems.filter(
      (store) => store.store?.uid === storeUid
    );
    const marketplaceStores = storesWithTheirEligibleItems.filter(
      (store) => store.store?.uid !== storeUid
    );
    const alphabeticalMarketplaceStores = marketplaceStores.sort((a, b) => {
      if (a.store && b.store) {
        return (a.store?.name ?? '').localeCompare(b.store?.name ?? '');
      }
      return 0;
    });

    return marketplaceProvider.concat(alphabeticalMarketplaceStores);
  }, [storeUid, storesWithTheirEligibleItems]);

  const getDisplayStatus = useCallback(
    (isRemitted: boolean, returnCaseStatus: string) => {
      const key = isRemitted ? ReturnCaseItemStatus.COMPLETED : returnCaseStatus;
      if (!(key in statusLabelMap)) {
        return key;
      }
      return statusLabelMap[key].toLocaleUpperCase();
    },
    [statusLabelMap]
  );

  // Get just the selectedItem id, not the whole custom string. We could get
  // just the first item's ID because every other item in this list would have
  // the same store details.
  const selectedItemId = selectedItems && selectedItems[0]?.split('-').pop();

  const bayLocationsUrl = i18n.resolvedLanguage.startsWith('fr')
    ? MAIN_BAY_FRENCH_URL
    : MAIN_BAY_URL;

  return (
    <>
      <DefaultAppPage title={t('selectItemsPage.title')} className={styles.container}>
        <div className={styles.headerInfo} data-testid="select-items-header-info">
          {t('selectItemsPage.orderNumber', { orderNumber })}
          <a
            href={
              i18n.resolvedLanguage.startsWith('fr')
                ? storeConfig?.returnPolicyUrl_fr || englishPolicyUrl
                : englishPolicyUrl
            }
            target="_returnPolicy"
            className={styles.returnPolicy}
          >
            {t('selectItemsPage.viewReturnPolicy')}
          </a>
        </div>

        {showSelectItemsFromOneVendorAlert && !isStoreOrderLoading && !isStoreConfigLoading && (
          <div className={styles.alertWrapper}>
            <Alert
              iconComponent={<InfoIcon size={20} />}
              message={t('selectItemsPage.returnOnlyOneVendorWarning', {
                marketplaceProvider: storeName,
              })}
            />
          </div>
        )}
        <div className={styles.lineItems} data-testid="items-parent-container">
          {isStoreOrderLoading && !isStoreConfigLoading && (
            <>
              <ProductReturnCardPlaceholder />
              <ProductReturnCardPlaceholder />
            </>
          )}

          {orderedEligibleItemStoreDetails.map((store) => {
            const selectedItemDetails = store.items.find((item) => {
              return item.id === selectedItemId;
            });

            const isMarketplaceOwned =
              selectedItemDetails?.store?.sellerType === StoreSellerTypeChoice.MarketplaceOwned;

            return (
              <Fragment key={store.store?.uid}>
                <div className={styles.itemCount}>
                  {t('selectItemsPage.itemsFromCountWithStoreName', {
                    storeName: store.store?.name || '',
                    count: store.items.length,
                  })}
                </div>

                {isMarketplaceOwned && !inGeo && (
                  <div
                    className={clsx(
                      styles.alertWrapper,
                      isMarketplaceOwned && styles.selectedItems
                    )}
                  >
                    <Alert
                      iconComponent={<InfoIcon size={20} />}
                      message={t('returnMethodPage.storePickupMPOwned')}
                      cta_link={bayLocationsUrl}
                      cta_message={t('returnMethodPage.storeLocator')}
                    />
                  </div>
                )}

                <div className={styles.productSelectionWarning}>
                  {selectedItemId &&
                    selectedItemDetails?.store?.uid !== store.store?.uid &&
                    t('selectItemsPage.returnOnlyOneVendorProductSectionWarning')}
                </div>
                <div data-testid="select-items-list">
                  {store.items?.map((lineItem, index) => {
                    return (
                      <ProductReturnCard
                        index={index}
                        currency={currency}
                        key={`product-${lineItem.id}-${index}`}
                        lineItem={lineItem}
                        initialData={returnItems.find(
                          (item) => item.lineItem.uniqueId === lineItem.uniqueId
                        )}
                        criteria={returnAcceptanceCriteria}
                        storeConfig={storeConfig}
                        hasExchangeFee={hasExchangeFee}
                        hasRefundFee={hasRefundFee}
                        hasStoreCreditFee={hasStoreCreditFee}
                        setReturnItems={setReturnData}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        invalidStoreCreditFee={invalidStoreCreditFee}
                        invalidReturnCaseFee={invalidReturnCaseFee}
                        hasExchangeItemSelected={hasExchangeItemSelected}
                        storeReturnReasons={mapStoreReturnReasonsToOptions(storeReturnReasons)}
                        selectedItemStore={selectedItemDetails?.store}
                      />
                    );
                  })}
                </div>
                <br />
              </Fragment>
            );
          })}
        </div>

        {submittedItems.length > 0 && (
          <div className={styles.blob}>
            <div className={styles.itemCount}>
              {t('selectItemsPage.submittedReturns', { count: submittedItems.length })}
            </div>

            {submittedItems.map((lineItem, index) => (
              <ProductCard key={`submitted-${lineItem.id}-${index}`} lineItem={lineItem}>
                {t('selectItemsPage.status', {
                  status: getDisplayStatus(lineItem.isRemitted || false, lineItem.returnCaseStatus),
                })}
              </ProductCard>
            ))}
          </div>
        )}

        {nonReturnableItems.length > 0 && (
          <div className={styles.blob}>
            <div className={styles.itemCount}>
              {t('selectItemsPage.specialHandlingRequired', { count: nonReturnableItems.length })}
            </div>

            <div className={styles.specialHandling}>
              <Trans
                i18nKey="selectItemsPage.specialHandlingBody"
                t={t}
                components={[specialHandlingLink]}
              />
            </div>

            {nonReturnableItems.map((lineItem, index) => (
              <ProductReturnCard
                index={index}
                currency={currency}
                key={`product-${lineItem.id}-${index}`}
                lineItem={lineItem}
                initialData={returnItems.find(
                  (item) => item.lineItem.uniqueId === lineItem.uniqueId
                )}
                storeConfig={storeConfig}
                hasExchangeFee={hasExchangeFee}
                hasRefundFee={hasRefundFee}
                hasStoreCreditFee={hasStoreCreditFee}
                setReturnItems={setReturnData}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                invalidStoreCreditFee={invalidStoreCreditFee}
                invalidReturnCaseFee={invalidReturnCaseFee}
                hasExchangeItemSelected={hasExchangeItemSelected}
                storeReturnReasons={mapStoreReturnReasonsToOptions(storeReturnReasons)}
                disabled={true}
              />
            ))}
          </div>
        )}

        {ineligibleItems.length > 0 && (
          <div className={styles.blob}>
            <div className={styles.itemCount}>
              {t('selectItemsPage.itemsNotEligibleForReturn', { count: ineligibleItems.length })}
            </div>

            {ineligibleItems.map((lineItem, index) => (
              <ProductCard key={`inelgible-${lineItem.id}-${index}`} lineItem={lineItem}>
                <span>{lineItem.disableMessage}</span>
              </ProductCard>
            ))}
          </div>
        )}
      </DefaultAppPage>
      <StorePolicyChecklistModal
        isVisible={showStorePolicyModal}
        onClose={() => setShowStorePolicyModal(false)}
        onSubmit={handleModalContinueClick}
      />

      {!isStoreOrderLoading && !isStoreConfigLoading && (
        <ContinueCtaWrapper
          continueButton={
            <Button disabled={shouldDisableContinue} onClick={handleContinueClick}>
              {t('selectItemsPage.continue')}
            </Button>
          }
          selectedItemsCount={selectedItems.length}
        />
      )}
    </>
  );
};
