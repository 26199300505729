import clsx from 'clsx';

import { Spinner } from 'ui';

import styles from './Button.module.scss';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  /**
   * If provided, renders the button with a different appearance
   */
  appearance?: 'link' | 'text';

  /**
   * An additional className to append to the button element
   */
  className?: string;

  /**
   * Specifies the content for the button
   */
  children?: React.ReactNode;

  /**
   * Applies the [disabled] attribute to the button and prevents the button from receiving clicks
   */
  disabled?: boolean;

  /**
   * Specifies the button type
   */
  type?: 'button' | 'submit' | 'reset' | undefined;

  /**
   * If true, renders a <Spinner /> element to the left of the text content
   */
  isWorking?: boolean;
}

export const Button = ({
  appearance,
  children,
  className,
  isWorking,
  type,
  ...props
}: ButtonProps) => (
  <button
    {...props}
    type={type}
    className={clsx(styles.container, className, appearance && styles[appearance])}
  >
    {isWorking && <Spinner size={21} className={styles.loadingIndicator} />}
    {/*
      Using Google Translate without wrapping this in a span causes the React app to crash
      https://github.com/facebook/react/issues/11538#issuecomment-390386520
    */}
    {children && <span>{children}</span>}
  </button>
);

Button.defaultProps = {
  type: 'button',
};
