import { useSearchParams } from 'react-router-dom';

import { ENABLE_IN_GEO_URL_FLAG } from '../constants';

/**
 * A hook that returns value for the in_geo query string parameter
 *
 * @returns Boolean indicating whether in_geo is set or not.
 */
export const useInGeo = () => {
  const [searchParams] = useSearchParams();
  const geozoneValue = searchParams.get('in_geo');

  if (!ENABLE_IN_GEO_URL_FLAG) {
    return true;
  }

  if (geozoneValue) {
    return geozoneValue === 'true';
  }

  return true;
};
