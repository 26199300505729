import { createPortal } from 'react-dom';
import { useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';

import { usePortal, useLockBodyScroll } from 'app/hooks';
import { IconTimes } from 'icons';

import styles from './Modal.module.scss';

export interface ModalProps {
  /**
   * The content for the Modal
   */
  children: React.ReactNode;

  /**
   * Specifies an optional className to be appended to the content area
   */
  className?: string;

  /**
   * Specifies the a11y text that is used for the close button
   *
   * Defaults to 'Close', you can use this prop to provide a translation
   */
  closeButtonLabel?: string;

  /**
   * Specifies the content that gets rendered in the <footer> of this Modal
   */
  footer?: React.ReactNode;

  /**
   * Customizes the styling used for the title of this Modal
   */
  customTitleClassName?: string;

  /**
   * Customizes the styling used in the <footer> of this Modal
   */
  customFooterClassName?: string;

  /**
   * Customizes the styling used for the close button of this Modal
   */
  customCloseButtonClassName?: string;

  /**
   * Specifies whether the modal should be visible or not
   *
   * When not visible, the Modal gets removed from the DOM and most form inputs will
   * be reset to default values
   */
  isVisible: boolean;

  /**
   * An optional classname to be appended to the outer modal elmeent
   */
  modalClass?: string;

  /**
   * Provide a function to be called when the close button is clicked
   */
  onClose?: () => void;

  /**
   * Specifies a title to be used in the modal header
   *
   * If a custom title is desired, ignore this prop and render a title inside `children` instead
   */
  title?: React.ReactNode;

  /**
   * Specifies whether or not to prevent scrolling when the modal is visible
   */
  useScrollLock?: boolean;

  /**
   * Adds an id that we can scroll to.
   */
  contentId?: string;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  closeButtonLabel,
  footer,
  customTitleClassName,
  customFooterClassName,
  customCloseButtonClassName,
  isVisible,
  modalClass,
  title,
  onClose,
  useScrollLock,
  contentId,
}) => {
  const target = usePortal('modals');
  const containerRef = useRef<HTMLDivElement>(null);

  // Prevent body scrolling when visible
  useLockBodyScroll(!!useScrollLock && isVisible);

  useLayoutEffect(() => {
    if (isVisible && containerRef.current) {
      // For accessibility, focus the container element so that the user can use
      // the Tab key to correctly hit the first element in the newly visible modal
      containerRef.current?.focus();
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }
  const modalContent = (
    <>
      <div
        className={styles.container}
        // We want the modal to be focused when the user presses tab to
        // automatically select the first interactable element in the dialog
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={containerRef}
      >
        <div className={clsx(styles.modal, modalClass)}>
          {isVisible && (
            <>
              {title && <div className={clsx(styles.title, customTitleClassName)}>{title}</div>}
              <div className={clsx(styles.modalContent, className)} id={contentId}>
                {children}
              </div>

              {footer && (
                <footer className={clsx(styles.footer, customFooterClassName)}>{footer}</footer>
              )}
              {onClose && (
                <button
                  onClick={onClose}
                  className={clsx(styles.closeButton, customCloseButtonClassName)}
                  aria-label={closeButtonLabel}
                >
                  <IconTimes size={18} />
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.modalBackdrop} />
    </>
  );

  return createPortal(modalContent, target);
};

Modal.defaultProps = {
  closeButtonLabel: 'Close',
  isVisible: false,
  useScrollLock: true,
};
