import { useState } from 'react';
import clsx from 'clsx';

import { TooltipButton } from 'ui';

import styles from './TextInput.module.scss';

interface TextInputProps {
  /**
   * Text to be used for the TooltipButton label
   */
  a11yText: string;

  /**
   * Whether or not the element should be automatically focused when rendered
   */
  autoFocus?: boolean | undefined;

  /**
   * An optional className to be applied to the outer container element
   */
  className?: string;

  /**
   * Whether or not the input element should be disabled
   */
  disabled?: boolean;

  /**
   * The text or content to be used for the <label> element
   */
  label?: React.ReactNode | string | null;

  /**
   * The name property for the <input> element
   */
  name: string;

  /**
   * A callback function that will be called whenever the input changes
   */
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;

  /**
   * A placeholder value to be rendered inside the <input /> element
   */
  placeholder?: string;

  /**
   * The text or content to be used inside the tooltip
   */
  tooltip?: React.ReactNode;

  /**
   * The type of the text input
   *
   * 'password', 'email', and 'number' are common types that should be used where appropriate
   */
  type: string;

  /**
   * The current value of the input
   */
  value: string;

  /**
   * Show field error.
   */
  errorText?: string;
}

export const TextInput = ({
  a11yText,
  className,
  label,
  value,
  tooltip,
  type,
  errorText,
  ...spreadProps
}: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(spreadProps?.autoFocus ? true : false);
  const isLabelShiftedDown = value === '' && !isFocused;

  return (
    <>
      <div className={clsx(styles.container, className)}>
        <label>
          <div
            className={clsx(
              styles.inputLabel,
              isLabelShiftedDown && styles.shiftedDown,
              isFocused && styles.focused
            )}
          >
            {label}
          </div>

          <input
            className={styles.input}
            value={value}
            {...spreadProps}
            type={type}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </label>

        {tooltip && (
          <TooltipButton className={styles.tooltip} a11yText={a11yText}>
            {tooltip}
          </TooltipButton>
        )}
      </div>
      <div>{errorText && <p className={styles.error}>{errorText}</p>}</div>
    </>
  );
};

TextInput.defaultProps = {
  type: 'text',
  a11yText: 'Show tooltip',
};
