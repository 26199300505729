import { SelectItemShape } from 'ui';

export enum ReturnMethods {
  DropOff = 'dropOff',
  MailIn = 'mailIn',
}

export enum ExchangeOrReturn {
  Exchange = 'exchange',
  Return = 'return',
}

export const DEFAULT_PHONE_NUMBER = '+1 416-303-1843';

export const NETWORK_GENERIC_ERROR =
  'Network error occurred, please try again or contact customer service.';

export enum RuleOutcomeKey {
  PAYOUT_METHOD = 'PAYOUT_METHOD',
}

export const DEFAULT_ADDRESS_RESIDENTIAL_INDICATOR = 'Yes';

export enum ReturnSteps {
  LocateOrder = '/',
  SelectItems = 'select-items',
  ReturnMethod = 'return-method',
  PayoutMethod = 'payout-method',
  Review = 'review',
}

// for items without a variant shopify gives it a default title that we want to hide.
export const SHOPIFY_DEFAULT_VARIANT_TITLE = 'Default Title';

export const CANADA_COUNTRY_CODE = 'CA';

// NOTE: The type is pulled from google's @react-google-maps/api "Libraries" type
// that isnt exported. We do this because google maps' library property needs
// to have a static reference whenever we are initializing it.
export const googleLibraries: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['geometry'];

export const MAIN_BAY_URL = 'https://locations.thebay.com/en-ca';
export const MAIN_BAY_FRENCH_URL = 'https://locations.thebay.com/fr-ca';

export const SUPPORT_URL_FR = 'https://service.labaie.com/s/contactsupport';
export const SUPPORT_URL_EN = 'https://support.thebay.com/s/contactsupport';

export const canadianProvinces: SelectItemShape[] = [
  {
    value: 'AB',
    label: 'Alberta',
  },
  {
    value: 'BC',
    label: 'British Columbia',
  },
  {
    value: 'MB',
    label: 'Manitoba',
  },
  {
    value: 'NB',
    label: 'New Brunswick',
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador',
  },
  {
    value: 'NT',
    label: 'Northwest Territories',
  },
  {
    value: 'NS',
    label: 'Nova Scotia',
  },
  {
    value: 'NU',
    label: 'Nunavut',
  },
  {
    value: 'ON',
    label: 'Ontario',
  },
  {
    value: 'PE',
    label: 'Prince Edward Island',
  },
  {
    value: 'QC',
    label: 'Quebec',
  },
  {
    value: 'SK',
    label: 'Saskatchewan',
  },
  {
    value: 'YT',
    label: 'Yukon',
  },
];

export const usStates: SelectItemShape[] = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MD',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const countries: SelectItemShape[] = [
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'United States of America',
    value: 'US',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
];

export const ENABLE_IN_GEO_URL_FLAG = false;
