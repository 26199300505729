import { Icon, IconProps } from './Icon';

export const IconReturnBear = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 19 19">
    <path
      d="M1.42653 6.80799L3.21424 5.76636C2.64369 5.34199 2.28235 4.66686 2.28235 3.89528C2.28235 2.62217 3.30933 1.58054 4.56454 1.58054C5.74367 1.58054 6.7136 2.48714 6.82771 3.64451L12.381 0.384588C14.2829 -0.714914 16.6602 0.67393 16.6602 2.89222V15.7197C16.6602 17.938 14.2829 19.3462 12.381 18.2274L7.72157 15.5076H9.31911C10.6694 15.5076 11.5062 14.0223 10.8406 12.8456L8.9958 9.62426C8.33016 8.4476 6.63753 8.4476 5.95287 9.62426L5.64858 10.1451H8.02586C8.76758 10.1451 9.14794 11.0517 8.63445 11.5725L7.34121 12.9228C7.0179 13.27 6.46637 13.27 6.14306 12.9228L4.86883 11.5725L4.14614 12.8456C4.07006 12.9999 3.99399 13.1542 3.95595 13.3086L1.42653 11.8233C-0.4753 10.7045 -0.4753 7.92678 1.42653 6.80799ZM11.9056 9.48923C12.4191 9.48923 12.8565 9.06487 12.8565 8.52476C12.8565 7.98465 12.4381 7.56028 11.9056 7.56028C11.3731 7.56028 10.9547 7.98465 10.9547 8.52476C10.9547 9.06487 11.3731 9.48923 11.9056 9.48923ZM4.54552 9.48923C5.05901 9.48923 5.49643 9.06487 5.49643 8.52476C5.49643 7.98465 5.07803 7.56028 4.54552 7.56028C4.01301 7.56028 3.59461 7.98465 3.59461 8.52476C3.59461 9.06487 4.01301 9.48923 4.54552 9.48923Z"
      fill={color}
    />
  </Icon>
);
