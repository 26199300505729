import { GraphQLError } from 'graphql';

export enum CustomGraphQLErrorCode {
  BadUserInput = 'BAD_USER_INPUT',
  Unauthenticated = 'UNAUTHENTICATED',
  Forbidden = 'FORBIDDEN',
  NotFound = 'NOT_FOUND',
}

export interface FormattedGraphQLError {
  message: string;
  graphQLError?: GraphQLError;
  errorCode?: CustomGraphQLErrorCode | unknown;
  validationError?: { [key: string]: any } | unknown;
}
