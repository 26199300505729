// TODO: learn how to do this properly?
// https://mui.com/material-ui/customization/typography/
// https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme

// Figma designs for typography:
// https://www.figma.com/file/r9k8Vwb0HaYJYVimNR4tCR/MUI-for-Figma-Material-Pro-v5.9.0?node-id=4662%3A14

// XXX: theme typography contains variants that do not exist in MUI by default.

import type { Theme } from '@mui/material/styles';

import { ThemeConfigInterface } from '../app/types';

export const typographyWithRetailerTheme = (theme?: ThemeConfigInterface | null) => {
  // a constant was created because we need to return json with "type" Partial<Theme['typography']>
  const typography: Partial<Theme['typography']> = {
    fontFamily: theme?.defaultFontFamily || 'Poppins',
    h1: {
      fontWeight: '500',
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h2: {
      fontWeight: '400',
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: '500',
      fontSize: '34px',
      lineHeight: '40px',
      letterSpacing: '0px',
    },
    h4: {
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px',
    },
    h6: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    // button medium
    button: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'none',
    },
    caption: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    // missing variants:
    // button large
    // button small
    // avatarLetter
    // inputLabel
    // helperText
    // inputText
    // tooltip
  };

  return typography;
};
