import { ConfirmationHeader } from 'app/components';

import styles from './ConfirmationLayout.module.scss';

interface ConfirmationLayoutProps {
  children: React.ReactNode;
  hero?: React.ReactNode;
  nextSteps?: string[];
  subTitle: string;
  title: string;
}

export const ConfirmationLayout = ({
  children,
  hero,
  nextSteps,
  subTitle,
  title,
}: ConfirmationLayoutProps) => (
  <>
    <ConfirmationHeader />
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.subTitle}>{subTitle}</div>

      {hero && <div className={styles.hero}>{hero}</div>}

      {nextSteps && (
        <ul className={styles.nextSteps}>
          {nextSteps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      )}

      <div className={styles.content}>{children}</div>
    </div>
  </>
);
