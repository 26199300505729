import { useTranslation } from 'react-i18next';

import { useStoreConfig } from 'app/contexts';

import styles from './ConfirmationHeader.module.scss';

export const ConfirmationHeader = () => {
  const { storeConfig, themeConfig } = useStoreConfig();
  const { t, i18n } = useTranslation();

  const storeNameOrLogo = themeConfig?.logoImageUrl ? (
    <img src={themeConfig?.logoImageUrl} alt={themeConfig.name} className={styles.logo} />
  ) : (
    themeConfig?.name
  );

  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  return (
    <div className={styles.container}>
      <div className={styles.header}>{storeNameOrLogo}</div>

      <div className={styles.title}>{t('returnConfirmationPage.headerTitle')}</div>

      <div className={styles.backToShopping}>
        <a
          href={
            i18n.resolvedLanguage.startsWith('fr')
              ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
              : englishShoppingUrl
          }
          className={styles.backToShoppingButton}
        >
          {t('returnConfirmationPage.backToShopping')}
        </a>
      </div>
    </div>
  );
};
