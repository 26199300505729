import clsx from 'clsx';

import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  /**
   * The content to display within the error message
   */
  children: React.ReactNode;

  /**
   * An optional className to append to the container element
   */
  className?: string;

  /**
   * Determines whether or not this element is rendered
   */
  isVisible?: boolean;
}

export const ErrorMessage = ({ className, children, isVisible }: ErrorMessageProps) =>
  isVisible ? (
    <div data-testid="error-message-container" className={clsx(styles.container, className)}>
      {children}
    </div>
  ) : null;

ErrorMessage.defaultProps = {
  isVisible: true,
};
