import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useStoreConfig } from 'app/contexts';
import { PolicyRule } from 'app/types';
import { useReturnFlow } from 'app/hooks';
import { Button, Checkbox, Modal, Placeholder } from 'ui';

import styles from './StorePolicyChecklistModal.module.scss';

interface StorePolicyModalChecklistProps {
  /**
   * Determines whether or not the StorePolicyChecklistModal is visible
   *
   * When not visible, the element is removed from the DOM
   */
  isVisible: boolean;

  /**
   * A callback function to be called whenever the modal's Close button is clicked
   */
  onClose?: () => void;

  /**
   * A callback function to be called when the Continue button at the bottom of the modal
   * is clicked
   *
   * This function receives an array of PolicyRules filled with objects containing { uid, rule, accepted }
   */
  onSubmit?: (reviewedPolicyRules: PolicyRule[]) => void;
}

/**
 * Renders a modal containing a checklist of all the store's configured policy rules
 */
export const StorePolicyChecklistModal = ({
  isVisible,
  onClose,
  onSubmit,
}: StorePolicyModalChecklistProps) => {
  const { storeConfig, policyRules, policyRulesLoading, themeConfig } = useStoreConfig();
  const { reviewedPolicyRules: initialValues } = useReturnFlow();
  const { t } = useTranslation();
  const [reviewedPolicyRules, setReviewedPolicyRules] = useState<PolicyRule[]>(initialValues);

  useEffect(() => {
    if (reviewedPolicyRules.length === 0 && policyRules.length > 0) {
      setReviewedPolicyRules(
        policyRules.map(({ uid, rule }) => ({
          accepted: false,
          uid,
          rule,
        }))
      );
    }
  }, [policyRules, reviewedPolicyRules]);

  const contactUsLink = (
    <a href={`mailto:${storeConfig?.customerServiceEmail}`} target="_blank" rel="noreferrer">
      {storeConfig?.customerServiceEmail}
    </a>
  );

  /**
   * Handles what happens when the Continue button at the ottom of the modal is clicked
   */
  const handleContinueClick = () => {
    if (typeof onSubmit === 'function') {
      onSubmit(reviewedPolicyRules);
    }
  };

  const handleCheckboxClick = (policyRule: PolicyRule) => () => {
    // Update the list of policy rules with the new value
    const updatedPolicyRules = reviewedPolicyRules.map((originalValue) => {
      if (originalValue.uid !== policyRule.uid) {
        return originalValue;
      }

      return {
        ...originalValue,
        accepted: !policyRule.accepted,
      };
    });

    setReviewedPolicyRules(updatedPolicyRules);
  };

  return (
    <Modal
      title={t('storePolicyChecklist.title')}
      isVisible={isVisible}
      onClose={onClose}
      footer={
        <Button className={styles.ctaButton} onClick={handleContinueClick}>
          {t('common.continue')}
        </Button>
      }
      className={styles.container}
      modalClass={styles.modal}
    >
      <p>{t('storePolicyChecklist.instructions', { storeName: themeConfig?.name })}</p>

      {policyRulesLoading ? (
        <>
          <div className={styles.placeholder}>
            <Placeholder height={20} width={20} className={styles.placeholderCheckbox} />
            <Placeholder height={24} width={280} />
          </div>

          <div className={styles.placeholder}>
            <Placeholder height={20} width={20} className={styles.placeholderCheckbox} />
            <Placeholder height={24} width={315} />
          </div>
        </>
      ) : (
        <div className={styles.checkboxes}>
          {reviewedPolicyRules.map((policyRule) => (
            <Checkbox
              key={policyRule.uid}
              className={styles.checkbox}
              checked={policyRule.accepted}
              onChange={handleCheckboxClick(policyRule)}
            >
              {policyRule.rule}
            </Checkbox>
          ))}
        </div>
      )}
      <small>
        <Trans
          i18nKey="storePolicyChecklist.contactUsInstructions"
          t={t}
          components={[contactUsLink]}
          values={{ contactUsEmail: storeConfig?.customerServiceEmail }}
        />
      </small>
    </Modal>
  );
};
