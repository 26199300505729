import { Icon, IconProps } from './Icon';

export const IconMapPin = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 17 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7601 11.1258C12.7903 9.68968 13.5 8.1052 13.5 6.5C13.5 3.73858 11.2614 1.5 8.5 1.5C5.73858 1.5 3.5 3.73858 3.5 6.5C3.5 8.1052 4.20966 9.68968 5.23993 11.1258C6.26219 12.5507 7.51236 13.7063 8.38066 14.4216C8.4265 14.4594 8.46777 14.4693 8.5 14.4693C8.53223 14.4693 8.5735 14.4594 8.61934 14.4217C9.48764 13.7063 10.7378 12.5507 11.7601 11.1258ZM9.57313 15.5794C11.4193 14.0584 15 10.5822 15 6.5C15 2.91015 12.0899 0 8.5 0C4.91015 0 2 2.91015 2 6.5C2 10.5822 5.58075 14.0584 7.42687 15.5794C8.05797 16.0993 8.94203 16.0993 9.57313 15.5794Z"
      fill={color}
    />
    <circle cx="8.5" cy="7" r="2" fill={color} />
  </Icon>
);
