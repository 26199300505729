import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useHashSwitch = (switchName: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hash, setHash] = useState(() => location.hash);

  // Update hash whenever it changes
  useEffect(() => setHash(location.hash.substring(1)), [location.hash]);

  // Detect whether the switchName is the current hash
  const isSwitchOn = useMemo(() => hash === switchName, [hash, switchName]);

  const setIsOn: (isOn: boolean) => void = useCallback(
    (isOn: boolean) => {
      const navigateOptions = {
        replace: true,
        state: location.state,
      };

      if (!isOn) {
        navigate(location.pathname, navigateOptions);
      } else {
        navigate(`${location.pathname}#${switchName}`, navigateOptions);
      }
    },
    [navigate, switchName, location]
  );

  return [isSwitchOn, setIsOn] as const;
};
