import { ReturnItem } from 'app/types';

/**
 * Remaps line items to lineItemJson for the estimateShippingApi
 *
 * @param .returnItems to remap
 * @returns .ReturnEstimateItems
 */
export const mapLineItemsForEstimate = (returnItems: ReturnItem[]) => {
  return returnItems.map((item) => {
    return { ...item, lineItemJson: item.lineItem };
  });
};
