import { useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactInfoModal, DropoffLocationsModal } from 'app/components';
import ContinueCtaWrapper from 'app/components/ContinueCtaWrapper';
import {
  DEFAULT_PHONE_NUMBER,
  MAIN_BAY_FRENCH_URL,
  MAIN_BAY_URL,
  ReturnMethods,
} from 'app/constants';
import { useStoreConfig, useStoreOrder } from 'app/contexts';
import {
  useDropoffLocations,
  useHashSwitch,
  useRedirectToErrorPage,
  useReturnFlow,
  useScrollToTopOnMount,
} from 'app/hooks';
import { useInGeo } from 'app/hooks/useInGeo';
import { DropoffLocation, FormattedGraphQLError, ReturnFlowData } from 'app/types';
import {
  GetShippingEstimateDocument,
  GetShippingEstimateMutation,
  ReturnMethodsUserInterfaceSettingsDocument,
  StoreSellerTypeChoice,
  ValidateAddressOperationDocument,
} from 'generated/graphql';
import { CAFlagIcon, IconExternalLink, IconReturnBear, USAFlagIcon } from 'icons';
import { InfoIcon } from 'icons/IconInfo';
import { DefaultAppPage } from 'layouts';
import { Button, Placeholder, SelectableItem, ThreeDots, TooltipButton } from 'ui';
import Alert from 'ui/Alert';
import { getReturnChannels, getStoreSellerTypeChoice } from 'utils';
import { formatApolloError } from 'utils/api';
import { formatPrice } from 'utils/number';
import { mapLineItemsForEstimate } from 'utils/shipping';

import styles from './ReturnMethod.module.scss';
import LocationsList from './components/LocationsList';
import { ReturnMethodChangeAddressModal } from './components/ReturnMethodChangeAddressModal';

export const ReturnMethod = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage; // 'en' or 'fr'
  const {
    dropoffLocationId: initialDropOffLocationId,
    hasOrderData,
    returnItems,
    shippingAddress,
    save,
    remainingItems,
    saveAndNavigate,
    isCanadianOrder,
    hasInboundShippingFee,
    hasFlatRateFee,
    flatRateFeeAmountOriginal,
    hasDropOffFlatRateFee,
    hasFlatFeeRemitMethodsFilter,
    hasMailInFlatRateFee,
    flatRateFeeName,
    returnMethod,
    orderLookupInput,
    isLocationsListVisible,
  } = useReturnFlow();

  const inGeo = useInGeo();

  const [showReturnMethodChangeAddressModal, setShowReturnMethodChangeAddressModal] =
    useState<boolean>(false);
  const [showOtherLocations, setShowOtherLocations] = useHashSwitch('dropOffLocations');
  const [showContactInfo, setShowContactInfo] = useHashSwitch('contactInfo');
  const [hasRetrievedShippingEstimate, setHasRetrievedShippingEstimate] = useState<boolean>(false);
  const [dropoffLocationId, setDropOffLocationId] = useState<string | null>(
    initialDropOffLocationId
  );
  const [shippingEstimate, setShippingEstimate] = useState<number>(0);
  const [crossBorderFee, setCrossBorderFee] = useState<number>(0);
  const [shippingEstimateError, setShippingEstimateError] = useState<FormattedGraphQLError | null>(
    null
  );
  const { storeOrder, isInitializing: isStoreOrderLoading } = useStoreOrder();
  const currency = storeOrder?.currency;

  const storeSellerTypeChoice = getStoreSellerTypeChoice(returnItems);

  const isMarketplaceOwned = storeSellerTypeChoice === StoreSellerTypeChoice.MarketplaceOwned;
  const isMarketplaceThirdParty =
    storeSellerTypeChoice === StoreSellerTypeChoice.MarketplaceThirdParty;
  const isD2C = storeSellerTypeChoice === StoreSellerTypeChoice.D2C;

  const returnItemsStoreName = returnItems?.[0].lineItem.store?.name;

  const {
    dropoffLocations,
    loading: dropoffLocationsLoading,
    isCloserToNonPilotStore,
    isGeolocating,
    defaultStartingLocationCoordinates,
  } = useDropoffLocations();
  const isLoading = dropoffLocationsLoading || isStoreOrderLoading;
  const { themeConfig, storeConfig } = useStoreConfig();
  const shippingCountryCode = shippingAddress?.countryCode;
  const usCrossBorderFee = isCanadianOrder ? 0 : crossBorderFee || 0;
  const usCrossBorderFeeName = t('returnMethodPage.crossBorderFee');

  const storeReturnChannels = storeConfig?.returnChannel || [];
  const currentReturnChannel = getReturnChannels(storeReturnChannels, shippingCountryCode ?? '');
  const canDropoff =
    inGeo &&
    (!isCloserToNonPilotStore || !isMarketplaceOwned) &&
    currentReturnChannel.includes(ReturnMethods.DropOff);
  const canMailin = currentReturnChannel.includes(ReturnMethods.MailIn);
  const defaultReturnMethod = returnMethod
    ? returnMethod
    : canDropoff
    ? ReturnMethods.DropOff
    : ReturnMethods.MailIn;
  const [currentReturnMethod, setCurrentReturnMethod] =
    useState<ReturnMethods>(defaultReturnMethod);

  const [getShippingEstimate, getShippingEstimateApi] = useMutation(GetShippingEstimateDocument, {
    onError(err) {
      setShippingEstimateError(formatApolloError(err, t));
      setHasRetrievedShippingEstimate(false);
    },
    onCompleted: (data: GetShippingEstimateMutation) => {
      setShippingEstimate(
        hasInboundShippingFee ? Number(data?.shippingEstimate?.estimateAmount) || 0 : 0
      );
      setCrossBorderFee(Number(data?.shippingEstimate?.logisticsFee) || 0);
      setShippingEstimateError(null);
      setHasRetrievedShippingEstimate(true);
    },
  });
  const dropoffLocation = useMemo<DropoffLocation | null>(() => {
    const firstLocation = dropoffLocations[0];
    const foundLocation = dropoffLocations.find((l) => l.uid === dropoffLocationId);

    return foundLocation || firstLocation;
  }, [dropoffLocations, dropoffLocationId]);

  const shippingVariables = {
    fromAddress1: shippingAddress?.address1 || '',
    fromAddress2: shippingAddress?.address2 || '',
    fromCity: shippingAddress?.city || '',
    fromProvince: shippingAddress?.provinceCode || '',
    fromCountryCode: shippingAddress?.countryCode || '',
    fromPostalCode: shippingAddress?.zipCode || '',
    fromName: shippingAddress?.name || '',
    fromFirstName: shippingAddress?.firstName || '',
    fromLastName: shippingAddress?.lastName || '',
    fromCompany: shippingAddress?.company || '',
    fromPhone: shippingAddress?.phone || DEFAULT_PHONE_NUMBER,
    lookupParams: orderLookupInput ?? [],
    // the estimateShippingApi uses the legacy prop LineItemJson to calculate US duties
    returnItems: mapLineItemsForEstimate(returnItems),
    storeUid: storeOrder?.storeUid || '',
  };

  const [validateAddress, validateAddressMutation] = useMutation(ValidateAddressOperationDocument);

  const userInterfaceSettingsQuery = useQuery(ReturnMethodsUserInterfaceSettingsDocument, {
    variables: {
      storeId: Number(storeOrder?.storeId),
      consumerLatitude: defaultStartingLocationCoordinates?.lat ?? 0,
      consumerLongitude: defaultStartingLocationCoordinates?.lng ?? 0,
    },
    skip: !!returnMethod || !storeOrder?.storeId || !defaultStartingLocationCoordinates,
    onCompleted: (data) => {
      save({
        isLocationsListVisible: canDropoff
          ? data.returnMethodsUserInterfaceSettings?.dropOffIsPreferred
          : false,
      });
    },
  });

  // Prevent the user from going to steps after this current one if they landed
  // here from future steps. The return method may affect the payout amount
  // shown on the payout method step (eg: if they selected mail in and there's
  // a shipping cost).
  useEffect(() => {
    save({
      payoutMethod: undefined,
    });
    // We only run this hook on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the return method if it was updated after we've received the list
  // of drop off locations.
  useEffect(() => {
    setCurrentReturnMethod(defaultReturnMethod);
  }, [defaultReturnMethod]);

  useEffect(() => {
    if (
      (hasInboundShippingFee || storeConfig?.hasShippingLogisticFee) &&
      shippingVariables &&
      storeOrder &&
      !hasRetrievedShippingEstimate
    ) {
      getShippingEstimate({
        variables: shippingVariables,
        fetchPolicy: 'no-cache',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrder, hasInboundShippingFee, getShippingEstimate, hasRetrievedShippingEstimate]);

  const handleContinueClick = (locationUid?: string) => {
    const updateData: Partial<ReturnFlowData> = {
      returnMethod: currentReturnMethod,
      shippingEstimate,
      logisticsFee: crossBorderFee,
      dropoffLocationId: currentReturnMethod === ReturnMethods.DropOff ? locationUid : undefined,
    };

    const navigateToNextRoute = () => {
      // Skip directly to review screen if there are no items where a user can select a payout method
      const nextRoute = remainingItems.length > 0 ? '/return/payout-method' : '/return/review';

      saveAndNavigate(nextRoute, updateData);
    };

    if (currentReturnMethod !== ReturnMethods.MailIn) {
      navigateToNextRoute();
    } else {
      validateAddress({
        variables: {
          address1: storeOrder?.shippingAddress.address1 || '',
          address2: storeOrder?.shippingAddress.address2 || '',
          addressResidentialIndicator:
            storeOrder?.shippingAddress.addressResidentialIndicator || '',
          city: storeOrder?.shippingAddress.city || '',
          country: storeOrder?.shippingAddress.country || '',
          province: storeOrder?.shippingAddress.province || '',
          zipCode: storeOrder?.shippingAddress.zipCode || '',
        },
      }).then((response) => {
        if (response.data?.validateAddress?.matchedAddress) {
          navigateToNextRoute();
        } else {
          setShowReturnMethodChangeAddressModal(true);
        }
      });
    }
  };

  useRedirectToErrorPage({
    when: !hasOrderData || !returnItems.length,
  });

  const handleShowOtherLocationsClick = () => {
    setShowOtherLocations(true);
  };

  const handleRetryShippingEstimate = () => {
    getShippingEstimate({
      variables: shippingVariables,
      fetchPolicy: 'no-cache',
    });
  };

  const handleMailInClick = () => {
    setCurrentReturnMethod(ReturnMethods.MailIn);
  };

  // scroll to content on back
  useScrollToTopOnMount();

  const formattedShippingEstimatePrice = currency ? (
    formatPrice({ amount: shippingEstimate, currency }, undefined, i18n.language)
  ) : (
    <Placeholder width={48} />
  );
  const formattedUsCrossBorderFeePrice = currency ? (
    formatPrice({ amount: usCrossBorderFee, currency }, undefined, i18n.language)
  ) : (
    <Placeholder width={48} />
  );

  const bayLocationsUrl = i18n.resolvedLanguage.startsWith('fr')
    ? MAIN_BAY_FRENCH_URL
    : MAIN_BAY_URL;

  // if flat rate fee applies to drop off and mail in, AND also depends on the remit method then show later in the flow
  const showFlatRateFeeLaterInFlowOnly =
    (hasFlatFeeRemitMethodsFilter?.length || 0) > 0 &&
    hasDropOffFlatRateFee == hasMailInFlatRateFee;

  const showDropOffFlatRateFee =
    !showFlatRateFeeLaterInFlowOnly &&
    hasFlatRateFee &&
    !!flatRateFeeAmountOriginal &&
    !!flatRateFeeName &&
    hasDropOffFlatRateFee;

  const showMailInFlatRateFee =
    !showFlatRateFeeLaterInFlowOnly &&
    hasFlatRateFee &&
    !!flatRateFeeAmountOriginal &&
    !!flatRateFeeName &&
    hasMailInFlatRateFee;

  // The "Shipping fee" is grouped into one displayed item, but the flat
  // fee is displayed as a separate item
  const hasShippingFee = !!hasInboundShippingFee || !!usCrossBorderFee;

  const flatRateBox = () => {
    return (
      <div className={styles.optionContainer}>
        <div className={styles.optionInfo}>
          <div className={styles.optionTitle}>
            <div className={styles.flagContainer}>{flatRateFeeName}</div>
          </div>
        </div>
        {currency && (
          <div className={styles.price}>
            {(hasFlatFeeRemitMethodsFilter?.length || 0 > 0) &&
              formatPrice({ amount: 0, currency }, undefined, i18n.language)}{' '}
            -{' '}
            {formatPrice({ amount: flatRateFeeAmountOriginal, currency }, undefined, i18n.language)}
          </div>
        )}
      </div>
    );
  };

  const handleChangeAddressModalContinueClick = () => {
    const updateData: Partial<ReturnFlowData> = {
      returnMethod: currentReturnMethod,
      shippingEstimate,
      logisticsFee: crossBorderFee,
    };

    if (currentReturnMethod === ReturnMethods.DropOff) {
      updateData.dropoffLocationId = dropoffLocation?.uid;
    }

    // Skip directly to review screen if there are no items where a user can select a payout method
    const nextRoute = remainingItems.length > 0 ? '/return/payout-method' : '/return/review';

    saveAndNavigate(nextRoute, updateData);
  };

  const bannerMessage =
    currentLanguage === 'en'
      ? storeConfig?.merchantReturnMethodPageBanner?.messageEn
      : storeConfig?.merchantReturnMethodPageBanner?.messageFr;

  const bannerCtaMessage =
    currentLanguage === 'en'
      ? storeConfig?.merchantReturnMethodPageBanner?.ctaMessageEn
      : storeConfig?.merchantReturnMethodPageBanner?.ctaMessageFr;

  const bannerCtaLink =
    currentLanguage === 'en'
      ? storeConfig?.merchantReturnMethodPageBanner?.ctaLinkEn
      : storeConfig?.merchantReturnMethodPageBanner?.ctaLinkFr;

  const SelectableDropOffItem = () => (
    <SelectableItem
      isSelected={currentReturnMethod === ReturnMethods.DropOff}
      onClick={() => setCurrentReturnMethod(ReturnMethods.DropOff)}
      className={styles.option}
    >
      <div className={styles.rowContent}>
        <div className={styles.optionInfo}>
          <div className={styles.optionTitle}>{t('returnMethodPage.dropOffTitle')}</div>
          {isMarketplaceThirdParty
            ? t('returnMethodPage.dropOffInfoMarketPlaceThirdParty', {
                marketplaceSeller: returnItemsStoreName,
              })
            : t('returnMethodPage.dropOffInfo')}
          <div className={styles.dropOffLocation}>
            <div className={styles.dropOffIcon}>
              <IconReturnBear size={24} />
            </div>

            <div className={styles.dropOffDetails}>
              <div className={styles.locationInfo}>
                <div className={styles.address}>
                  {isLoading ? (
                    <>
                      <Placeholder width={124} height={20} />
                      <br />
                      <Placeholder width={80} />
                    </>
                  ) : (
                    <>
                      <strong>{dropoffLocation?.name}</strong>
                      {dropoffLocation?.address}
                    </>
                  )}
                </div>

                {!!dropoffLocation?.distance && (
                  <div className={styles.distance}>{dropoffLocation?.distance}</div>
                )}
              </div>

              {dropoffLocation?.hoursOfOperationUrl && (
                <a
                  href={dropoffLocation.hoursOfOperationUrl}
                  target="_locationHours"
                  onClick={(event) => event.stopPropagation()}
                >
                  {t('returnMethodPage.viewHours')}{' '}
                  <IconExternalLink className={styles.externalLinkIcon} />
                </a>
              )}
            </div>
          </div>

          <div className={styles.otherLocation}>
            {isLoading ? (
              <Placeholder width={150} />
            ) : (
              dropoffLocations.length > 0 && (
                <button
                  onClick={handleShowOtherLocationsClick}
                  className={styles.changeLocationButton}
                  disabled={isLoading}
                >
                  <>{t('returnMethodPage.mapOfDropoffPoints')}</>
                </button>
              )
            )}
          </div>
          {isD2C && (
            <div className={styles.helpText}>
              <TooltipButton
                a11yText={t('returnMethodPage.whatAreDropOffPoints')}
                position="center"
                message={t('returnMethodPage.whatAreDropOffPoints')}
              >
                {t('returnMethodPage.dropOffToolTip', {
                  storeName: themeConfig?.name,
                  count: dropoffLocations.length,
                })}
              </TooltipButton>
            </div>
          )}
        </div>

        {!showDropOffFlatRateFee && (
          <div className={clsx(styles.price, styles.freePrice)}>{t('common.free')}</div>
        )}
      </div>

      {showDropOffFlatRateFee && (
        <>
          <div className={styles.optionBorder} />
          {flatRateBox()}
        </>
      )}
    </SelectableItem>
  );

  const SelectableMailInItem = () => (
    <SelectableItem
      isSelected={currentReturnMethod === ReturnMethods.MailIn}
      onClick={handleMailInClick}
      className={styles.mailInOption}
    >
      <div className={styles.optionContainer}>
        <div className={styles.optionInfo}>
          <div className={styles.optionTitle}>{t('returnMethodPage.mailInTitle')}</div>
          {isMarketplaceThirdParty
            ? t('returnMethodPage.mailInInfoMarketPlaceThirdParty', {
                marketplaceSeller: returnItemsStoreName,
              })
            : t('returnMethodPage.mailInInfo')}
          {/* Hide/exclude info on fullstory with fs-exclude */}
          <div className={clsx(styles.shipsFrom, 'fs-exclude')}>
            {t('returnMethodPage.shipsFrom', {
              address: shippingAddress?.address1,
            })}
            {/* <button className={styles.changeAddressButton} onClick={() => setShowContactInfo(true)}>
              {t('common.change')}
            </button> */}
          </div>
        </div>
        {!getShippingEstimateApi.loading &&
          !hasInboundShippingFee &&
          !usCrossBorderFee &&
          !hasMailInFlatRateFee && (
            <div className={clsx(styles.price, styles.freePrice)}>{t('common.free')}</div>
          )}
      </div>

      {hasShippingFee || showMailInFlatRateFee ? (
        <div>
          <div className={styles.optionBorder} />
          {hasShippingFee && (
            <div className={styles.optionContainer}>
              <div className={styles.optionInfo}>
                <div className={styles.optionTitle}>
                  <div className={styles.flagContainer}>
                    {isCanadianOrder ? (
                      <>
                        <CAFlagIcon className={styles.flagIcon} />
                        {t('common.shippingFee')}
                      </>
                    ) : (
                      <>
                        <USAFlagIcon className={styles.flagIcon} />
                        {hasInboundShippingFee && usCrossBorderFee
                          ? usCrossBorderFeeName
                          : usCrossBorderFee
                          ? t('returnMethodPage.crossBorderOnlyFee')
                          : t('common.shippingFee')}

                        {hasInboundShippingFee && usCrossBorderFee ? (
                          <TooltipButton
                            a11yText={t('returnMethodPage.crossBorderFeeTooltip', {
                              shippingFee: formattedShippingEstimatePrice,
                              handlingFee: formattedUsCrossBorderFeePrice,
                            })}
                            position="center"
                          >
                            {t('returnMethodPage.crossBorderFeeTooltip', {
                              shippingFee: formattedShippingEstimatePrice,
                              handlingFee: formattedUsCrossBorderFeePrice,
                            })}
                          </TooltipButton>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {!currency || getShippingEstimateApi.loading ? (
                <ThreeDots className={styles.threeDots} />
              ) : shippingEstimate + usCrossBorderFee ? (
                <div className={styles.price}>
                  -{' '}
                  {formatPrice(
                    {
                      amount: shippingEstimate + usCrossBorderFee,
                      currency,
                    },
                    undefined,
                    i18n.language
                  )}
                </div>
              ) : !getShippingEstimateApi.loading && shippingEstimateError?.message ? (
                <button
                  className={styles.changeAddressButton}
                  onClick={handleRetryShippingEstimate}
                >
                  {t('common.tryAgain')}
                </button>
              ) : (
                ''
              )}
            </div>
          )}

          {showMailInFlatRateFee && flatRateBox()}
        </div>
      ) : null}

      {shippingEstimateError?.message ? (
        <div>
          <div className={styles.optionContainer}>
            <div className={styles.optionInfo}>
              <div className={styles.errorMessage}>{shippingEstimateError.message}</div>
            </div>
          </div>
        </div>
      ) : null}
    </SelectableItem>
  );

  return (
    <>
      <DefaultAppPage
        title={
          isLocationsListVisible
            ? t('returnMethodPage.locationListViewTitle')
            : t('returnMethodPage.title')
        }
        subtitle={isLocationsListVisible ? t('returnMethodPage.subTitle') : undefined}
      >
        {isMarketplaceOwned && (!inGeo || isCloserToNonPilotStore) && (
          <div className={styles.alertWrapper}>
            <Alert
              iconComponent={<InfoIcon size={20} />}
              message={t('returnMethodPage.storePickupMPOwned')}
              cta_message={t('returnMethodPage.storeLocator')}
              cta_link={bayLocationsUrl}
            />
          </div>
        )}

        {isGeolocating ||
        dropoffLocationsLoading ||
        (userInterfaceSettingsQuery.loading && !userInterfaceSettingsQuery.data) ? (
          <>
            <Placeholder width={'100%'} height={200} />
            <br />
            <br />
            <Placeholder width={'100%'} height={200} />
          </>
        ) : (
          <>
            {/* Banner */}
            {storeConfig?.merchantReturnMethodPageBanner && bannerMessage && (
              <div className={styles.alertWrapper}>
                <Alert
                  message={bannerMessage ?? ''}
                  cta_link={bannerCtaLink}
                  cta_message={bannerCtaMessage}
                  iconComponent={<InfoIcon size={20} />}
                />
              </div>
            )}

            {isLocationsListVisible ? (
              <>
                <LocationsList
                  dropoffLocations={dropoffLocations}
                  showLocationsModal={handleShowOtherLocationsClick}
                  handleContinueClick={handleContinueClick}
                />
              </>
            ) : (
              <>
                {/* Dropoff Option*/}
                {canDropoff && <SelectableDropOffItem />}

                {/* Mail-in Option */}
                {canMailin && <SelectableMailInItem />}
              </>
            )}

            <DropoffLocationsModal
              isVisible={showOtherLocations}
              onClose={() => setShowOtherLocations(false)}
              onPick={(locationId: string) => {
                setDropOffLocationId(locationId);
              }}
              value={dropoffLocationId}
              canMailin={canMailin}
              handleMailInClick={handleMailInClick}
              handleContinueClick={handleContinueClick}
              setIsLocationsListVisible={save}
            />
            <ContactInfoModal
              isVisible={showContactInfo}
              onClose={() => setShowContactInfo(false)}
            />
            <ReturnMethodChangeAddressModal
              isVisible={showReturnMethodChangeAddressModal}
              onClose={() => setShowReturnMethodChangeAddressModal(false)}
              onSubmit={handleChangeAddressModalContinueClick}
            />
          </>
        )}
      </DefaultAppPage>
      {!isLocationsListVisible && (
        <ContinueCtaWrapper
          continueButton={
            <Button
              isWorking={
                getShippingEstimateApi.loading ||
                dropoffLocationsLoading ||
                validateAddressMutation.loading
              }
              disabled={dropoffLocationsLoading || validateAddressMutation.loading}
              onClick={() => handleContinueClick(dropoffLocation?.uid)}
            >
              {t('common.continue')}
            </Button>
          }
        />
      )}
    </>
  );
};
