import { useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  CANADA_COUNTRY_CODE,
  DEFAULT_ADDRESS_RESIDENTIAL_INDICATOR,
  DEFAULT_PHONE_NUMBER,
  ReturnMethods,
} from 'app/constants';
import { AddressContext, useStoreConfig, useStoreOrder } from 'app/contexts';
import { useSaveAndNavigate } from 'app/hooks';
import { ExchangeOrReturn, ReturnFlowData } from 'app/types';
import {
  CreateReturnCaseWithOrderLookupParamsOpDocument,
  CreateReturnCaseWithOrderLookupParamsOpMutationVariables,
  InputMaybe,
  ReturnCaseItemInput,
  ReturnCaseItemReturnMethod,
  ReturnCaseMethods,
  ReturnMethodChoices,
  ReturnReasonReasonType,
  ReviewedPolicyRuleInput,
} from 'generated/graphql';
import { formatApolloError } from 'utils';
import {
  getItemPayoutMethods,
  getRefundOnlyItems,
  getStoreCreditOnlyItems,
  getTotalFeeAmountForItems,
  getValidLineItemReturnMethod,
  subtotalReducer,
  taxReducer,
} from 'utils/core';

interface LocationState {
  state: ReturnFlowData;
}

interface AugmentedReturnFlowData extends ReturnFlowData {
  hasPayoutItems: boolean;
  hasExchangeItems: boolean;

  // Flat fee properties
  hasFlatRateFee: boolean;
  hasFlatFeeRemitMethodsFilter?: ReturnMethodChoices[];
  flatRateFeeName?: string | null;
  hasDropOffFlatRateFee: boolean;
  hasMailInFlatRateFee: boolean;
  flatRateFeeAmountOriginal: number;
  flatRateFeeAmountForSelectedReturnMethod: number;
  isFlatFeeApplicable: boolean;
  flatFeeRemitMethodFilter: string[];

  /**
   * Returns true if the user has properly entered the required fields
   * taken from OrderLookupParamInput.
   */
  hasOrderData: boolean;
}

/**
 * Returns an object containing all stored returnData
 *
 * @returns ReturnDataState The currently stored returnData
 */
export const useReturnFlow = () => {
  const location = useLocation();
  const { state } = location as LocationState;
  const { save, saveAndNavigate } = useSaveAndNavigate();
  const { storeOrder } = useStoreOrder();
  const { addressInfo } = useContext(AddressContext);

  const {
    storeConfig,
    hasRefundFee,
    hasStoreCreditFee,
    hasExchangeFee,
    storeReturnReasons,
    returnAcceptanceCriteria,
  } = useStoreConfig();
  const [
    createReturnCaseWithLookupParams,
    { loading: isCreateReturnCaseWorking, error: creationError },
  ] = useMutation(CreateReturnCaseWithOrderLookupParamsOpDocument);
  const { t, i18n } = useTranslation();

  const shippingCountryCode = storeOrder?.shippingAddress?.countryCode;
  const orderNumber = storeOrder?.orderNumber;
  const augmentedReturnFlowData = useMemo<AugmentedReturnFlowData>(() => {
    const sessionId = state?.sessionId ?? '';
    const enableMethods = storeConfig?.returnMethod || [];
    const returnItems = state?.returnItems ?? [];
    const createdReturnCase = state?.createdReturnCase || null;
    const reviewedPolicyRules = state?.reviewedPolicyRules ?? [];
    const exchangeItems = returnItems.filter((r) => r.returnMethod === ExchangeOrReturn.Exchange);
    const payoutItems = returnItems.filter((r) => r.returnMethod === ExchangeOrReturn.Return);
    const payoutSubTotal = payoutItems.reduce(subtotalReducer, 0);
    const hasPayoutItems = payoutItems.length > 0;
    const hasExchangeItems = exchangeItems.length > 0;
    const isLocationsListVisible = state?.isLocationsListVisible ?? false;

    const isCanadianOrder = shippingCountryCode === CANADA_COUNTRY_CODE;
    const hasInboundShippingCanada = storeConfig?.hasInboundShippingFee?.CA;
    const hasInboundShippingUSA = storeConfig?.hasInboundShippingFee?.US;
    const hasInboundShippingFee = isCanadianOrder
      ? hasInboundShippingCanada
      : hasInboundShippingUSA;

    const eligibleStoreCreditItems = payoutItems.filter((row) => {
      const itemPayoutMethods = getItemPayoutMethods(row.lineItem, enableMethods);
      return itemPayoutMethods.includes(ReturnCaseItemReturnMethod.StoreCredit);
    });

    const eligibleReturnItems = payoutItems.filter((row) => {
      const itemPayoutMethods = getItemPayoutMethods(row.lineItem, enableMethods);
      return itemPayoutMethods.includes(ReturnCaseItemReturnMethod.Refund);
    });

    const storeCreditOnlyItems = getStoreCreditOnlyItems(payoutItems);

    const refundOnlyItems = getRefundOnlyItems(payoutItems);

    // remove items caught by rules
    const remainingItems = payoutItems
      .filter((x) => !refundOnlyItems.includes(x))
      .filter((x) => !storeCreditOnlyItems.includes(x));

    const storeCreditOnlyItemsFee =
      hasStoreCreditFee && payoutItems.length > 0
        ? getTotalFeeAmountForItems(storeCreditOnlyItems, storeConfig?.fees?.storeCredit)
        : 0;

    const refundOnlyItemsFee =
      hasRefundFee && payoutItems.length > 0
        ? getTotalFeeAmountForItems(refundOnlyItems, storeConfig?.fees?.refund)
        : 0;

    const exchangeOnlyItemsFee =
      hasExchangeFee && exchangeItems.length > 0
        ? getTotalFeeAmountForItems(exchangeItems, storeConfig?.fees?.exchange)
        : 0;

    const remainingItemFeeRefund = getTotalFeeAmountForItems(
      remainingItems,
      storeConfig?.fees?.refund
    );
    const remainingItemFeeStoreCredit = getTotalFeeAmountForItems(
      remainingItems,
      storeConfig?.fees?.storeCredit
    );

    const storeCreditSubtotal = storeCreditOnlyItems.reduce(subtotalReducer, 0);
    const refundSubtotal = refundOnlyItems.reduce(subtotalReducer, 0);
    const hasStoreCreditOnlyItems = storeCreditOnlyItems && storeCreditOnlyItems.length > 0;
    const hasRefundOnlyItems = refundOnlyItems && refundOnlyItems.length > 0;

    const totalStoreCreditFee = remainingItemFeeStoreCredit + storeCreditOnlyItemsFee;
    const totalRefundFee = remainingItemFeeRefund + refundOnlyItemsFee;
    const totalTaxes = payoutItems.reduce(taxReducer, 0);

    const returnCaseItemInput = returnItems.map((item): ReturnCaseItemInput => {
      const itemReturnMethod = item.returnMethod;
      const lineItemPayoutMethods = getItemPayoutMethods(item.lineItem, enableMethods);
      const lineItemReturnMethod = getValidLineItemReturnMethod(
        lineItemPayoutMethods,
        itemReturnMethod,
        state?.payoutMethod
      );

      const returnReasonEnumFromUID = (uid: string): ReturnReasonReasonType | undefined => {
        const returnReason = storeReturnReasons?.find((reason) => reason.uid === uid);
        return returnReason?.reasonType;
      };

      const itemEvaluations = returnAcceptanceCriteria?.flatMap((c) => {
        const reasonType = item.returnReasonUid
          ? returnReasonEnumFromUID(item.returnReasonUid)
          : undefined;

        const defaultForReason = reasonType ? !c.autoFalseIfReasonIn.includes(reasonType) : true;

        return {
          criteriaUid: c.uid,
          passed: item.itemDeclaration?.[c.uid] ?? defaultForReason,
        };
      });

      return {
        orderLineItemId: item.lineItem.id,
        quantity: 1,
        lineItemJson: item.lineItem,
        returnReason:
          storeReturnReasons?.find((reason) => reason.uid === item.returnReasonUid)?.text || '',
        returnReasonUid: item.returnReasonUid ?? '',
        returnMethod: lineItemReturnMethod ?? '',
        unitPrice: Number(
          (item.lineItem.subtotal + item.lineItem.totalTax) / item.lineItem.quantity
        ),
        comment: item.additionalComments,
        fulfilledDate: item.lineItem.fulfilledDate,
        exchangeVariantId: item.exchangeVariantId || item.lineItem.variant?.id,
        itemsEvaluations: itemEvaluations,
      };
    });

    const orderLookupInput = state?.orderLookupInput ?? [];

    const isOrderLookupInputValid = orderLookupInput.every((param) => {
      return !!param.value;
    });

    // For now, we're assuming you can only select items from a single "store".
    // For D2C stores, the line item store will always match the top-level store.
    // For marketplace providers, the line item store will match all other line
    // item stores (we'll always only have items from the marketplace provider,
    // or only items from a single marketplace seller).
    // This set up will probably last for a pretty long time.
    const storeOrMarketplaceSeller = returnItems[0]?.lineItem?.store;

    const flatRateFeeConfig = storeOrMarketplaceSeller?.storeConfig?.fees?.flatRate;
    const flatRateFeeAmountOriginal = parseFloat(flatRateFeeConfig?.feeAmount || '0');
    const hasFlatRateFee = flatRateFeeAmountOriginal > 0;
    const hasMailInFlatRateFee =
      !!storeOrMarketplaceSeller?.storeConfig?.fees?.flatRate?.returnMethods.some(
        (e) => e === ReturnCaseMethods.MailIn
      );
    const hasDropOffFlatRateFee =
      !!storeOrMarketplaceSeller?.storeConfig?.fees?.flatRate?.returnMethods.some(
        (e) => e === ReturnCaseMethods.DropOff
      );
    const flatRateFeeName = i18n.resolvedLanguage.startsWith('fr')
      ? flatRateFeeConfig?.feeNameFr ?? flatRateFeeConfig?.feeName
      : flatRateFeeConfig?.feeNameEn ?? flatRateFeeConfig?.feeName;

    // TODO: We should stop using the super old dropOff and mailIn enum and
    // instead use the one matching the backend DROP_OFF and MAIL_IN enum
    const selectedReturnMethodAsEnumValue: ReturnCaseMethods =
      state?.returnMethod === ReturnMethods.DropOff
        ? ReturnCaseMethods.DropOff
        : ReturnCaseMethods.MailIn;

    const isFlatFeeApplicableForReturnChannel = !!flatRateFeeConfig?.returnMethods.some(
      (flatRateMethod) => flatRateMethod === selectedReturnMethodAsEnumValue
    );

    const flatFeeRemitMethodFilter =
      flatRateFeeConfig?.returnRemitMethods.map((x) => x.toString()) ?? [];

    const isFlatFeeApplicableBasedOnReturnRemitMethod =
      flatFeeRemitMethodFilter.length === 0 ||
      returnCaseItemInput.some((item) => flatFeeRemitMethodFilter.includes(item.returnMethod));

    const isFlatFeeApplicable =
      isFlatFeeApplicableForReturnChannel && isFlatFeeApplicableBasedOnReturnRemitMethod;

    const flatRateFeeAmountForSelectedReturnMethod = isFlatFeeApplicable
      ? flatRateFeeAmountOriginal
      : 0;

    return {
      ...state,
      sessionId,
      orderNumber: orderNumber ?? '',
      createdReturnCase,
      hasFlatFeeRemitMethodsFilter: flatRateFeeConfig?.returnRemitMethods,
      totalTaxes,
      returnItems,
      payoutSubTotal,
      refundSubtotal,
      storeCreditSubtotal,
      hasPayoutItems,
      hasExchangeItems,
      eligibleStoreCreditItems,
      eligibleReturnItems,
      storeCreditOnlyItemsFee,
      hasStoreCreditOnlyItems,
      hasRefundOnlyItems,
      refundOnlyItems,
      storeCreditOnlyItems,
      refundOnlyItemsFee,
      exchangeOnlyItemsFee,
      remainingItems,
      remainingItemFeeRefund,
      remainingItemFeeStoreCredit,
      reviewedPolicyRules,
      totalStoreCreditFee,
      totalRefundFee,
      returnCaseItemInput,
      hasInboundShippingFee,
      isCanadianOrder,
      hasFlatRateFee,
      flatRateFeeName,
      hasDropOffFlatRateFee,
      hasMailInFlatRateFee,
      flatRateFeeAmountOriginal,
      isFlatFeeApplicable,
      flatRateFeeAmountForSelectedReturnMethod,
      isLocationsListVisible,
      flatFeeRemitMethodFilter,

      // Convenience property for checking to make sure the first screen has been completed
      hasOrderData: isOrderLookupInputValid,
    };
  }, [
    state,
    hasRefundFee,
    hasStoreCreditFee,
    hasExchangeFee,
    storeConfig,
    shippingCountryCode,
    storeReturnReasons,
    orderNumber,
    returnAcceptanceCriteria,
    i18n.resolvedLanguage,
  ]);

  const createReturnCase = useCallback(async () => {
    const { shippingAddress, isAdmin, cognitoTokenFromRetailerAdmin, sessionId } =
      augmentedReturnFlowData;
    const returnCaseReturnMethod: ReturnCaseMethods =
      augmentedReturnFlowData.returnMethod === ReturnMethods.DropOff
        ? ReturnCaseMethods.DropOff
        : ReturnCaseMethods.MailIn;

    const reviewedPolicyRules = augmentedReturnFlowData.reviewedPolicyRules.map(
      (original): InputMaybe<ReviewedPolicyRuleInput> => ({
        accepted: original.accepted ?? false,
        uid: original.uid,
      })
    );

    // Let's see if we have overridden address information.
    const address1 = addressInfo?.address1 ?? shippingAddress.address1 ?? '';
    const address2 = addressInfo?.address2 ?? shippingAddress.address2 ?? '';
    const city = addressInfo?.city ?? shippingAddress?.city ?? '';
    const country = addressInfo?.countryCode ?? shippingAddress?.countryCode ?? '';
    const province = addressInfo?.provinceCode ?? shippingAddress?.provinceCode ?? '';
    const zipCode = addressInfo?.zipCode ?? shippingAddress?.zipCode ?? '';
    const addressResidentialIndicator =
      addressInfo?.addressResidentialIndicator ?? DEFAULT_ADDRESS_RESIDENTIAL_INDICATOR;

    const payload: CreateReturnCaseWithOrderLookupParamsOpMutationVariables = {
      consumerSessionId: sessionId,
      lookupParams: augmentedReturnFlowData.orderLookupInput ?? [],
      address1: address1,
      address2: address2,
      addressResidentialIndicator: addressResidentialIndicator,
      city: city,
      country: country,
      // currency: storeOrder?.currency,
      firstName: shippingAddress?.firstName ?? '',
      lastName: shippingAddress?.lastName ?? '',
      locationId: augmentedReturnFlowData.dropoffLocationId,
      method: returnCaseReturnMethod,
      // TODO: check why this was being sent:
      // orderId: storeOrder?.orderId ?? '',
      orderJson: {
        // This `getStoreOrder` property should not be renamed as it will break
        // the admin.
        getStoreOrder: storeOrder,
      },
      phone: shippingAddress?.phone || DEFAULT_PHONE_NUMBER,
      province: province,
      returnItems: augmentedReturnFlowData.returnCaseItemInput,
      reviewedPolicyRules,
      storeId: storeOrder?.storeId ?? '',
      zipCode: zipCode,
    };

    if (isAdmin && cognitoTokenFromRetailerAdmin) {
      return createReturnCaseWithLookupParams({
        variables: payload,
        context: {
          headers: {
            authorization: cognitoTokenFromRetailerAdmin,
          },
        },
      });
    }

    return createReturnCaseWithLookupParams({
      variables: payload,
    });
  }, [augmentedReturnFlowData, storeOrder, createReturnCaseWithLookupParams, addressInfo]);

  const formattedCreationErrors = useMemo(() => {
    return formatApolloError(creationError, t);
  }, [creationError, t]);

  return {
    ...augmentedReturnFlowData,
    save,
    saveAndNavigate,
    /**
     * A function that will create a return case from AugmentedReturnFlowData
     */
    createReturnCase,
    formattedCreationErrors,
    isCreateReturnCaseWorking,
  };
};
