import clsx from 'clsx';
import { useState } from 'react';

import { IconInfoCircle } from 'icons';
import { outlineSuppressionHandlers } from 'ui/helpers';

import { ReactComponent as TooltipArrow } from './tooltipArrow.svg';
import styles from './TooltipButton.module.scss';

interface TooltipButtonProps {
  /**
   * Used to specify an `aria-label` for the <button> element, required for accessibility
   */
  a11yText: string;

  /**
   * The content to display inside the tooltipp
   */
  children: React.ReactNode;

  /**
   * An optional className to be applied to the outer container element
   */
  className?: string;

  /**
   * Tooltip position. The first word is the position on the big screen, the second word is the position on the small screens
   */
  position?: string;

  /**
   * An optional message to be located to the right of the IconInfoCircle
   */
  message?: string;
}

export const TooltipButton = ({
  a11yText,
  className,
  children,
  position,
  message,
}: TooltipButtonProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let positionTooltipArrow = styles.tooltipArrow;
  let positionTooltip = styles.tooltip;

  switch (position) {
    case 'center right':
      positionTooltipArrow = styles.tooltipArrowCenterRight;
      positionTooltip = styles.tooltipCenterRight;
      break;
    default:
      break;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <button
        {...outlineSuppressionHandlers}
        className={styles.button}
        type="button"
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseDown={outlineSuppressionHandlers.onMouseDown}
        onBlur={(e: React.FocusEvent<HTMLButtonElement>) => {
          setShowTooltip(false);
          e.currentTarget.style.outline = '';
          e.currentTarget.style.boxShadow = '';
        }}
        aria-label={a11yText}
      >
        <div className={styles.icon}>
          <IconInfoCircle />
        </div>
        {message && <div className={styles.message}>{message}</div>}
      </button>

      <div className={clsx(positionTooltip, showTooltip && styles.visible)}>
        <TooltipArrow className={positionTooltipArrow} />
        <div className={styles.tooltipContent}>{children}</div>
      </div>
    </div>
  );
};
