import { ThemeConfigInterface } from '../app/types';

export const paletteWithRetailerTheme = (theme?: ThemeConfigInterface | null) => {
  return {
    primary: {
      main: theme?.buttonBackgroundColor ?? '#5779d9',
      contrastText: theme?.buttonTextColor ?? '#fff',
    },
    secondary: {
      main: '#9C27B0',
    },
    error: {
      main: '#cd281d',
    },
    warning: {
      main: '#17110c',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FAFAFA',
    },
  };
};
