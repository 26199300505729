import { useMemo, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { round } from 'lodash-es';

import { useStoreConfig, useStoreOrder } from 'app/contexts';
import {
  useRedirectToErrorPage,
  useReturnFlow,
  useAnalytics,
  useScrollToTopOnMount,
} from 'app/hooks';
import { DefaultAppPage } from 'layouts';
import { Button, Placeholder, SelectableItem, TooltipButton } from 'ui';
import { formatPrice } from 'utils/number';
import { ReturnCaseItemReturnMethod } from 'generated/graphql';
import ContinueCtaWrapper from 'app/components/ContinueCtaWrapper';
import i18n from 'app/locales';

import styles from './PayoutMethod.module.scss';

export const PayoutMethod = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    payoutMethod: payoutMethodState,
    payoutSubTotal,
    storeCreditSubtotal,
    save,
    saveAndNavigate,
    hasFlatRateFee,
    flatRateFeeAmountOriginal,
    flatRateFeeName,
    hasOrderData,
    returnItems,
    refundSubtotal,
    storeCreditOnlyItemsFee,
    hasStoreCreditOnlyItems,
    eligibleStoreCreditItems,
    eligibleReturnItems,
    hasRefundOnlyItems,
    refundOnlyItemsFee,
    remainingItemFeeRefund,
    remainingItemFeeStoreCredit,
    totalStoreCreditFee,
    storeCreditOnlyItems,
    hasExchangeItems,
    isFlatFeeApplicable,
    flatFeeRemitMethodFilter,
  } = useReturnFlow();

  const { storeOrder, isInitializing: isStoreOrderLoading } = useStoreOrder();
  const currency = storeOrder?.currency;

  // Checks for items that can apply to the respective refund method
  const hasEligibleReturnItems = eligibleReturnItems.length > 0;
  const hasEligibleStoreCreditItems = eligibleStoreCreditItems.length > 0;

  // scroll to content on back
  useScrollToTopOnMount();
  // show store credit selection if there are items that can be selected for store credit
  const hasRemainingStoreCreditItems =
    hasEligibleStoreCreditItems && eligibleStoreCreditItems.length > storeCreditOnlyItems.length;

  // show store credit selection if only store credit items
  const onlyStoreCreditItems = !hasRefundOnlyItems && hasEligibleStoreCreditItems;

  const [payoutMethod, setPayoutMethod] = useState(payoutMethodState);
  const { storeConfig, hasStoreCreditFee, hasRefundFee } = useStoreConfig();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.returnPaymentPageViewed(returnItems.length);
    // This needs to be disabled as we only want to track when the component mounts, and not
    // when the selectedItemList changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent the user from going to steps after this current one if they landed
  // here from future steps. Currently, changing the payout method will only
  // persist AFTER the user presses the continue button. Therefore, they
  // shouldn't be able to move over to the "Review" step via the stepper.
  useEffect(() => {
    save({
      payoutMethod: undefined,
    });
    // We only run this hook on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bonusAmount = Number(storeConfig?.bonusAmount) || 0;

  // Bonus' don't apply if items are caught by rules
  const eligibleBonusAmount =
    payoutMethod === ReturnCaseItemReturnMethod.Refund ||
    hasStoreCreditOnlyItems ||
    hasRefundOnlyItems
      ? 0
      : bonusAmount;

  const storeCreditOnlyAmount = storeCreditSubtotal - storeCreditOnlyItemsFee;
  const refundOnlyAmount = refundSubtotal - refundOnlyItemsFee;

  const payoutAmount = useMemo(() => {
    let remainingAmount = payoutSubTotal;
    if (hasStoreCreditOnlyItems) {
      remainingAmount -= Number(storeCreditSubtotal);
    }
    if (hasRefundOnlyItems) {
      remainingAmount -= Number(refundSubtotal);
    }

    // This fixes the large amount of decimals caused by JS
    return round(remainingAmount, 2);
  }, [
    payoutSubTotal,
    hasStoreCreditOnlyItems,
    hasRefundOnlyItems,
    refundSubtotal,
    storeCreditSubtotal,
  ]);

  const handleContinueClick = () => {
    saveAndNavigate('/return/review', {
      payoutMethod,
    });
  };

  // ensures the value of items caught by rules are greater than fees
  // if they can't be invoiced with an exchange item
  const invalidStoreCreditOnlyAmount = storeCreditOnlyAmount < 0 && !hasExchangeItems;
  const invalidRefundOnlyAmount = refundOnlyAmount < 0 && !hasExchangeItems;

  // Show the errors if the fees for the items caught by rules or if the remaining items are greater than the credit
  const storeCreditError =
    (storeCreditOnlyItemsFee + remainingItemFeeStoreCredit > payoutSubTotal && !hasExchangeItems) ||
    (payoutMethod === ReturnCaseItemReturnMethod.Refund && invalidStoreCreditOnlyAmount);

  const refundError =
    (remainingItemFeeRefund + refundOnlyItemsFee > payoutSubTotal && !hasExchangeItems) ||
    (payoutMethod === ReturnCaseItemReturnMethod.StoreCredit && invalidRefundOnlyAmount);
  // Show a warning letting the user know they will be invoiced for the fees on the exchange items
  const storeCreditFeeWarning = hasExchangeItems && storeCreditError;
  const refundFeeWarning = hasExchangeItems && refundError;

  const disableContinue = hasExchangeItems
    ? false
    : (payoutMethod === ReturnCaseItemReturnMethod.StoreCredit && storeCreditError) ||
      (payoutMethod === ReturnCaseItemReturnMethod.Refund && refundError) ||
      !payoutMethod;

  // the payout amount shows the value of the items that are not covered by rules
  // This amount should only consider if all items were selected as store credit
  // or the items that must be store credit and if the rest of the items are refunds.
  const amountLessRelevantFees =
    payoutAmount -
    (payoutMethod === ReturnCaseItemReturnMethod.StoreCredit
      ? totalStoreCreditFee
      : remainingItemFeeRefund + storeCreditOnlyItemsFee) +
    eligibleBonusAmount;

  const payoutAmountLessRelevantFees = currency
    ? formatPrice({
        amount: amountLessRelevantFees,
        currency: currency,
      })
    : null;

  useRedirectToErrorPage({
    when: !hasOrderData || !returnItems.length,
  });

  const itemSelectionLink = (
    <NavLink className={styles.errorMessage} to="/return/select-items" state={location.state}>
      {t('payoutMethodPage.removeItems')}
    </NavLink>
  );

  const flatRateBox = () => {
    return (
      <>
        <div className={styles.divider} />
        <div className={styles.optionContainer}>
          <div className={styles.optionInfo}>
            <div className={styles.optionTitle}>{flatRateFeeName}</div>
            {storeConfig?.fees?.flatRate?.feeReason}
          </div>
          <div className={styles.feeAmount}>
            {currency ? (
              `- ${formatPrice(
                { amount: flatRateFeeAmountOriginal, currency },
                undefined,
                i18n.language
              )}`
            ) : (
              <Placeholder width={48} />
            )}
          </div>
        </div>
      </>
    );
  };

  const isLoading = isStoreOrderLoading || !currency;

  return (
    <>
      <DefaultAppPage title={t('payoutMethodPage.title')}>
        {isLoading ? (
          <>
            <Placeholder width={'100%'} height={200} />
            <br />
            <br />
            <Placeholder width={'100%'} height={200} />
          </>
        ) : (
          <>
            {hasStoreCreditOnlyItems && (
              <div className={styles.paymentAmounts}>
                <span>
                  <Trans
                    i18nKey="payoutMethodPage.storeCreditAmount"
                    /* eslint-disable-next-line react/jsx-key */
                    components={[<strong />]}
                    values={{
                      amount: formatPrice({ amount: storeCreditOnlyAmount, currency }),
                    }}
                  />
                </span>
                <TooltipButton a11yText={t('payoutMethodPage.storeCreditOnlyWarning')}>
                  {t('payoutMethodPage.storeCreditOnlyWarning')}
                </TooltipButton>
              </div>
            )}
            {hasRefundOnlyItems && (
              <div className={styles.paymentAmounts}>
                <span>
                  <Trans
                    i18nKey="payoutMethodPage.payoutAmount"
                    /* eslint-disable-next-line react/jsx-key */
                    components={[<strong />]}
                    values={{ amount: formatPrice({ amount: refundOnlyAmount, currency }) }}
                  />
                </span>
                <TooltipButton a11yText={t('payoutMethodPage.refundOnlyWarning')}>
                  {t('payoutMethodPage.refundOnlyWarning')}
                </TooltipButton>
              </div>
            )}
            {payoutAmountLessRelevantFees ? (
              <div className={styles.payoutAmount}>
                <Trans
                  i18nKey={
                    hasRefundOnlyItems || hasStoreCreditOnlyItems
                      ? 'payoutMethodPage.remainingAmount'
                      : 'payoutMethodPage.totalPayout'
                  }
                  /* eslint-disable-next-line react/jsx-key */
                  components={[<strong />]}
                  values={{
                    amount: payoutAmountLessRelevantFees,
                  }}
                />
              </div>
            ) : null}

            <div className={styles.options}>
              {(hasRemainingStoreCreditItems || onlyStoreCreditItems) && (
                <SelectableItem
                  className={styles.option}
                  isSelected={payoutMethod === ReturnCaseItemReturnMethod.StoreCredit}
                  onClick={() => setPayoutMethod(ReturnCaseItemReturnMethod.StoreCredit)}
                >
                  <div className={styles.optionContainer}>
                    <div className={styles.optionInfo}>
                      <div className={styles.optionTitle}>
                        {t('payoutMethodPage.storeCreditTitle')}
                      </div>
                      {t('payoutMethodPage.storeCreditInfo')}
                    </div>
                    {/*  bonus amounts don't apply if the user has items caught by rules */}
                    {hasStoreCreditOnlyItems || hasRefundOnlyItems || !bonusAmount ? null : (
                      <div className={styles.bonusAmount}>
                        + {formatPrice({ amount: bonusAmount, currency })}{' '}
                        {t('payoutMethodPage.bonus')}
                      </div>
                    )}
                  </div>
                  {hasFlatRateFee &&
                    isFlatFeeApplicable &&
                    flatFeeRemitMethodFilter.includes(ReturnCaseItemReturnMethod.StoreCredit) &&
                    flatRateBox()}

                  {hasStoreCreditFee && totalStoreCreditFee ? (
                    <>
                      <div className={styles.divider} />
                      <div className={styles.optionContainer}>
                        <div className={styles.optionInfo}>
                          <div className={styles.optionTitle}>
                            {storeConfig?.fees?.storeCredit.feeName}
                          </div>
                          {storeConfig?.fees?.storeCredit.feeReason}
                        </div>
                        <div className={styles.feeAmount}>
                          - {formatPrice({ amount: totalStoreCreditFee, currency })}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {storeCreditError || storeCreditFeeWarning ? (
                    <div>
                      <div className={styles.optionContainer}>
                        <div className={styles.optionInfo}>
                          <div className={styles.errorMessage}>
                            <Trans
                              i18nKey={
                                storeCreditFeeWarning
                                  ? 'payoutMethodPage.feeWarning'
                                  : storeCreditError
                                  ? 'payoutMethodPage.creditAmountError'
                                  : ''
                              }
                              t={t}
                              components={[itemSelectionLink]}
                              values={{ itemSelectionLink: t('payoutMethodPage.removeItems') }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </SelectableItem>
              )}

              {hasEligibleReturnItems && (
                <SelectableItem
                  className={styles.option}
                  isSelected={payoutMethod === ReturnCaseItemReturnMethod.Refund}
                  onClick={() => setPayoutMethod(ReturnCaseItemReturnMethod.Refund)}
                >
                  <div className={styles.optionContainer}>
                    <div className={styles.optionInfo}>
                      <div className={styles.optionTitle}>
                        {t('payoutMethodPage.originalPaymentMethodTitle')}
                      </div>
                      {t('payoutMethodPage.originalPaymentMethodInfo')}
                    </div>
                  </div>

                  {hasFlatRateFee &&
                    isFlatFeeApplicable &&
                    flatFeeRemitMethodFilter.includes(ReturnCaseItemReturnMethod.Refund) &&
                    flatRateBox()}

                  {hasRefundFee && (remainingItemFeeRefund || refundOnlyItemsFee) ? (
                    <>
                      <div className={styles.divider} />
                      <div className={styles.optionContainer}>
                        <div className={styles.optionInfo}>
                          <div className={styles.optionTitle}>
                            {storeConfig?.fees?.refund.feeName}
                          </div>
                          {storeConfig?.fees?.refund.feeReason}
                        </div>
                        <div className={styles.feeAmount}>
                          -{' '}
                          {formatPrice({
                            amount: remainingItemFeeRefund || refundOnlyItemsFee,
                            currency,
                          })}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {hasStoreCreditFee && storeCreditOnlyItemsFee ? (
                    <div className={styles.optionContainer}>
                      <div className={styles.optionInfo}>
                        <div className={styles.optionTitle}>
                          {storeConfig?.fees?.storeCredit.feeName}
                        </div>
                        {storeConfig?.fees?.storeCredit.feeReason}
                      </div>
                      <div className={styles.feeAmount}>
                        -{' '}
                        {formatPrice({
                          amount: storeCreditOnlyItemsFee,
                          currency,
                        })}
                      </div>
                    </div>
                  ) : null}
                  {refundError || refundFeeWarning ? (
                    <div>
                      <div className={styles.optionContainer}>
                        <div className={styles.optionInfo}>
                          <div className={styles.errorMessage}>
                            <Trans
                              i18nKey={
                                refundFeeWarning
                                  ? 'payoutMethodPage.feeWarning'
                                  : refundError
                                  ? 'payoutMethodPage.creditAmountError'
                                  : ''
                              }
                              t={t}
                              components={[itemSelectionLink]}
                              values={{ itemSelectionLink: t('payoutMethodPage.removeItems') }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </SelectableItem>
              )}
            </div>
          </>
        )}
      </DefaultAppPage>
      <ContinueCtaWrapper
        continueButton={
          <Button onClick={handleContinueClick} disabled={disableContinue}>
            {t('common.continue')}
          </Button>
        }
      />
    </>
  );
};
