import { gql } from '@apollo/client';

import {
  GetStoreConfigDocument,
  GetStoreOrderWithLookupParamsDocument,
  ListAcceptanceCriteriaDocument,
} from 'generated/graphql';

export const getStoreConfigQuery = GetStoreConfigDocument;

export const ListAcceptanceCriteriaQuery = ListAcceptanceCriteriaDocument;

export const getThemeConfigQuery = gql`
  query getThemeConfig($subdomain: String!) {
    getThemeConfig(subdomain: $subdomain) {
      subdomain
      name
      title
      backgroundImageUrl
      logoImageUrl
      headerImageUrl
      backgroundColor
      headerBackgroundColor
      buttonBackgroundColor
      buttonTextColor
      linkTextColor
      defaultFontFamily
      welcomeMessage
      welcomeMessage_en
      welcomeMessage_fr
    }
  }
`;

export const getStoreOrderWithLookupParamsGql = GetStoreOrderWithLookupParamsDocument;

export const getStorePolicyRules = gql`
  query GetStorePolicyRules {
    getStorePolicyRules {
      uid
      rule
      store {
        uid
        name
        sellerType
      }
    }
  }
`;

export const getDropOffLocations = gql`
  query getLocations($storeUid: UUID) {
    getLocations(storeUid: $storeUid) {
      uid
      name
      isActive
      instructions
      address
      hoursOfOperationUrl
      coordinates {
        lat
        lng
      }
    }
  }
`;

export const getStoreReturnReason = gql`
  query getStoreReturnReasons {
    getStoreReturnReasons {
      returnReasons {
        uid
        text
        sortOrder
        reasonType
      }
    }
  }
`;

export const returnMethodsUserInterfaceSettings = gql`
  query ReturnMethodsUserInterfaceSettings(
    $storeId: Int!
    $consumerLatitude: Float!
    $consumerLongitude: Float!
  ) {
    returnMethodsUserInterfaceSettings(
      storeId: $storeId
      consumerLatitude: $consumerLatitude
      consumerLongitude: $consumerLongitude
    ) {
      dropOffIsPreferred
    }
  }
`;
