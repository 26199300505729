import { Icon, IconProps } from './Icon';

export const IconArrowLeft = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 20 16">
    <path
      d="M8 15L1 8M1 8L8 1M1 8H19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
