import { useMemo } from 'react';
import clsx from 'clsx';

import styles from './Placeholder.module.scss';

interface PlaceholderProps {
  /**
   * If specified, appends an additional className to the container element
   */
  className?: string;

  /**
   * If specified, renders the element with reduced opacity
   */
  dark?: boolean;

  /**
   * Specifies the height of the placeholder
   */
  height?: number | string;

  /**
   * Specifies the width of the placeholder element
   */
  width?: number | string;
}

/**
 * Renders a UI Placeholder in the specified size
 */
export const Placeholder = ({ className, dark, height, width }: PlaceholderProps) => {
  const style = useMemo(() => ({ width, height }), [width, height]);

  return <div className={clsx(styles.container, dark && styles.dark, className)} style={style} />;
};

Placeholder.defaultProps = {
  width: 64,
  height: 16,
};
