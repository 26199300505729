import clsx from 'clsx';

import styles from './ThreeDots.module.scss';

interface ThreeDotsProps {
  /**
   * An optional className to be appended to the outer element
   */
  className?: string;
}

/**
 * Renders a loading indicator that looks like 3 pulsing dots
 *
 * @returns React.ReactNode
 */
export const ThreeDots = ({ className }: ThreeDotsProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.dots} data-testid="three-dots" />
  </div>
);
