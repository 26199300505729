import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// We use dayjs to do datetime calculations
// We extend it here for utc to get access everywhere else
// some dates are provided as utc strings
dayjs.extend(utc);

import { createMonitor } from 'utils/monitor';

import App from './app';
import reportWebVitals from './reportWebVitals';
import './index.scss';

createMonitor();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
