import { useMemo } from 'react';

import { ReturnMethods } from 'app/constants';
import { useRedirectToErrorPage, useReturnFlow, useScrollToTopOnMount } from 'app/hooks';

import { DropOffConfirmation, MailInConfirmation, ManualApprovalConfirmation } from './components';

export const ReturnConfirmation = () => {
  const { hasOrderData, returnItems, returnMethod, reviewedPolicyRules } = useReturnFlow();

  const isAutoApproved = useMemo(
    () => reviewedPolicyRules.every((rule) => rule.accepted === true),
    [reviewedPolicyRules]
  );

  useScrollToTopOnMount();

  useRedirectToErrorPage({
    when: !hasOrderData || !returnItems.length,
  });

  if (!isAutoApproved) {
    return <ManualApprovalConfirmation returnMethod={returnMethod} />;
  } else if (returnMethod === ReturnMethods.MailIn) {
    return <MailInConfirmation />;
  } else {
    return <DropOffConfirmation />;
  }
};
