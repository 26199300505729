import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { TFunction } from 'react-i18next';

import { NETWORK_GENERIC_ERROR } from 'app/constants';
import { FormattedGraphQLError } from 'app/types';

/**
 * Formats the error from the backend
 *
 * @param err error
 * @returns { graphQLError: graphQLError,
 * message: formattedServerErrorMessage,
 * validationError: extensions?.validation_error
 * } error object
 */
export const formatApolloError = (
  err: ApolloError | undefined,
  t: TFunction
): FormattedGraphQLError | null => {
  if (!err) {
    return null;
  }

  if (err.networkError) {
    return {
      message: NETWORK_GENERIC_ERROR,
    };
  }
  const graphQLError = err.graphQLErrors?.[0];
  const extensions = graphQLError.extensions;
  const message = graphQLError.message;
  const errorCode = extensions?.error_code;
  // At the moment we send back a custom error code and an appropriate message.
  const formattedServerErrorMessage = errorCode ? message : t('errors.unexpected');

  return {
    graphQLError: graphQLError,
    message: formattedServerErrorMessage,
    errorCode,
    validationError: extensions?.validation_error,
  };
};

/**
 * Formats the error from the backend
 *
 * @param err error
 * @returns { graphQLError: graphQLError,
 * message: formattedServerErrorMessage,
 * validationError: extensions?.validation_error
 * } error object
 */
export const formatGraphQLErrors = (errors: readonly GraphQLError[]): FormattedGraphQLError => {
  const graphQLError = errors?.[0];
  const extensions = graphQLError.extensions;
  const errorCode = extensions?.error_code;

  return {
    graphQLError: graphQLError,
    message: graphQLError.message,
    errorCode,
    validationError: extensions?.validation_error,
  };
};
