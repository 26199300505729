import { Icon, IconProps } from './Icon';

export const IconArrowUpRight = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 16 16">
    <path d="M4 12L11.5 4.5" stroke={color} strokeWidth="1.25" />
    <path d="M5.1001 4.40005H11.6001V11" stroke={color} strokeWidth="1.25" fill="none" />
  </Icon>
);
