import { Icon, IconProps } from './Icon';

export const IconExternalLink = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 17 19">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1893 1.75L10 1.75001C9.58578 1.75001 9.25 1.41422 9.25 1.00001C9.25 0.585793 9.58578 0.250006 10 0.250006L15 0.25C15.1989 0.25 15.3897 0.329017 15.5303 0.46967C15.671 0.610322 15.75 0.801087 15.75 1V6C15.75 6.41421 15.4142 6.75 15 6.75C14.5858 6.75 14.25 6.41421 14.25 6V2.81066L8.53027 8.53034C8.23737 8.82323 7.7625 8.82323 7.46961 8.53033C7.17671 8.23744 7.17672 7.76257 7.46961 7.46967L13.1893 1.75Z"
      fill={color}
    />
    <path
      d="M6.28571 2H5C3.34315 2 2 3.34315 2 5V11C2 12.6569 3.34315 14 5 14H11C12.6569 14 14 12.6569 14 11V9.71429"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      fill="none"
    />
  </Icon>
);
