import clsx from 'clsx';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  /**
   * An optional className to be appanded to the outer container element
   */
  className?: string;

  /**
   * Determines the size of the rendered element
   */
  size: number;
}

export const Spinner = ({ className, size }: SpinnerProps) => (
  <svg
    className={clsx(styles.spinner, className)}
    width={size}
    height={size}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={styles.background}
      fill="none"
      strokeWidth="7"
      strokeLinecap="round"
      cx="25"
      cy="25"
      r="18"
    />
    <circle
      className={styles.path}
      fill="none"
      strokeWidth="7"
      strokeLinecap="round"
      cx="25"
      cy="25"
      r="18"
    />
  </svg>
);

Spinner.defaultProps = {
  size: 16,
};
