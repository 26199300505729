import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import React, { useMemo } from 'react';

import { useStoreConfig } from 'app/contexts';

import { typographyWithRetailerTheme } from './typography';
import { components } from './components';
import { paletteWithRetailerTheme } from './palette';

// theme configs based on:
// https://www.figma.com/file/r9k8Vwb0HaYJYVimNR4tCR/MUI-for-Figma-Material-Pro-v5.9.0

interface CustomThemeProviderProps {
  children?: React.ReactNode;
}

export const CustomThemeProvider: React.FC<React.ReactNode> = ({
  children,
}: CustomThemeProviderProps) => {
  const { themeConfig } = useStoreConfig();
  const theme = useMemo(() => {
    return createTheme({
      palette: paletteWithRetailerTheme(themeConfig),
      typography: typographyWithRetailerTheme(themeConfig),
      components,
    });
  }, [themeConfig]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
