import { useState } from 'react';
import clsx from 'clsx';

import styles from './AdditionalCommentsInput.module.scss';

interface AdditionalCommentsInputProps extends React.HTMLProps<HTMLTextAreaElement> {
  buttonText?: string;
  className?: string;
  labelText?: string;
}

export const AdditionalCommentsInput = ({
  buttonText,
  className,
  labelText,
  ...spreadProps
}: AdditionalCommentsInputProps) => {
  const hasInitialValue = !!spreadProps.value;
  const [showInput, setShowInput] = useState(hasInitialValue);

  return (
    <div className={clsx(styles.container, className)}>
      {!showInput && (
        <button className={styles.button} onClick={() => setShowInput(true)}>
          {buttonText}
        </button>
      )}

      {showInput && (
        <label>
          <div className={styles.label}>{labelText}</div>
          <textarea
            {...spreadProps}
            className={styles.textarea}
            // We actually want to autofocus on the text area field, since it
            // shows up when the user clicks "Add additional comments"
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus={!hasInitialValue}
          />
        </label>
      )}
    </div>
  );
};

AdditionalCommentsInput.defaultProps = {
  buttonText: '+ Add additional comments',
  labelText: 'Enter Additional Comments',
};
