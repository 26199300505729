import { Icon, IconProps } from './Icon';

export const IconTimes = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7197 0.21967C17.0126 -0.0732233 17.4874 -0.0732233 17.7803 0.21967C18.0732 0.512563 18.0732 0.987437 17.7803 1.28033L10.0607 9L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L9 10.0607L1.28033 17.7803C0.987436 18.0732 0.512564 18.0732 0.219669 17.7803C-0.0732231 17.4874 -0.0732231 17.0126 0.219669 16.7197L7.93934 9L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L9 7.93934L16.7197 0.21967Z"
      fill={color}
    />
  </Icon>
);
