import { Placeholder } from 'ui';

import styles from './ProductReturnCardPlaceholder.module.scss';

export const ProductReturnCardPlaceholder = () => (
  <div className={styles.container}>
    <div className={styles.checkbox}>
      <Placeholder height={20} width={20} />
    </div>

    <div className={styles.productThumbnail}>
      <Placeholder height={152} width={152} className={styles.image} />
    </div>

    <div className={styles.productDetails}>
      <div className={styles.productInfo}>
        <Placeholder height={28} width={123} className={styles.productName} />
        <Placeholder height={21} width={180} />
      </div>

      <div className={styles.price}>
        <Placeholder height={28} />
      </div>
    </div>
  </div>
);
