import { useSegmentContext } from 'app/contexts/SegmentAnalytics';
import { getSubdomain } from 'utils';

export const useAnalytics = () => {
  const { analytics } = useSegmentContext();

  const searchOrder = (orderId: string) => {
    analytics?.track('Order Searched', {
      orderId,
      subdomain: getSubdomain(),
    });
  };

  const returnSelectPageViewed = (totalItems: number) => {
    analytics?.track('Return Select Page Viewed', {
      totalItems,
      subdomain: getSubdomain(),
    });
  };

  const returnSelectPageCancelled = () => {
    analytics?.track('Return Select Page Cancelled', { subdomain: getSubdomain() });
  };

  const returnPaymentPageViewed = (totalItems: number) => {
    analytics?.track('Return Payment Page Viewed', {
      totalItems,
      subdomain: getSubdomain(),
    });
  };

  const returnReviewPageViewed = (reviewedItemsTotal: number) => {
    analytics?.track('Return Review Page Viewed', {
      reviewedItemsTotal,
      subdomain: getSubdomain(),
    });
  };

  const returnCreated = () => {
    analytics?.track('Return Created', { subdomain: getSubdomain() });
  };

  return {
    searchOrder,
    returnSelectPageViewed,
    returnSelectPageCancelled,
    returnPaymentPageViewed,
    returnReviewPageViewed,
    returnCreated,
  };
};
