import { ReturnMethods } from 'app/constants';
import {
  GetStoreOrderWithLookupParamsQuery,
  ReturnCaseItemReturnMethod as ReturnItemMethodEnum,
  ReturnCaseMethods,
  ReturnReasonReasonType,
  ReturnReasonType,
} from 'generated/graphql';

export interface CustomShippingLabelConfigInterface {
  canCreateCustomShippingLabel: boolean;
  defaultCourier: string;
}

export enum AmountTypes {
  PERCENT = 'PERCENT',
  NUMBER = 'NUMBER',
}

export enum OrderLineItemLineType {
  LESS_THAN_TRUCKLOAD = 'LTL',
  MAJOR_HOME_FASHION = 'MHF',
}

export interface BaseMethodFeeInterface {
  feeAmount: string;
  feeAmountType: AmountTypes;
  feeName: string;
  feeReason: string;
  feeNameEn?: string;
  feeNameFr?: string;
}

export interface StoreCreditFeeInterface extends BaseMethodFeeInterface {
  canEditStoreCreditFees: boolean;
}

export interface RefundFeeInterface extends BaseMethodFeeInterface {
  canEditRefundFees: boolean;
}

export interface FlatRateFeeInterface extends BaseMethodFeeInterface {
  canEditFlatRateFees: boolean;
  returnMethods: ReturnCaseMethods[];
}

export interface ExchangeFeeInterface extends BaseMethodFeeInterface {
  canEditExchangeFees: boolean;
  canEditExchangeShipping: boolean;
}

export interface StoreConfigFeesInterface {
  refund: RefundFeeInterface;
  exchange: ExchangeFeeInterface;
  storeCredit: StoreCreditFeeInterface;
  flatRate?: FlatRateFeeInterface;
}
export interface InboundShippingFeeType {
  US: boolean;
  CA: boolean;
}

export interface StoreConfigMerchantReturnMethodPageBanner {
  messageEn: string;
  messageFr: string;
  ctaMessageEn: string;
  ctaMessageFr: string;
  ctaLinkEn: string;
  ctaLinkFr: string;
}

export interface StoreConfigInterface {
  packingSlip: string;
  customerServiceEmail: string;
  retailerPolicyUrl: string;
  returnChannel: ReturnMethods[];
  returnWindow: string;
  returnPolicyUrl: string;
  returnPolicyUrl_en: string;
  returnPolicyUrl_fr: string;
  approvalDays: string;
  shoppingUrl: string;
  shoppingUrl_en: string;
  shoppingUrl_fr: string;
  mailInSurveyLink?: string;
  dropOffSurveyLink?: string;
  returnMethod: ReturnItemMethodEnum[];
  visibleEditReturnMethods?: [string];
  returnsPortalUrl?: string;
  bonusAmount: string;
  giftCardProductId?: string;
  canEditStoreConfigurations?: boolean;
  canEditRuleAutomation?: boolean;
  customShippingLabel?: CustomShippingLabelConfigInterface;
  shippingCourier: string;
  fees?: StoreConfigFeesInterface;
  hasInboundShippingFee?: InboundShippingFeeType;
  hasShippingLogisticFee?: boolean;
  hasTaxesWithheld?: boolean;
  orderLookupParams?: string[];
  merchantReturnMethodPageBanner: StoreConfigMerchantReturnMethodPageBanner;
}

export type ReturnReasonInterface = ReturnReasonType;

export interface StoreReturnReasonInterface {
  returnReasons: ReturnReasonInterface[];
}

export interface ThemeConfigInterface {
  backgroundColor: string;
  headerImageUrl: string;
  backgroundImageUrl: string;
  name: string;
  title: string;
  subdomain: string;
  logoImageUrl: string;
  headerBackgroundColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  linkTextColor: string;
  defaultFontFamily: string;
  welcomeMessage: string;
  welcomeMessage_en: string;
  welcomeMessage_fr: string;
}

export type PolicyRule = {
  accepted?: boolean;
  uid: string;
  rule: string;
};

/**
 * Return Item Method
 */

interface RuleOutcomeMessage {
  english: string;
  french?: string;
}
export enum RuleOutcomeKey {
  PAYOUT_METHOD = 'PAYOUT_METHOD',
}

export interface RuleOutcomeType {
  key: RuleOutcomeKey;
  payoutMethods: string[];
  target: ReturnItemMethodEnum[];
  message: RuleOutcomeMessage;
  value: any;
  valueType: string;
}

export interface Product {
  id: string;
  title: string;
  description: string;
  image: string;
  color: [string];
  size: [string];
}

export interface OrderVariantType {
  id: string;
  barcode: string;
  title: string;
  price: string;
  weight: string;
  weightUnit: string;
  image: string;
  color: string;
  size: string;
}

export interface CustomsType {
  id: string | undefined;
  countryCodeOfOrigin: string | undefined;
  harmonizedSystemCode: string | undefined;
  sku: string | undefined;
}

export interface ReturnConstraint {
  returnWindow: string;
}

export type StoreOrderWithLookupParams_LineItem_StoreType = NonNullable<
  NonNullable<GetStoreOrderWithLookupParamsQuery['getStoreOrderWithLookupParams']>['lineItems']
>[0]['store'];

export interface StoreOrderLineItem {
  id: string;
  name: string;
  quantity: number;
  refundableQuantity: number;
  returnCaseItemsQuantity: number;
  fulfilledDate: string;
  sku: string;
  requiresShipping: boolean;
  price: number;
  subtotal: number;
  totalTax: number;
  totalPrice: number;
  returnConstraint: ReturnConstraint;
  returnCaseExists: boolean;
  returnCaseStatus: string;
  ruleOutcome?: RuleOutcomeType[] | null;
  product: Product;
  variant: OrderVariantType;
  disableMessage?: string;
  uniqueId?: string;
  isRemitted?: boolean;
  customs?: CustomsType | null;
  lineType?: string | null;
  store: StoreOrderWithLookupParams_LineItem_StoreType;
}

export type ShippingAddress = {
  id: string;
  address1?: string | null | undefined;
  address2?: string | null | undefined;
  city?: string | null | undefined;
  company?: string | null | undefined;
  country?: string | null | undefined;
  countryCode?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  name?: string | null | undefined;
  province?: string | null | undefined;
  provinceCode?: string | null | undefined;
  zipCode?: string | null | undefined;
  phone?: string | null | undefined;
  addressResidentialIndicator?: string | null | undefined;
};

export interface StoreOrderInterface {
  storeId: string;
  storeUid: string;
  orderId: string;
  createdAt: string;
  email: string;
  orderNumber: string;
  closed: string;
  shippingAddress: ShippingAddress;
  fulfillmentStatus: string;
  currency: string;
  totalPrice: number;
  lineItems: [StoreOrderLineItem];
  // ruleOutcome: [Outcome];
}

export interface ReturnAcceptanceCriteria {
  uid: string;
  id: string;
  description: string;
  descriptionFr: string;
  displayOnConsumerPortal: boolean;
  autoFalseIfReasonIn: ReturnReasonReasonType[];
}

export interface OrderLookupParamInput {
  field: string;
  value: string;
}

export interface GetStoreOrderWithLookupParamsQueryResult {
  getStoreOrderWithLookupParams: StoreOrderInterface;
}

export interface GetStoreOrderWithLookupParamsQueryVariables {
  lookupParams?: OrderLookupParamInput[];
}

export interface GetStoreReturnReasonResult {
  getStoreReturnReasons: {
    returnReasons: ReturnReasonInterface[];
  };
}

export interface GetStoreConfigQueryVariables {
  subdomain: string;
}
export interface GetStoreConfigQueryResult {
  getStoreConfig: StoreConfigInterface;
}
