import { createPortal } from 'react-dom';
import { useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';

import { usePortal, useLockBodyScroll } from 'app/hooks';
import { IconTimes } from 'icons';

import styles from './Dialog.module.scss';

interface DialogProps {
  /**
   * Used to specify actions for the dialog
   *
   * Can be any React element, or combination of elements, but will
   * typically be a single button
   */
  actions?: React.ReactNode;

  /**
   * Used to specify the content for the dialog
   */
  children: React.ReactNode;

  /**
   * When specified, appends an additional className to the dialog's content element
   */
  className?: string;

  /**
   * Specifies the a11y text that is used for the close button
   *
   * Defaults to 'Close', but you can use this prop to provide a translation
   */
  closeButtonLabel?: string;

  /**
   * Determines whether or not the Dialog is visible
   */
  isVisible: boolean;

  /**
   * A callback function to be called whenever the Close button is clicked
   */
  onClose: () => void;

  /**
   * Renders a title for the dialog
   */
  title?: React.ReactNode;

  /**
   * Specifies whether or not to prevent scrolling when the dialog is visible
   */
  useScrollLock?: boolean;
}

export const Dialog = ({
  actions,
  children,
  className,
  closeButtonLabel,
  isVisible,
  onClose,
  title,
  useScrollLock,
}: DialogProps) => {
  const target = usePortal('dialogs');
  const containerRef = useRef<HTMLDivElement>(null);

  // Prevent body scrolling when visible
  useLockBodyScroll(!!useScrollLock && isVisible);

  useLayoutEffect(() => {
    if (isVisible && containerRef.current) {
      // For accessibility, focus the container element so that the user can use
      // the Tab key to correctly hit the first element in the newly visible dialog
      containerRef.current?.focus();
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  const dialogContent = (
    <>
      <div className={styles.container}>
        <div className={styles.dialog}>
          {title && <div className={styles.title}>{title}</div>}
          <div className={clsx(styles.dialogContent, className)}>{children}</div>

          {actions && <div className={styles.actions}>{actions}</div>}

          <button onClick={onClose} className={styles.closeButton} aria-label={closeButtonLabel}>
            <IconTimes size={18} />
          </button>
        </div>
      </div>

      <div className={styles.dialogBackdrop} />
    </>
  );

  return createPortal(dialogContent, target);
};

Dialog.defaultProps = {
  closeButtonLabel: 'Close',
  useScrollLock: true,
};
