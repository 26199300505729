import {
  convertNonPilotStoresToDropoffLocations,
  parseNonPilotStoresJson,
} from 'utils/environment';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';
export const BINARY_API_URL = process.env.REACT_APP_BINARY_API_URL || 'http://localhost:5000/';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/graphql-portal/';
export const RETAILER_ADMIN_URL = process.env.REACT_APP_RETAILER_ADMIN_URL || '';
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || '';

export const nonPilotStoreCoordinates = parseNonPilotStoresJson(
  process.env.REACT_APP_NON_PILOT_STORES_COORDINATES
);
export const NON_PILOT_STORE_LOCATIONS =
  convertNonPilotStoresToDropoffLocations(nonPilotStoreCoordinates);
