import { useTranslation } from 'react-i18next';

import { StoreOrderLineItem } from 'app/types';
import { getProductImage } from 'utils/product';
import { removeDefaultTitle } from 'utils';

import styles from './ProductCard.module.scss';

interface ProductCardProps {
  /**
   * The contextual content to be displayed
   */
  children?: React.ReactNode;

  /**
   * The line item from the store order
   */
  lineItem: StoreOrderLineItem;
}

/**
 * Renders a product card, and provides space for displaying contextual content
 * for the given product
 */
export const ProductCard = ({ children, lineItem }: ProductCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.productThumb}>
        <img
          src={getProductImage(lineItem)}
          alt={lineItem.product?.title || t('common.notApplicable')}
        />
      </div>

      <div className={styles.productInfo}>
        <div className={styles.productDetails}>
          <div className={styles.productName}>
            {lineItem.product?.title || lineItem.name || t('common.notApplicable')}
          </div>
          {lineItem.variant && (
            <div className={styles.variantInfo}>{removeDefaultTitle(lineItem.variant.title)}</div>
          )}
        </div>
      </div>

      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};
