import { useLayoutEffect } from 'react';

import { Footer } from 'app/components';
import { useStoreConfig } from 'app/contexts';
import { LoadingScreen } from 'layouts';

import styles from './StoreThemeLayout.module.scss';

interface DefaultAppPageProps {
  children: React.ReactNode;
}

export const StoreThemeLayout = ({ children }: DefaultAppPageProps) => {
  const { themeConfig, isInitializing } = useStoreConfig();

  useLayoutEffect(() => {
    if (themeConfig?.defaultFontFamily) {
      document.documentElement.style.setProperty(
        '--font-family-default',
        themeConfig?.defaultFontFamily
      );
    }
    // needs a default incase the retailer hasn't set a font
    else {
      document.documentElement.style.setProperty('--font-family-default', 'Poppins');
    }

    if (themeConfig?.backgroundImageUrl) {
      document.documentElement.style.setProperty(
        '--background-image-url',
        `url(${themeConfig?.backgroundImageUrl})`
      );
    }

    if (themeConfig?.buttonTextColor) {
      document.documentElement.style.setProperty(
        '--button-text-color',
        themeConfig?.buttonTextColor
      );
    }

    if (themeConfig?.buttonBackgroundColor) {
      document.documentElement.style.setProperty(
        '--button-background-color',
        themeConfig?.buttonBackgroundColor
      );
    }

    // Manual fix to allow footer to always stick to the bottom,
    // even if the content area is too small.
    document.getElementById('root')?.style.setProperty('height', '100%');

    document.documentElement.setAttribute('class', styles.rootHtml);
  }, [themeConfig]);

  if (isInitializing) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.contentArea}>{children}</div>
      <Footer />
    </div>
  );
};
