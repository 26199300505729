import clsx from 'clsx';
import React from 'react';

import { ReactComponent as Checkmark } from './checkmark.svg';
import styles from './Checkbox.module.scss';

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  /**
   * Using the children prop on this component renders a text label to the right side of the checkbox
   */
  children?: React.ReactNode;

  /**
   * Append an optional className to the outer container
   */
  className?: string;

  /**
   * Applies a click handler to the outer container, useful for applying .stopPropagation
   */
  onContainerClick?: (e: React.MouseEvent) => void;
}

export const Checkbox = ({
  children,
  className,
  onContainerClick,
  ...spreadProps
}: CheckboxProps) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
  <label className={clsx(styles.container, className)} onClick={onContainerClick}>
    <input {...spreadProps} type="checkbox" className={styles.checkbox} />
    <div className={styles.hitArea} aria-hidden="true">
      <Checkmark />
    </div>

    {children && <div className={styles.label}>{children}</div>}
  </label>
);
