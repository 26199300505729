import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MaximizeRoundedIcon from '@mui/icons-material/MaximizeRounded';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropoffLocationMap } from 'app/components';
import { useStoreOrder } from 'app/contexts';
import { useDropoffLocations, useGeocodeLocation } from 'app/hooks';
import { Coordinate, ReturnFlowData } from 'app/types';
import { IconExternalLink, IconMapPin, IconReturnBear } from 'icons';
import { Button, Modal, Placeholder, SelectableItem } from 'ui';
import { getAddressForGeocoding } from 'utils';

import styles from './DropoffLocationsModal.module.scss';
interface DropoffLocationsModalProps {
  isVisible: boolean;
  value?: string | null;
  canMailin?: boolean;
  onClose?: () => void;
  onPick?: (locationId: string) => void;
  handleMailInClick: () => void;
  handleContinueClick: (location: string) => void;
  setIsLocationsListVisible: (data?: Partial<ReturnFlowData>) => void;
}

export const DropoffLocationsModal = ({
  isVisible,
  value,
  canMailin,
  onClose,
  onPick,
  handleMailInClick,
  handleContinueClick,
  setIsLocationsListVisible,
}: DropoffLocationsModalProps) => {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const { storeOrder } = useStoreOrder();
  const prefilledAddress = getAddressForGeocoding(storeOrder?.shippingAddress);
  const [storedAddress, setStoredAddress] = useState(prefilledAddress);
  const [searchText, setSearchText] = useState(prefilledAddress);
  const { location: currentLocation } = useGeocodeLocation(storedAddress);
  const { dropoffLocations } = useDropoffLocations({ startingLocation: currentLocation });
  const firstDropoffLocationUid = dropoffLocations.length > 0 ? dropoffLocations[0].uid : '';
  // TODO: We need to use Material ui for breakpoints instead of hardcoding values
  const isMobile = useMediaQuery('(max-width:599px)');

  /**
   * Determine the coordinates of the selected location, this value is used for re-center
   * map whenever a new location is picked
   */
  const selectedLocationCoords = useMemo<Coordinate | null>(() => {
    const pickedLocation = dropoffLocations.find((i) => i.uid === selectedLocation);
    if (pickedLocation?.coordinates?.lat != null && pickedLocation.coordinates.lng != null) {
      return {
        lat: pickedLocation?.coordinates?.lat,
        lng: pickedLocation?.coordinates?.lng,
      };
    }
    return null;
  }, [selectedLocation, dropoffLocations]);

  const scrollToLocationListItem = (uid: string) => {
    const element = document.querySelector(`#list-location-${uid}`);

    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToTopOfMap = () => {
    const modalElement = document.getElementById('Drop-off-locations-modal');
    if (modalElement) {
      // Using a large negative number.
      modalElement.scrollTo(0, -Number.MAX_SAFE_INTEGER);
    }
  };

  // This useEffect handles a race condition where the storeOrder is not yet loaded
  // @todo - find a better way to resolve this
  useEffect(() => {
    // Pre-fill the address field when the store order loads for the first time
    if (prefilledAddress !== '') {
      const address = getAddressForGeocoding(storeOrder?.shippingAddress);

      setSearchText(address);
      setStoredAddress(address);
    }
  }, [prefilledAddress, setSearchText, storeOrder]);

  useEffect(() => {
    if (value) {
      // Sets the value of the selected dropoff location once the user clicks
      // the 'Select location' button.
      // Accept value updates from parent component
      setSelectedLocation(value);
    } else if (firstDropoffLocationUid) {
      setSelectedLocation(firstDropoffLocationUid);
    }

    if (isVisible && value && !isMobile) {
      scrollToLocationListItem(value);
    }
  }, [value, firstDropoffLocationUid, isVisible, isMobile]);

  const handleAddressChange = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setStoredAddress(searchText);
  };

  const currentSelectedLocation = dropoffLocations.find(
    (location) => location.uid === selectedLocation
  );

  const handleContinueToNextStep = (location: string) => {
    if (location) {
      if (typeof onPick === 'function' && location) {
        onPick(location);
      }
      if (typeof onClose === 'function') {
        onClose();
      }

      handleContinueClick(location);
    }
  };

  const hideLocationsList = () => {
    setIsLocationsListVisible({ isLocationsListVisible: false });
  };

  useEffect(() => {
    if (isVisible && isMobile) {
      scrollToTopOfMap();
    }
  }, [isVisible, isMobile]);

  const FloatingCurrentLocationCard = (
    <SelectableItem
      id={`list-location-${currentSelectedLocation?.uid}`}
      key={currentSelectedLocation?.uid}
      onClick={() => {
        if (currentSelectedLocation?.uid) {
          handleContinueToNextStep(currentSelectedLocation?.uid);
        }
      }}
      className={styles.floatingLocationCard}
    >
      <div className={clsx(styles.dropOffIcon, styles.isSelected)}>
        <IconReturnBear size={25} />
      </div>

      <div className={styles.locationInfo}>
        <div className={styles.locationName}>{currentSelectedLocation?.name}</div>
        <div className={styles.address}>{currentSelectedLocation?.address}</div>
        <div className={styles.hours}>
          {currentSelectedLocation?.hoursOfOperationUrl && (
            <a href={currentSelectedLocation.hoursOfOperationUrl} target="_locationHours">
              {t('returnMethodPage.viewHours')}{' '}
              <IconExternalLink className={styles.externalLinkIcon} />
            </a>
          )}
        </div>
      </div>

      {isMobile ? (
        <div className={styles.detailsRightSection}>
          <div className={styles.distance}>
            {currentSelectedLocation?.distance === undefined ? (
              <Placeholder height={20} />
            ) : (
              currentSelectedLocation?.distance
            )}
          </div>

          <div className={styles.arrowRightIcon}>
            <KeyboardArrowRightIcon />
          </div>
        </div>
      ) : (
        <div className={styles.distance}>
          {currentSelectedLocation?.distance === undefined ? (
            <Placeholder height={20} />
          ) : (
            currentSelectedLocation?.distance
          )}
        </div>
      )}
    </SelectableItem>
  );

  const ModalFooter = !isMobile && (
    <>
      {canMailin && (
        <Button
          appearance="link"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose();
              hideLocationsList();
              handleMailInClick();
            }
          }}
        >
          {t('dropoffLocationsModal.printLabelAndPackageMyReturn')}
        </Button>
      )}

      <Button
        onClick={() => {
          if (typeof onPick === 'function' && selectedLocation) {
            onPick(selectedLocation);
          }
          if (typeof onClose === 'function') {
            onClose();
          }
          if (selectedLocation) {
            handleContinueToNextStep(selectedLocation);
          }
        }}
      >
        {t('dropoffLocationsModal.selectLocation')}
      </Button>
    </>
  );

  return (
    <Modal
      title={t('dropoffLocationsModal.title')}
      isVisible={isVisible}
      onClose={onClose}
      closeButtonLabel={t('common.close')}
      className={styles.content}
      modalClass={styles.modalOverrides}
      customTitleClassName={styles.modalTitleOverrides}
      customCloseButtonClassName={styles.modalCloseButtonOverrides}
      customFooterClassName={styles.customFooterStyles}
      contentId="Drop-off-locations-modal"
      footer={ModalFooter}
    >
      <div className={styles.locationsList}>
        {isMobile && (
          <>
            <div className={clsx(styles.scrollIconArea, !canMailin && styles.noMailInOption)}>
              <div className={styles.maximizeIcon}>
                <MaximizeRoundedIcon fontSize="large" />
              </div>
              <div className={styles.moreOptionsText}>{t('dropoffLocationsModal.moreOptions')}</div>
            </div>
            {canMailin && (
              <div className={styles.printAndMailReturnArea}>
                <Button
                  appearance="link"
                  className={styles.printAndMailReturnButton}
                  onClick={() => {
                    if (typeof onClose === 'function') {
                      onClose();
                      hideLocationsList();
                      handleMailInClick();
                    }
                  }}
                >
                  {t('dropoffLocationsModal.printLabelAndPackageMyReturn')}
                </Button>
              </div>
            )}
          </>
        )}

        {dropoffLocations.map((location) => {
          const isSelected = selectedLocation === location.uid;
          return (
            <SelectableItem
              isSelected={isSelected}
              id={`list-location-${location.uid}`}
              key={location.uid}
              onClick={() => {
                setSelectedLocation(location.uid);
                if (isMobile) {
                  handleContinueToNextStep(location.uid);
                }
              }}
              className={styles.location}
            >
              <div className={clsx(styles.dropOffIcon, isSelected && styles.isSelected)}>
                <IconReturnBear size={25} />
              </div>

              <div className={styles.locationInfo}>
                <div className={styles.locationName}>{location.name}</div>

                <div className={styles.address}>{location.address}</div>
                <div className={styles.hours}>
                  {location?.hoursOfOperationUrl && (
                    <a href={location.hoursOfOperationUrl} target="_locationHours">
                      {t('returnMethodPage.viewHours')}{' '}
                      <IconExternalLink className={styles.externalLinkIcon} />
                    </a>
                  )}
                </div>
              </div>

              {isMobile ? (
                <div className={styles.detailsRightSection}>
                  <div className={styles.distance}>
                    {location.distance === undefined ? (
                      <Placeholder height={20} />
                    ) : (
                      location.distance
                    )}
                  </div>

                  <div className={styles.arrowRightIcon}>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              ) : (
                <div className={styles.distance}>
                  {location.distance === undefined ? (
                    <Placeholder height={20} />
                  ) : (
                    location.distance
                  )}
                </div>
              )}
            </SelectableItem>
          );
        })}
      </div>

      <div className={styles.map}>
        <DropoffLocationMap
          selectedLocation={selectedLocation}
          selectedLocationCoords={selectedLocationCoords}
          onLocationClick={(locationUid) => {
            setSelectedLocation(locationUid);
            scrollToLocationListItem(locationUid);
          }}
          currentLocation={currentLocation}
          dropoffLocations={dropoffLocations}
          floatingCurrentLocationCard={isMobile ? FloatingCurrentLocationCard : undefined}
        />

        <form className={styles.searchBar} onSubmit={handleAddressChange}>
          <div className={styles.searchInput}>
            <label htmlFor="map-address-input" className={styles.inputLabel}>
              {t('dropoffLocationsModal.locationFieldLabel')}
            </label>
            <input
              id="map-address-input"
              type="text"
              className={styles.input}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <button className={styles.searchButton} type="submit">
            <IconMapPin />
          </button>
        </form>
      </div>
    </Modal>
  );
};
