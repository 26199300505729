import { Icon, IconProps } from './Icon';

export const IconInfoCircle = ({
  className,
  color = 'currentColor',
  size,
  ...spreadProps
}: IconProps): JSX.Element => (
  <Icon {...spreadProps} className={className} size={size} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 4.00004C8.41421 4.00004 8.75 4.33582 8.75 4.75004V5.50004C8.75 5.91425 8.41421 6.25004 8 6.25004C7.58579 6.25004 7.25 5.91425 7.25 5.50004V4.75004C7.25 4.33582 7.58579 4.00004 8 4.00004ZM8 7.24997C8.41421 7.24997 8.75 7.58575 8.75 7.99997V11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V7.99997C7.25 7.58575 7.58579 7.24997 8 7.24997Z"
      fill={color}
    />
  </Icon>
);
