import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useReturnFlow } from 'app/hooks';
import { useStoreConfig } from 'app/contexts';
import { ConfirmationLayout } from 'layouts';
import { API_URL } from 'app/environment';
import { ReturnCaseItemReturnMethod } from 'generated/graphql';

import styles from './MailInConfirmation.module.scss';

export const MailInConfirmation = () => {
  const { t, i18n } = useTranslation();
  const { storeConfig, themeConfig } = useStoreConfig();
  const { createdReturnCase } = useReturnFlow();

  const refundOrStoreCreditItems = createdReturnCase?.returnItems?.filter(
    (item) =>
      item.returnMethod &&
      [ReturnCaseItemReturnMethod.Refund, ReturnCaseItemReturnMethod.StoreCredit].includes(
        item.returnMethod
      )
  );
  const hasRefundOrStoreCreditItems =
    !!refundOrStoreCreditItems && refundOrStoreCreditItems.length > 0;
  const hasExchangeItems =
    !!createdReturnCase?.exchangeItems && createdReturnCase?.exchangeItems?.length > 0;
  const hasReturnsAndExchanges = hasRefundOrStoreCreditItems && hasExchangeItems;

  const nextSteps = useMemo(
    () => [
      t('returnConfirmationPage.mailIn.mailInItems'),
      t('returnConfirmationPage.returnProcessed'),
      t('returnConfirmationPage.receiveRefund'),
    ],
    [t]
  );

  const refundOrStoreCreditStepContent = (
    <li>
      <h3> {t('returnConfirmationPage.dropOff.receiveRefundOrCredit')}</h3>
      {t('returnConfirmationPage.dropOff.receiveRefundOrCreditInstructions')}
    </li>
  );

  const exchangeStepContent = (
    <li>
      <h3> {t('returnConfirmationPage.dropOff.receiveExchangesTitle')}</h3>
      {t('returnConfirmationPage.dropOff.receiveExchangesInstructions')}
    </li>
  );

  const downloadUrl = createdReturnCase?.shippingLabel?.labelDownloadUrl;
  const base64EncodedData = createdReturnCase?.shippingDocuments?.label?.data;

  let shippingLabelLinkHref = downloadUrl || `data:application/pdf;base64,${base64EncodedData}`;
  const shippingLabelFilename = `return-case-${createdReturnCase?.returnCase?.shortUid}-shipping-label.pdf`;
  const englishShoppingUrl = storeConfig?.shoppingUrl_en || storeConfig?.shoppingUrl;

  const shouldUseServedPDFWithPackingSlip = storeConfig?.packingSlip ? true : false;

  if (shouldUseServedPDFWithPackingSlip) {
    const url = new URL(`${API_URL}`);

    url.pathname = '/shipping/label';

    url.searchParams.append('return_case', createdReturnCase?.returnCase?.shortUid ?? '');
    url.searchParams.append('email', createdReturnCase?.returnCase?.email ?? '');
    url.searchParams.append('order_number', createdReturnCase?.returnCase?.orderNumber ?? '');
    url.searchParams.append(
      'postal_code',
      createdReturnCase?.returnCase?.orderShippingAddress?.postalCode ?? ''
    );

    shippingLabelLinkHref = url.toString();
  }

  return (
    <ConfirmationLayout
      title={t('returnConfirmationPage.title')}
      subTitle={t('returnConfirmationPage.subTitle')}
      hero={
        <>
          <h2>{t('returnConfirmationPage.mailIn.yourShippingLabel')}</h2>
          <p>
            {t('returnConfirmationPage.mailIn.shippingLabelInstructions', {
              merchant: themeConfig?.name,
            })}
          </p>

          <a
            className={styles.shippingLabelButton}
            href={shippingLabelLinkHref}
            download={shippingLabelFilename}
            target="_blank"
            rel="noreferrer"
          >
            {t('returnConfirmationPage.mailIn.downloadShippingLabel')}
          </a>
        </>
      }
      nextSteps={nextSteps}
    >
      <ol className={styles.nextSteps}>
        <li>
          <h3>{t('returnConfirmationPage.mailIn.mailInItems')}</h3>
          {t('returnConfirmationPage.mailIn.mailInItemsInstructions')}
        </li>
        <li>
          <h3> {t('returnConfirmationPage.mailIn.itemsProcessed', { processingTime: 7 })}</h3>
          {t('returnConfirmationPage.mailIn.itemsProcessedInfo')}
        </li>
        {hasReturnsAndExchanges ? (
          <>
            {refundOrStoreCreditStepContent}
            {exchangeStepContent}
          </>
        ) : hasExchangeItems ? (
          exchangeStepContent
        ) : (
          refundOrStoreCreditStepContent
        )}
      </ol>

      <footer className={styles.actions}>
        <a
          href={
            i18n.resolvedLanguage.startsWith('fr')
              ? storeConfig?.shoppingUrl_fr || englishShoppingUrl
              : englishShoppingUrl
          }
          className={styles.backToShopping}
        >
          {t('returnConfirmationPage.backToShopping')}
        </a>

        <Link to="/" className={styles.createAnotherReturn}>
          {t('returnConfirmationPage.submitAnotherReturn')}
        </Link>
      </footer>
    </ConfirmationLayout>
  );
};
