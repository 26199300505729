import { useState } from 'react';

import { ShippingAddress } from 'app/types';

import { AddressInfoContextInterface } from '.';

export const useAddressInfoProvider = (): AddressInfoContextInterface => {
  const [addressInfo, setAddressInfo] = useState<ShippingAddress | undefined>(undefined);

  return {
    addressInfo,
    setAddressInfo,
  };
};
