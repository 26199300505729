import clsx from 'clsx';

import { Stepper } from 'app/components';

import styles from './DefaultAppPage.module.scss';

interface DefaultAppPageProps {
  /**
   * The content to display
   */
  children: React.ReactNode;

  /**
   * An optional className to be appended to the outer container element
   */
  className?: string;

  /**
   * The title to display
   */
  title: React.ReactNode | string | null;

  /**
   * The subtitle to display
   */
  subtitle?: React.ReactNode | string | null;
}

export const DefaultAppPage = ({ children, className, title, subtitle }: DefaultAppPageProps) => {
  return (
    <main className={clsx(styles.container, className)}>
      <div className={styles.topStepper}>
        <Stepper />
      </div>

      {title && (
        <h2 data-testid="default-app-page-title" className={styles.title}>
          {title}
        </h2>
      )}
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}

      <div className={styles.content}>{children}</div>
    </main>
  );
};
