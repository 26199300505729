import clsx from 'clsx';

import styles from './ReviewReturnSection.module.scss';

interface ReviewReturnSectionProps {
  action?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  title: React.ReactNode;
}

export const ReviewReturnSection = ({
  action,
  children,
  className,
  title,
}: ReviewReturnSectionProps) => (
  <div className={clsx(styles.container)}>
    <div className={styles.title}>{title}</div>
    <div className={clsx(styles.content, className)}>{children}</div>
    <div className={styles.action}>{action}</div>
  </div>
);
