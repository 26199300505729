import { StoreOrderLineItem } from 'app/types';

export const MISSING_IMAGE_URL = process.env.PUBLIC_URL + '/images/not-available.jpg';

/**
 * Returns the correct image to use for a StoreOrderLineItem
 *
 * @returns {string} An image url
 */
export const getProductImage = (lineItemJson: StoreOrderLineItem) => {
  return lineItemJson?.variant?.image || lineItemJson.product?.image || MISSING_IMAGE_URL;
};
