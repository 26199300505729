import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

import i18n from 'app/locales';
import { API_URL } from 'app/environment';
import { getSubdomain, sendErrorMessageForMonitoring } from 'utils';

const httpLink = createHttpLink({
  uri: API_URL,
});

// this removes the __typename prop from returned objects
// without this we would need a middleware to remove the __typename prop
// we don't use the __typename anywhere yet.

const cache = new InMemoryCache({
  addTypename: false,
  // https://www.apollographql.com/docs/react/caching/cache-configuration/
  typePolicies: {
    OrderType: {
      keyFields: ['orderId'],
    },
  },
});

const headerMiddleWare = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      subdomain: getSubdomain(),
      'Accept-Language': i18n.resolvedLanguage,
    },
  }));
  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      sendErrorMessageForMonitoring(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    sendErrorMessageForMonitoring(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([retryLink, errorLink, headerMiddleWare, httpLink]),
  cache,
});

export const clearAPICache = async () => {
  if (client.link) {
    client.clearStore();
  }
};

export default client;
