import clsx from 'clsx';

import styles from './Icon.module.scss';

export interface IconProps {
  className?: string;
  color?: string;
  size?: number;
}

interface IconComponentProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  size?: number;
  viewBox: string;
}

export const Icon = ({
  children,
  className,
  size,
  viewBox,
  ...spreadProps
}: IconComponentProps): JSX.Element => (
  <svg
    className={clsx(styles.icon, className)}
    width={size}
    height={size}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    {...spreadProps}
  >
    {children}
  </svg>
);

Icon.defaultProps = {
  size: 16,
};
