interface ReturnBearLogoProps {
  /**
   * Specify the size of the logo in pixels. Defaults to 48
   */
  size?: number;

  /**
   * Specify the color for the logo. Defaults to `currentColor`
   */
  color?: string;

  /**
   * If used appends a className to the outer svg tag
   */
  className?: string;
}

/**
 * Renders the ReturnBear logo
 */
export const ReturnBearLogo = ({
  className,
  color = 'currentColor',
  size = 48,
}: ReturnBearLogoProps) => (
  <svg
    width={size * 6.76}
    height={size}
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.3121 11.0771C83.6008 11.0771 82.9445 10.9121 82.3431 10.5821C81.7491 10.2447 81.2761 9.77906 80.9241 9.18506C80.5795 8.58372 80.4071 7.90906 80.4071 7.16106C80.4071 6.41306 80.5795 5.74206 80.9241 5.14806C81.2761 4.55406 81.7491 4.09206 82.3431 3.76206C82.9445 3.42472 83.6008 3.25606 84.3121 3.25606C85.0308 3.25606 85.6871 3.42472 86.2811 3.76206C86.8825 4.09206 87.3555 4.55406 87.7001 5.14806C88.0448 5.74206 88.2171 6.41306 88.2171 7.16106C88.2171 7.90906 88.0448 8.58372 87.7001 9.18506C87.3555 9.77906 86.8825 10.2447 86.2811 10.5821C85.6871 10.9121 85.0308 11.0771 84.3121 11.0771ZM84.3121 9.98806C84.8181 9.98806 85.2691 9.87439 85.6651 9.64706C86.0611 9.41239 86.3691 9.08239 86.5891 8.65706C86.8165 8.22439 86.9301 7.72572 86.9301 7.16106C86.9301 6.59639 86.8165 6.10139 86.5891 5.67606C86.3691 5.25072 86.0611 4.92439 85.6651 4.69706C85.2691 4.46972 84.8181 4.35606 84.3121 4.35606C83.8061 4.35606 83.3551 4.46972 82.9591 4.69706C82.5631 4.92439 82.2515 5.25072 82.0241 5.67606C81.8041 6.10139 81.6941 6.59639 81.6941 7.16106C81.6941 7.72572 81.8041 8.22439 82.0241 8.65706C82.2515 9.08239 82.5631 9.41239 82.9591 9.64706C83.3551 9.87439 83.8061 9.98806 84.3121 9.98806Z"
      fill={color}
    />
    <path
      d="M92.2421 5.96206H91.1201V11.0001H89.8551V5.96206H89.1401V4.93906H89.8551V4.51006C89.8551 3.81339 90.0384 3.30739 90.4051 2.99206C90.7791 2.66939 91.3621 2.50806 92.1541 2.50806V3.55306C91.7728 3.55306 91.5051 3.62639 91.3511 3.77306C91.1971 3.91239 91.1201 4.15806 91.1201 4.51006V4.93906H92.2421V5.96206Z"
      fill={color}
    />
    <path
      d="M96.1444 5.96206H95.0224V11.0001H93.7574V5.96206H93.0424V4.93906H93.7574V4.51006C93.7574 3.81339 93.9408 3.30739 94.3074 2.99206C94.6814 2.66939 95.2644 2.50806 96.0564 2.50806V3.55306C95.6751 3.55306 95.4074 3.62639 95.2534 3.77306C95.0994 3.91239 95.0224 4.15806 95.0224 4.51006V4.93906H96.1444V5.96206Z"
      fill={color}
    />
    <path
      d="M98.1438 4.13606C97.9165 4.13606 97.7258 4.05906 97.5718 3.90506C97.4178 3.75106 97.3408 3.56039 97.3408 3.33306C97.3408 3.10572 97.4178 2.91506 97.5718 2.76106C97.7258 2.60706 97.9165 2.53006 98.1438 2.53006C98.3638 2.53006 98.5508 2.60706 98.7048 2.76106C98.8588 2.91506 98.9358 3.10572 98.9358 3.33306C98.9358 3.56039 98.8588 3.75106 98.7048 3.90506C98.5508 4.05906 98.3638 4.13606 98.1438 4.13606ZM98.7598 4.93906V11.0001H97.5058V4.93906H98.7598Z"
      fill={color}
    />
    <path
      d="M100.238 7.96406C100.238 7.34072 100.363 6.79439 100.612 6.32506C100.869 5.84839 101.221 5.48172 101.668 5.22506C102.116 4.96839 102.629 4.84006 103.208 4.84006C103.942 4.84006 104.547 5.01606 105.023 5.36806C105.507 5.71272 105.834 6.20772 106.002 6.85306H104.649C104.539 6.55239 104.363 6.31772 104.121 6.14906C103.879 5.98039 103.575 5.89606 103.208 5.89606C102.695 5.89606 102.284 6.07939 101.976 6.44606C101.676 6.80539 101.525 7.31139 101.525 7.96406C101.525 8.61672 101.676 9.12639 101.976 9.49306C102.284 9.85972 102.695 10.0431 103.208 10.0431C103.934 10.0431 104.415 9.72406 104.649 9.08606H106.002C105.826 9.70206 105.496 10.1934 105.012 10.5601C104.528 10.9194 103.927 11.0991 103.208 11.0991C102.629 11.0991 102.116 10.9707 101.668 10.7141C101.221 10.4501 100.869 10.0834 100.612 9.61406C100.363 9.13739 100.238 8.58739 100.238 7.96406Z"
      fill={color}
    />
    <path
      d="M108.14 4.13606C107.913 4.13606 107.722 4.05906 107.568 3.90506C107.414 3.75106 107.337 3.56039 107.337 3.33306C107.337 3.10572 107.414 2.91506 107.568 2.76106C107.722 2.60706 107.913 2.53006 108.14 2.53006C108.36 2.53006 108.547 2.60706 108.701 2.76106C108.855 2.91506 108.932 3.10572 108.932 3.33306C108.932 3.56039 108.855 3.75106 108.701 3.90506C108.547 4.05906 108.36 4.13606 108.14 4.13606ZM108.756 4.93906V11.0001H107.502V4.93906H108.756Z"
      fill={color}
    />
    <path
      d="M110.234 7.94206C110.234 7.33339 110.359 6.79439 110.608 6.32506C110.865 5.85572 111.21 5.49272 111.642 5.23606C112.082 4.97206 112.566 4.84006 113.094 4.84006C113.571 4.84006 113.985 4.93539 114.337 5.12606C114.697 5.30939 114.983 5.54039 115.195 5.81906V4.93906H116.46V11.0001H115.195V10.0981C114.983 10.3841 114.693 10.6224 114.326 10.8131C113.96 11.0037 113.542 11.0991 113.072 11.0991C112.552 11.0991 112.075 10.9671 111.642 10.7031C111.21 10.4317 110.865 10.0577 110.608 9.58106C110.359 9.09706 110.234 8.55072 110.234 7.94206ZM115.195 7.96406C115.195 7.54606 115.107 7.18306 114.931 6.87506C114.763 6.56706 114.539 6.33239 114.26 6.17106C113.982 6.00972 113.681 5.92906 113.358 5.92906C113.036 5.92906 112.735 6.00972 112.456 6.17106C112.178 6.32506 111.95 6.55606 111.774 6.86406C111.606 7.16472 111.521 7.52406 111.521 7.94206C111.521 8.36006 111.606 8.72672 111.774 9.04206C111.95 9.35739 112.178 9.59939 112.456 9.76806C112.742 9.92939 113.043 10.0101 113.358 10.0101C113.681 10.0101 113.982 9.92939 114.26 9.76806C114.539 9.60672 114.763 9.37206 114.931 9.06406C115.107 8.74872 115.195 8.38206 115.195 7.96406Z"
      fill={color}
    />
    <path d="M119.611 2.86006V11.0001H118.357V2.86006H119.611Z" fill={color} />
    <path
      d="M84.7961 21.0001L83.0361 17.9421H82.0791V21.0001H80.8251V13.3551H83.4651C84.0518 13.3551 84.5468 13.4577 84.9501 13.6631C85.3608 13.8684 85.6651 14.1434 85.8631 14.4881C86.0685 14.8327 86.1711 15.2177 86.1711 15.6431C86.1711 16.1417 86.0245 16.5964 85.7311 17.0071C85.4451 17.4104 85.0015 17.6854 84.4001 17.8321L86.2921 21.0001H84.7961ZM82.0791 16.9411H83.4651C83.9345 16.9411 84.2865 16.8237 84.5211 16.5891C84.7631 16.3544 84.8841 16.0391 84.8841 15.6431C84.8841 15.2471 84.7668 14.9391 84.5321 14.7191C84.2975 14.4917 83.9418 14.3781 83.4651 14.3781H82.0791V16.9411Z"
      fill={color}
    />
    <path
      d="M93.5803 17.8211C93.5803 18.0484 93.5656 18.2537 93.5363 18.4371H88.9053C88.942 18.9211 89.1216 19.3097 89.4443 19.6031C89.767 19.8964 90.163 20.0431 90.6323 20.0431C91.307 20.0431 91.7836 19.7607 92.0623 19.1961H93.4153C93.232 19.7534 92.8983 20.2117 92.4143 20.5711C91.9376 20.9231 91.3436 21.0991 90.6323 21.0991C90.053 21.0991 89.5323 20.9707 89.0703 20.7141C88.6156 20.4501 88.2563 20.0834 87.9923 19.6141C87.7356 19.1374 87.6073 18.5874 87.6073 17.9641C87.6073 17.3407 87.732 16.7944 87.9813 16.3251C88.238 15.8484 88.5936 15.4817 89.0483 15.2251C89.5103 14.9684 90.0383 14.8401 90.6323 14.8401C91.2043 14.8401 91.714 14.9647 92.1613 15.2141C92.6086 15.4634 92.957 15.8154 93.2063 16.2701C93.4556 16.7174 93.5803 17.2344 93.5803 17.8211ZM92.2713 17.4251C92.264 16.9631 92.099 16.5927 91.7763 16.3141C91.4536 16.0354 91.054 15.8961 90.5773 15.8961C90.1446 15.8961 89.7743 16.0354 89.4663 16.3141C89.1583 16.5854 88.975 16.9557 88.9163 17.4251H92.2713Z"
      fill={color}
    />
    <path
      d="M96.5274 15.9621V19.3171C96.5274 19.5444 96.5787 19.7094 96.6814 19.8121C96.7914 19.9074 96.9747 19.9551 97.2314 19.9551H98.0014V21.0001H97.0114C96.4467 21.0001 96.014 20.8681 95.7134 20.6041C95.4127 20.3401 95.2624 19.9111 95.2624 19.3171V15.9621H94.5474V14.9391H95.2624V13.4321H96.5274V14.9391H98.0014V15.9621H96.5274Z"
      fill={color}
    />
    <path
      d="M104.896 14.9391V21.0001H103.642V20.2851C103.444 20.5344 103.184 20.7324 102.861 20.8791C102.546 21.0184 102.208 21.0881 101.849 21.0881C101.372 21.0881 100.943 20.9891 100.562 20.7911C100.188 20.5931 99.8912 20.2997 99.6712 19.9111C99.4585 19.5224 99.3522 19.0531 99.3522 18.5031V14.9391H100.595V18.3161C100.595 18.8587 100.731 19.2767 101.002 19.5701C101.273 19.8561 101.644 19.9991 102.113 19.9991C102.582 19.9991 102.953 19.8561 103.224 19.5701C103.503 19.2767 103.642 18.8587 103.642 18.3161V14.9391H104.896Z"
      fill={color}
    />
    <path
      d="M108.055 15.8191C108.238 15.5111 108.48 15.2727 108.781 15.1041C109.089 14.9281 109.452 14.8401 109.87 14.8401V16.1381H109.551C109.059 16.1381 108.685 16.2627 108.429 16.5121C108.179 16.7614 108.055 17.1941 108.055 17.8101V21.0001H106.801V14.9391H108.055V15.8191Z"
      fill={color}
    />
    <path
      d="M114.33 14.8401C114.807 14.8401 115.232 14.9391 115.606 15.1371C115.988 15.3351 116.285 15.6284 116.497 16.0171C116.71 16.4057 116.816 16.8751 116.816 17.4251V21.0001H115.573V17.6121C115.573 17.0694 115.438 16.6551 115.166 16.3691C114.895 16.0757 114.525 15.9291 114.055 15.9291C113.586 15.9291 113.212 16.0757 112.933 16.3691C112.662 16.6551 112.526 17.0694 112.526 17.6121V21.0001H111.272V14.9391H112.526V15.6321C112.732 15.3827 112.992 15.1884 113.307 15.0491C113.63 14.9097 113.971 14.8401 114.33 14.8401Z"
      fill={color}
    />
    <path
      d="M120.866 21.0991C120.389 21.0991 119.96 21.0147 119.579 20.8461C119.205 20.6701 118.908 20.4354 118.688 20.1421C118.468 19.8414 118.351 19.5077 118.336 19.1411H119.634C119.656 19.3977 119.777 19.6141 119.997 19.7901C120.224 19.9587 120.507 20.0431 120.844 20.0431C121.196 20.0431 121.467 19.9771 121.658 19.8451C121.856 19.7057 121.955 19.5297 121.955 19.3171C121.955 19.0897 121.845 18.9211 121.625 18.8111C121.412 18.7011 121.071 18.5801 120.602 18.4481C120.147 18.3234 119.777 18.2024 119.491 18.0851C119.205 17.9677 118.956 17.7881 118.743 17.5461C118.538 17.3041 118.435 16.9851 118.435 16.5891C118.435 16.2664 118.53 15.9731 118.721 15.7091C118.912 15.4377 119.183 15.2251 119.535 15.0711C119.894 14.9171 120.305 14.8401 120.767 14.8401C121.456 14.8401 122.01 15.0161 122.428 15.3681C122.853 15.7127 123.081 16.1857 123.11 16.7871H121.856C121.834 16.5157 121.724 16.2994 121.526 16.1381C121.328 15.9767 121.06 15.8961 120.723 15.8961C120.393 15.8961 120.14 15.9584 119.964 16.0831C119.788 16.2077 119.7 16.3727 119.7 16.5781C119.7 16.7394 119.759 16.8751 119.876 16.9851C119.993 17.0951 120.136 17.1831 120.305 17.2491C120.474 17.3077 120.723 17.3847 121.053 17.4801C121.493 17.5974 121.852 17.7184 122.131 17.8431C122.417 17.9604 122.663 18.1364 122.868 18.3711C123.073 18.6057 123.18 18.9174 123.187 19.3061C123.187 19.6507 123.092 19.9587 122.901 20.2301C122.71 20.5014 122.439 20.7141 122.087 20.8681C121.742 21.0221 121.335 21.0991 120.866 21.0991Z"
      fill={color}
    />
    <path
      d="M133.245 15.6321C133.245 16.0207 133.154 16.3874 132.97 16.7321C132.787 17.0767 132.494 17.3591 132.09 17.5791C131.687 17.7917 131.17 17.8981 130.539 17.8981H129.153V21.0001H127.899V13.3551H130.539C131.126 13.3551 131.621 13.4577 132.024 13.6631C132.435 13.8611 132.739 14.1324 132.937 14.4771C133.143 14.8217 133.245 15.2067 133.245 15.6321ZM130.539 16.8751C131.016 16.8751 131.372 16.7687 131.606 16.5561C131.841 16.3361 131.958 16.0281 131.958 15.6321C131.958 14.7961 131.485 14.3781 130.539 14.3781H129.153V16.8751H130.539Z"
      fill={color}
    />
    <path
      d="M134.273 17.9421C134.273 17.3334 134.398 16.7944 134.647 16.3251C134.904 15.8557 135.249 15.4927 135.681 15.2361C136.121 14.9721 136.605 14.8401 137.133 14.8401C137.61 14.8401 138.024 14.9354 138.376 15.1261C138.736 15.3094 139.022 15.5404 139.234 15.8191V14.9391H140.499V21.0001H139.234V20.0981C139.022 20.3841 138.732 20.6224 138.365 20.8131C137.999 21.0037 137.581 21.0991 137.111 21.0991C136.591 21.0991 136.114 20.9671 135.681 20.7031C135.249 20.4317 134.904 20.0577 134.647 19.5811C134.398 19.0971 134.273 18.5507 134.273 17.9421ZM139.234 17.9641C139.234 17.5461 139.146 17.1831 138.97 16.8751C138.802 16.5671 138.578 16.3324 138.299 16.1711C138.021 16.0097 137.72 15.9291 137.397 15.9291C137.075 15.9291 136.774 16.0097 136.495 16.1711C136.217 16.3251 135.989 16.5561 135.813 16.8641C135.645 17.1647 135.56 17.5241 135.56 17.9421C135.56 18.3601 135.645 18.7267 135.813 19.0421C135.989 19.3574 136.217 19.5994 136.495 19.7681C136.781 19.9294 137.082 20.0101 137.397 20.0101C137.72 20.0101 138.021 19.9294 138.299 19.7681C138.578 19.6067 138.802 19.3721 138.97 19.0641C139.146 18.7487 139.234 18.3821 139.234 17.9641Z"
      fill={color}
    />
    <path
      d="M143.65 15.8191C143.834 15.5111 144.076 15.2727 144.376 15.1041C144.684 14.9281 145.047 14.8401 145.465 14.8401V16.1381H145.146C144.655 16.1381 144.281 16.2627 144.024 16.5121C143.775 16.7614 143.65 17.1941 143.65 17.8101V21.0001H142.396V14.9391H143.65V15.8191Z"
      fill={color}
    />
    <path
      d="M148.331 15.9621V19.3171C148.331 19.5444 148.382 19.7094 148.485 19.8121C148.595 19.9074 148.778 19.9551 149.035 19.9551H149.805V21.0001H148.815C148.25 21.0001 147.818 20.8681 147.517 20.6041C147.216 20.3401 147.066 19.9111 147.066 19.3171V15.9621H146.351V14.9391H147.066V13.4321H148.331V14.9391H149.805V15.9621H148.331Z"
      fill={color}
    />
    <path
      d="M154.269 14.8401C154.746 14.8401 155.171 14.9391 155.545 15.1371C155.926 15.3351 156.223 15.6284 156.436 16.0171C156.649 16.4057 156.755 16.8751 156.755 17.4251V21.0001H155.512V17.6121C155.512 17.0694 155.376 16.6551 155.105 16.3691C154.834 16.0757 154.463 15.9291 153.994 15.9291C153.525 15.9291 153.151 16.0757 152.872 16.3691C152.601 16.6551 152.465 17.0694 152.465 17.6121V21.0001H151.211V14.9391H152.465V15.6321C152.67 15.3827 152.931 15.1884 153.246 15.0491C153.569 14.9097 153.91 14.8401 154.269 14.8401Z"
      fill={color}
    />
    <path
      d="M164.159 17.8211C164.159 18.0484 164.145 18.2537 164.115 18.4371H159.484C159.521 18.9211 159.701 19.3097 160.023 19.6031C160.346 19.8964 160.742 20.0431 161.211 20.0431C161.886 20.0431 162.363 19.7607 162.641 19.1961H163.994C163.811 19.7534 163.477 20.2117 162.993 20.5711C162.517 20.9231 161.923 21.0991 161.211 21.0991C160.632 21.0991 160.111 20.9707 159.649 20.7141C159.195 20.4501 158.835 20.0834 158.571 19.6141C158.315 19.1374 158.186 18.5874 158.186 17.9641C158.186 17.3407 158.311 16.7944 158.56 16.3251C158.817 15.8484 159.173 15.4817 159.627 15.2251C160.089 14.9684 160.617 14.8401 161.211 14.8401C161.783 14.8401 162.293 14.9647 162.74 15.2141C163.188 15.4634 163.536 15.8154 163.785 16.2701C164.035 16.7174 164.159 17.2344 164.159 17.8211ZM162.85 17.4251C162.843 16.9631 162.678 16.5927 162.355 16.3141C162.033 16.0354 161.633 15.8961 161.156 15.8961C160.724 15.8961 160.353 16.0354 160.045 16.3141C159.737 16.5854 159.554 16.9557 159.495 17.4251H162.85Z"
      fill={color}
    />
    <path
      d="M166.897 15.8191C167.081 15.5111 167.323 15.2727 167.623 15.1041C167.931 14.9281 168.294 14.8401 168.712 14.8401V16.1381H168.393C167.902 16.1381 167.528 16.2627 167.271 16.5121C167.022 16.7614 166.897 17.1941 166.897 17.8101V21.0001H165.643V14.9391H166.897V15.8191Z"
      fill={color}
    />
    <path
      d="M1.91949 9.04496L3.88429 7.91473C4.09186 7.79533 4.10886 7.49916 3.94151 7.32779C3.39908 6.77234 3.07119 6.01697 3.07119 5.17519C3.07119 3.48376 4.45322 2.09987 6.14237 2.09987C7.55073 2.09987 8.73732 3.04873 9.09372 4.33723C9.15794 4.5694 9.42406 4.7052 9.63232 4.5845L16.6612 0.510956C19.2205 -0.949821 22.4196 0.89537 22.4196 3.84255V20.8849C22.4196 23.8321 19.2205 25.7029 16.6612 24.2165L11.4647 21.2219C11.1709 21.0526 11.2909 20.603 11.6299 20.603H12.5407C14.3578 20.603 15.4839 18.6297 14.5881 17.0664L12.1056 12.7866C11.2098 11.2233 8.93203 11.2233 8.01067 12.7866L7.89739 12.978C7.7666 13.199 7.92572 13.4785 8.18231 13.4785H10.8003C11.7985 13.4785 12.3103 14.683 11.6193 15.375L9.87898 17.1689C9.44389 17.6302 8.70169 17.6302 8.26661 17.1689L6.85756 15.6948C6.70479 15.535 6.44151 15.5669 6.33128 15.7586L5.57932 17.0664C5.57824 17.0686 5.57717 17.0707 5.57609 17.0729C5.42838 17.3687 5.03839 17.5163 4.75249 17.3505L1.91949 15.7082C-0.63983 14.2218 -0.63983 10.5314 1.91949 9.04496ZM16.0213 12.6072C16.7124 12.6072 17.301 12.0434 17.301 11.3258C17.301 10.6083 16.738 10.0444 16.0213 10.0444C15.3047 10.0444 14.7417 10.6083 14.7417 11.3258C14.7417 12.0434 15.3047 12.6072 16.0213 12.6072ZM6.11678 12.6072C6.80779 12.6072 7.39644 12.0434 7.39644 11.3258C7.39644 10.6083 6.83339 10.0444 6.11678 10.0444C5.40017 10.0444 4.83712 10.6083 4.83712 11.3258C4.83712 12.0434 5.40017 12.6072 6.11678 12.6072Z"
      fill={color}
    />
    <path
      d="M34.114 6.10779C34.114 6.77659 33.9619 7.37498 33.6579 7.90298C33.3538 8.42218 32.9369 8.81378 32.407 9.07778L34.0097 12.0082H31.6121L30.1788 9.44738H29.0712V12.0082H26.9603V2.76819H30.8433C31.4688 2.76819 32.0291 2.91339 32.5242 3.20379C33.0194 3.48539 33.406 3.88139 33.684 4.39179C33.9706 4.89339 34.114 5.46539 34.114 6.10779ZM29.0712 4.51059V7.70498H30.5176C30.9432 7.70498 31.2907 7.55538 31.56 7.25618C31.838 6.95698 31.977 6.57419 31.977 6.10779C31.977 5.64139 31.838 5.25859 31.56 4.95939C31.2907 4.66019 30.9432 4.51059 30.5176 4.51059H29.0712Z"
      fill={color}
    />
    <path
      d="M40.9199 8.49698C40.9199 8.76978 40.9025 9.06458 40.8678 9.38138H36.229C36.2463 9.79498 36.3853 10.1118 36.6459 10.3318C36.9066 10.5518 37.2584 10.6618 37.7014 10.6618C38.3182 10.6618 38.7091 10.4726 38.8741 10.0942H40.8287C40.7331 10.719 40.3943 11.225 39.8123 11.6122C39.239 11.9906 38.5353 12.1798 37.7014 12.1798C36.6242 12.1798 35.7773 11.885 35.1605 11.2954C34.5524 10.7058 34.2484 9.88298 34.2484 8.82698C34.2484 8.14058 34.3873 7.54658 34.6653 7.04498C34.9433 6.53459 35.3386 6.14739 35.8511 5.88339C36.3636 5.61059 36.9587 5.47419 37.6363 5.47419C38.2791 5.47419 38.8481 5.60179 39.3432 5.85699C39.8471 6.11219 40.2336 6.46859 40.5029 6.92619C40.7809 7.38379 40.9199 7.90738 40.9199 8.49698ZM38.9523 8.12738C38.9436 7.70498 38.8264 7.38378 38.6005 7.16378C38.3746 6.94378 38.0489 6.83379 37.6232 6.83379C37.1976 6.83379 36.8588 6.95258 36.6069 7.19018C36.3636 7.41898 36.2377 7.73138 36.229 8.12738H38.9523Z"
      fill={color}
    />
    <path
      d="M44.3819 10.4374C44.6598 10.4374 44.8901 10.4242 45.0725 10.3978V12.0082C44.6903 12.0786 44.2472 12.1138 43.7434 12.1138C43.1092 12.1138 42.5793 11.9598 42.1537 11.6518C41.728 11.3438 41.5152 10.719 41.5152 9.77738V7.38219V7.24298V5.64579V3.75819H43.5088V5.64579H44.9291V7.24298H43.5088V9.35498C43.5088 9.78618 43.5827 10.0766 43.7303 10.2262C43.878 10.367 44.0952 10.4374 44.3819 10.4374Z"
      fill={color}
    />
    <path
      d="M48.0787 12.1798C47.3316 12.1798 46.7583 11.9554 46.3587 11.5066C45.9591 11.049 45.7593 10.4066 45.7593 9.57938V5.64579H47.779V9.13058C47.779 9.57938 47.8615 9.91818 48.0265 10.147C48.1916 10.367 48.4565 10.477 48.8214 10.477C49.2297 10.477 49.5337 10.3538 49.7335 10.1074C49.9333 9.86098 50.0332 9.51338 50.0332 9.06458V5.64579H52.0138V12.0082H50.0332V11.0974C49.8508 11.4406 49.5945 11.709 49.2644 11.9026C48.943 12.0874 48.5478 12.1798 48.0787 12.1798Z"
      fill={color}
    />
    <path
      d="M52.8831 12.0082V5.64579H54.8768V6.97898C54.981 6.52138 55.1939 6.16939 55.5153 5.92299C55.8454 5.66779 56.2102 5.54019 56.6098 5.54019C56.8183 5.54019 56.9921 5.55779 57.131 5.59299V7.46738C56.8965 7.44098 56.6967 7.42778 56.5316 7.42778C55.9844 7.42778 55.5717 7.60818 55.2938 7.96898C55.0158 8.32098 54.8768 8.84898 54.8768 9.55298V12.0082H52.8831Z"
      fill={color}
    />
    <path
      d="M57.6803 12.0082V5.64579H59.6739V6.56979C59.8563 6.21779 60.1213 5.94939 60.4688 5.76459C60.8162 5.57099 61.2289 5.47419 61.7067 5.47419C63.2355 5.47419 64 6.34099 64 8.07458V12.0082H61.9933V8.73458C61.9933 8.18898 61.9065 7.79298 61.7327 7.54658C61.5677 7.30018 61.2853 7.17698 60.8857 7.17698C60.4861 7.17698 60.1821 7.30458 59.9736 7.55978C59.7738 7.81498 59.6739 8.18898 59.6739 8.68178V12.0082H57.6803Z"
      fill={color}
    />
    <path
      d="M31.8988 17.2486C32.4461 17.3014 32.8934 17.5214 33.2409 17.9086C33.5971 18.287 33.7752 18.7886 33.7752 19.4134C33.7752 20.2054 33.5146 20.8302 32.9933 21.2878C32.4721 21.7454 31.7598 21.9742 30.8564 21.9742H26.9603V12.7342H30.713C31.6165 12.7342 32.3288 12.9498 32.85 13.381C33.3799 13.8034 33.6449 14.3886 33.6449 15.1366C33.6449 15.7262 33.4885 16.2014 33.1758 16.5622C32.863 16.9142 32.4374 17.143 31.8988 17.2486ZM29.0712 14.2786V16.5226H30.2439C30.6435 16.5226 30.9563 16.4258 31.1821 16.2322C31.4167 16.0298 31.5339 15.7526 31.5339 15.4006C31.5339 15.0486 31.421 14.7758 31.1952 14.5822C30.9693 14.3798 30.6522 14.2786 30.2439 14.2786H29.0712ZM30.3873 20.3506C30.7782 20.3506 31.0866 20.245 31.3124 20.0338C31.5383 19.8138 31.6512 19.5146 31.6512 19.1362C31.6512 18.793 31.5339 18.5202 31.2994 18.3178C31.0735 18.1066 30.7695 18.001 30.3873 18.001H29.0712V20.3506H30.3873Z"
      fill={color}
    />
    <path
      d="M41.0617 18.463C41.0617 18.7358 41.0444 19.0306 41.0096 19.3474H36.3708C36.3882 19.761 36.5272 20.0778 36.7878 20.2978C37.0484 20.5178 37.4002 20.6278 37.8432 20.6278C38.46 20.6278 38.8509 20.4386 39.016 20.0602H40.9705C40.875 20.685 40.5362 21.191 39.9541 21.5782C39.3808 21.9566 38.6772 22.1458 37.8432 22.1458C36.766 22.1458 35.9191 21.851 35.3023 21.2614C34.6942 20.6718 34.3902 19.849 34.3902 18.793C34.3902 18.1066 34.5292 17.5126 34.8071 17.011C35.0851 16.5006 35.4804 16.1134 35.9929 15.8494C36.5054 15.5766 37.1005 15.4402 37.7781 15.4402C38.4209 15.4402 38.9899 15.5678 39.4851 15.823C39.9889 16.0782 40.3755 16.4346 40.6448 16.8922C40.9227 17.3498 41.0617 17.8734 41.0617 18.463ZM39.0941 18.0934C39.0855 17.671 38.9682 17.3498 38.7423 17.1298C38.5165 16.9098 38.1907 16.7998 37.765 16.7998C37.3394 16.7998 37.0006 16.9186 36.7487 17.1562C36.5054 17.385 36.3795 17.6974 36.3708 18.0934H39.0941Z"
      fill={color}
    />
    <path
      d="M44.5803 22.1458C43.9722 22.1458 43.4423 22.0006 42.9906 21.7102C42.5476 21.4198 42.2088 21.0238 41.9742 20.5222C41.7397 20.0118 41.6224 19.4354 41.6224 18.793C41.6224 18.1594 41.744 17.5874 41.9872 17.077C42.2305 16.5666 42.578 16.1662 43.0297 15.8758C43.4901 15.5854 44.0156 15.4402 44.6063 15.4402C45.0494 15.4402 45.4446 15.537 45.7921 15.7306C46.1396 15.9242 46.4002 16.1882 46.5739 16.5226V15.6118H48.5546V21.9742H46.5739V21.0766C46.4002 21.3934 46.1309 21.653 45.7661 21.8554C45.4099 22.049 45.0146 22.1458 44.5803 22.1458ZM45.1536 20.4694C45.6314 20.4694 46.0006 20.311 46.2612 19.9942C46.5218 19.6774 46.6521 19.277 46.6521 18.793C46.6521 18.3178 46.5218 17.9218 46.2612 17.605C46.0006 17.2882 45.6314 17.1298 45.1536 17.1298C44.7019 17.1298 44.3371 17.2838 44.0591 17.5918C43.7898 17.8998 43.6551 18.3002 43.6551 18.793C43.6551 19.2858 43.7898 19.6906 44.0591 20.0074C44.3371 20.3154 44.7019 20.4694 45.1536 20.4694Z"
      fill={color}
    />
    <path
      d="M49.488 21.9742V15.6118H51.4817V16.945C51.5859 16.4874 51.7988 16.1354 52.1202 15.889C52.4503 15.6338 52.8151 15.5062 53.2147 15.5062C53.4232 15.5062 53.5969 15.5238 53.7359 15.559V17.4334C53.5014 17.407 53.3016 17.3938 53.1365 17.3938C52.5893 17.3938 52.1766 17.5742 51.8987 17.935C51.6207 18.287 51.4817 18.815 51.4817 19.519V21.9742H49.488Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.0001 24L72.0001 -4.37114e-08L73.0001 0L73.0001 24L72.0001 24Z"
      fill={color}
    />
  </svg>
);
